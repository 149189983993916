import React, { useEffect, useState } from "react";
import SharedModal from "../shared/Modal";
import classes from "../FinancialManagement/styles/withdrawal-modal.module.css";
import DocumentItem from "./DocumentItem";
import { env } from "../../constants/env";

const ComplianceModal = ({ isOpen, setIsOpen, company, updateCount }) => {
  const [documents, setDocuments] = useState([]);
  const [changedDocuments, setChangedDocuments] = useState([])
  const [isSending, setIsSending] = useState(false)


  useEffect(() => {
    getDocuments()
  },[])


  const  getDocuments = async () => {
    const sendRequest = async () => {
      const response = await fetch(`${env.primeBridgeUrl}/api/company/documents/${company._id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("sending plays failed.");
      }

      const result = await response.json();

      setDocuments(result)

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };

  const  updateDocuments = async () => {
    const dataContent = {updatedDocuments: changedDocuments}
    const sendRequest = async () => {
      setIsSending(true)
      const response = await fetch(`${env.primeBridgeUrl}/api/company/update-document/${company._id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataContent)
      });

      if (!response.ok) {
        setIsSending(false)
        throw new Error("sending plays failed.");
      }

      const result = await response.json();
      setIsOpen(false)
      getDocuments()
      setIsSending(false)
      updateCount()

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      setIsSending(false)
      console.log(error);
    }
  };

  const dateToYMD = date => {
    const formatedDate = date.split('T')[0]
    console.log("DATE", formatedDate)
    var newDate = new Date(date)
    var fullDate = new Date(date)
    var strArray = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ]
    var days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    var d = newDate.getDate()
    var dayName = days[newDate.getDay()]
    var m = strArray[newDate.getMonth()]
    var y = newDate.getFullYear()
    var hr = newDate.getHours()
    var min = newDate.getMinutes()

    if (min < 10) {
      min = "0" + min;
    }
    var ampm = "AM";
    if( hr > 12 ) {
        hr -= 12;
        ampm = "PM";
    }

    return {
      full: '' + m + ' ' + (d <= 9 ? '0' + d : d) + ', ' + (hr <= 9 ? '0' + hr : hr) + ':' + min + ' ' + ampm,
      date: d,
      month: m
    }
  }

  const documentCheckHandler = (id, received, fileType, method) => {

    if(!changedDocuments.find(e => e.id === id)){
      setChangedDocuments([...changedDocuments, {id: id, received: method === 'checked' ? !received : received, fileType: fileType }])
    }else{
      const objIndex = changedDocuments.findIndex((obj => obj.id === id));
      let newArr = [...changedDocuments]; 
      newArr[objIndex] = {id: id, received: method === 'checked' ? !received : received, fileType: fileType}; 
      setChangedDocuments(newArr)
    }
    console.log("ID", id)
    console.log("RECEIVED", received)
  }

 

  return (
    <SharedModal className={`${classes.modal} ${classes.documentModal}`} show={isOpen} setShow={setIsOpen}>
      <div className={classes.body}>
        <div className={classes.headerSection}>
          <p className={classes.header}>Document Verification</p>
        </div>
        <div>
          <div style={{ marginBottom: "0.5rem" }}>
            <p className={classes.label}>Verification submitted</p>

            <p className={classes.value}>{dateToYMD(company.updatedAt).full}</p>
            {/* <div className={classes.groupItem}></div> */}

            {/* <div className={classes.groupItem}></div> */}
          </div>
          <div className={classes.group}>
            <div className={classes.groupItem}>
              <p className={classes.label}>Director</p>

              <p className={classes.value}>{company.director}</p>
            </div>

            <div className={classes.groupItem}>
              <p className={classes.label}>Date of Birth</p>

              <p className={classes.value}>1990-08-10</p>
            </div>
          </div>
          <div className={classes.group}>
            <div className={classes.groupItem}>
              <p className={classes.label}>Email address</p>

              <p className={classes.value}>{company.email}</p>
            </div>

            <div className={classes.groupItem}>
              <p className={classes.label}>Phone number</p>

              <p className={classes.value}>{company.phone}</p>
            </div>
          </div>
          <div className={classes.group}>
            <div className={classes.groupItem}>
              <p className={classes.label}>Company</p>

              <p className={classes.value}>{company.companyName}</p>
            </div>

            <div className={classes.groupItem}>
              <p className={classes.label}>Website</p>

              <p className={`${classes.value} ${classes.blue}`}>
                {company.website}
              </p>
            </div>
          </div>

          <div className={classes.group}>
            <div className={classes.groupItem}>
              <p className={classes.label}>Address</p>

              <p className={classes.value}>{company.address}</p>
            </div>

            <div className={classes.groupItem}></div>
          </div>
          <div className={classes.group}>
            <div className={classes.groupItem}>
              <p className={classes.label}>City</p>

              <p className={classes.value}>{company.city} </p>
            </div>

            <div className={classes.groupItem}>
              <p className={classes.label}>Province/State</p>

              <p className={`${classes.value}`}>{company.province}</p>
            </div>
          </div>
          <div className={classes.group}>
            <div className={classes.groupItem}>
              <p className={classes.label}>Postal Code</p>

              <p className={classes.value}>{company.postalCode}</p>
            </div>

            <div className={classes.groupItem}>
              <p className={classes.label}>Country</p>

              <p className={`${classes.value}`}>{company.country}</p>
            </div>
          </div>
          <div className={classes.group}>
            <div className={classes.groupItem}>
              <p className={classes.label}>Use of Prime Bridge</p>

              <p className={classes.value}>{company.selectedBridgeUse}</p>
            </div>

            <div className={classes.groupItem}>
              <p className={classes.label}>Source of funds</p>

              <p className={`${classes.value}`}>{company.selectedSource}</p>
            </div>
          </div>
        </div>
        <>
          <div className={classes.documentBox}>
            <div className={classes.heading}>
              <p className={classes.label}>Documents</p>
            </div>

            <div className={classes.content}>
              <div className={classes.statuses}>
                {documents.map((item, i) => (<DocumentItem document={item} key={i} documentCheck={documentCheckHandler}/>))}
              </div>
            </div>
          </div>
        </>

        <div className="d-flex justify-content-center align-items-center">
          {!isSending && <button className={classes.actionBtn} onClick={updateDocuments}>Update</button>}
          {isSending && (
          <div className="spinner-border" role="status">
          </div>
        )}
        </div>
        
      </div>
    </SharedModal>
  );
};

export default ComplianceModal;
