import React, { useEffect, useState } from "react";
import classes from "./detailpage.module.css";
import { env } from "../../constants/env";

const providersData = [
  {
      provider: "Prime Custody",
      title: "Prime Custody regulated by the FSCA",
      details: [{label: "Works with", desc:"Retail/Funds and UHNW"}, {label:"Banking", desc:"First National Bank"}, {label: "Location" , desc: "South Africa"}, {label: "Leverage", desc: "Maximum of 1:100"}],
      footNote: "",
      index: 0
  },
  {
      provider: "CXM Custody",
      title: "CXM Prime regulated by the FCA",
      details: [{label: "Works with", desc: "Funds and UHNW"}, {label: "Banking" , desc: "Barclays London"}, {label: "Location", desc: "United Kingdom"}, {label: "Leverage", desc: "1:25, but it can go up to 1:50 on performance review"}],
      footNote: "Further compliance checks will be requested by the FCA",
      index: 1
  }
]

const CustodyProvider = ({custodyProvider, getProvider}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [selectedOption, setSelectedOption] = useState();

  useEffect(() => {
    setSelectedOption(custodyProvider?.index)
  },[])

  const updateProviderHandler = async () => {
    const sendRequest = async () => {
      setIsSending(true)
      const response = await fetch(`${env.primeBridgeUrl}/api/custody-provider/update-provider/${custodyProvider?._id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(providersData[selectedOption])
      });

      if (!response.ok) {
        setIsSending(false)
        const error = await response.json();
        console.log("ERRORRR", error)
        throw new Error("sending plays failed.");
      }

      const result = await response.json();

      setIsEdit(false)

      getProvider()

      console.log("CUSTODY PROVIDER RESULT", result);

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      setIsSending(false)
      console.log(error);
    }
  };

  return (
    <div className={classes.transactionItem}>
      <p className={classes.transactionText}>
      Custody Provider:{" "}
        {!isEdit && (
          <span>
            {custodyProvider?.provider}{" "}
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => setIsEdit(true)}
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.35082 1.01441C10.4634 -0.0981163 12.2671 -0.098116 13.3796 1.01441L14.1854 1.82018C15.2979 2.93271 15.2979 4.73648 14.1854 5.84901L4.91911 15.1153L1.22404 15.1153C0.594699 15.1153 0.0845151 14.6051 0.0845156 13.9758L0.0845146 10.2807L9.35082 1.01441ZM11.7681 2.62595L12.5739 3.43171C12.7964 3.65422 12.7964 4.01497 12.5739 4.23748L11.3652 5.44613L9.7537 3.83459L10.9624 2.62595C11.1849 2.40344 11.5456 2.40344 11.7681 2.62595ZM9.7537 7.05766L3.9751 12.8363L2.36356 12.8363L2.36356 11.2247L8.14217 5.44613L9.7537 7.05766Z"
                fill="#0077B5"
              />
            </svg>
          </span>
        )}
      </p>
      {isEdit && (
        <div className={classes.transactionEdit}>
          <select onChange={(e) => setSelectedOption(e.target.value)}>
          {providersData.map((item, i) => (<option selected={i === selectedOption} value={i} key={i}>{item.provider}</option>))}
          </select>

          <button onClick={updateProviderHandler}>Save</button>
        </div>
      )}
    </div>
  );
};

export default CustodyProvider;
