import React, { useEffect, useState } from "react";
import classes from "./styles/service-fee.module.css";
import tableClasses from "../shared/styles/customTable.module.css";
import StatusRequestItem from "./StatusRequestItem";
import ServiceFeeItem from "./ServiceFeeItem";
import { env } from "../../constants/env";
import CreateInvoiceModal from "./CreateInvoice";

const ServiceFeePage = () => {
  const [feesList, setFeesList] = useState([])
  const [selectedDate, setSelectedDate] = useState([])
  const [dateList, setDateList] = useState("")
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  useEffect(() => {
    const today = new Date();
    const m = today.getMonth()
    const y = today.getFullYear()
    console.log("TODAYYYY", `${m}-${y}`)
    getAllInvoices()
    // getInvoicesByDate(`${m}-${y}`)
  }, [])


  

  const getAllInvoices = async () => {
    const sendRequest = async () => {
      const response = await fetch(`${env.primeBridgeUrl}/api/service-fee`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("sending plays failed.");
      }

      const result = await response.json();

      console.log("Invoices data", result);
      setFeesList(result)
      setDateList(result)
      const date = result[0]?.date?.split("T")[0];
      const today = new Date(date);
      const m = today?.getMonth()
      const y = today?.getFullYear()

      // getInvoicesByDate(`${m}-${y}`)
      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };

  const getInvoicesByDate = async (fullYear) => {
    const newFullYear = fullYear.split("-")
    const month =  +newFullYear[0] + 1
    const year =  +newFullYear[1]

    const sendRequest = async () => {
      const response = await fetch(`${env.primeBridgeUrl}/api/service-fee/date/${month}/${year}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("sending plays failed.");
      }

      const result = await response.json();

      console.log("Invoices BY DATE", result);
      setFeesList(result)
      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };


  const dateData = (data) => {
    if(data === ""){
      return []
    }
    var groups = data?.reduce(function (r, o) {
      const date = o?.createdAt?.split("T")[0];

      var newDate = new Date(date)
      var mIndex = date?.split("-")[1];
      var strArray = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      var m = strArray[mIndex - 1];
      var y = newDate?.getFullYear()

      var mn = newDate.getMonth()
      r[m] = { group: `${m} ${y}`, data: `${mn}-${y}` };
      return r;
    }, {});

    var result = Object.keys(groups).map(function (k) {
      return groups[k];
    });

    console.log("RESULTSSSSSSS", result)

    return result;
  };


  console.log("SSSELECTED DATEEEE", selectedDate)

  return (
    <div className={classes.container}>
      <div className={classes.navContainer}>
        <p className={classes.headerSection}>Service Fees</p>

        <div className={classes.actionsContainer}></div>
      </div>

      <div className={classes.tableContainer}>
        <div className={classes.headerSection}>
          <p className={classes.header}></p>
          <div className="d-flex justify-content-center align-items-center gap-4">
          <button
                onClick={() => setIsCreateOpen(true)}
                className={classes.tableButton}
              >
                Create Invoice
              </button>
          
          <div className={classes.selectContainer}>
            <select name="" id="" onChange={(e) => getInvoicesByDate(e.target.value)}>
            {dateData(dateList)?.map((item, i) => (<option value={item.data} key={i}>{item.group}</option>))}
              {/* <option value="">March 2023</option>
              <option value="">April 2023</option> */}
            </select>
            <svg
              width="13"
              height="8"
              viewBox="0 0 13 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.7168 2.10938L6.68885 6.03617C6.49112 6.22893 6.17457 6.2249 5.9818 6.02717L2.05501 1.99923"
                stroke="white"
                stroke-width="3"
                stroke-linecap="round"
              />
            </svg>
          </div>
        </div>
        </div>
        <table style={{ width: "100%" }} class={tableClasses.container}>
          <thead class="bg-gray-50">
            <tr>
              <th
                scope="col"
                class={`${tableClasses.header} ${tableClasses.first}`}
              >
                Company Name
              </th>
              <th scope="col" class={tableClasses.header}>
                Date
              </th>
              <th scope="col" class={tableClasses.header}>
                Item
              </th>
              <th scope="col" class={tableClasses.header}>
                Amount
              </th>
              <th scope="col" class={tableClasses.header}></th>
            </tr>
          </thead>
          <tbody class={tableClasses.body}>
            {feesList.map((item, i) => (<ServiceFeeItem hasInvoice={i !== 0} isOpen={isCreateOpen} setIsOpen={(result) => setIsCreateOpen(result)} list={item} fetchAllInvoices={getAllInvoices}/>))}
          </tbody>
        </table>
        <CreateInvoiceModal
            isOpen={isCreateOpen}
            setIsOpen={setIsCreateOpen}
            updateList={getAllInvoices}
          />
      </div>
    </div>
  );
};

export default ServiceFeePage;
