import React, { useEffect, useState } from "react";
import classes from "./RiskManagementMain.module.css";
import LeadDetailsModal from "./LeadDetailModal";
import { toast } from "react-hot-toast";
import { env } from "../../constants/env";
const AnalyticsItem = ({item}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [companyName, setCompanyName] = useState()

  useEffect(() => {
    fetchSingleCompany(item?.company)
  },[item])
  
  const dateToYMD = date => {
    const formatedDate = date?.split('T')[0]
    console.log("DATE", formatedDate)
    var newDate = new Date(formatedDate)
    var today = new Date()
    var strArray = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ]
    var days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    var d = newDate.getDate()
    var dayName = days[newDate.getDay()]
    var m = strArray[newDate.getMonth()]
    var y = newDate.getFullYear()

    
    return '' + m + ' ' + (d <= 9 ? '0' + d : d) + ', ' + y
  }

 const accEncoded = (num) => {
  let encodedId = window.btoa(encodeURI(num))

  return encodedId
 }

  const fetchSingleCompany =  async(companyId) => {
    const sendRequest = async () => {
      const response = await fetch(
        `${env.primeBridgeUrl}/api/company/${companyId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        setCompanyName("")
        throw new Error("fetching company failed.");
      }

      const result = await response.json();

      console.log("Results get company redux", result);
      setCompanyName(result.companyName)

      return result;
    };

    try {
      const data = await sendRequest();
    } catch (error) {
      setCompanyName("")
      console.log(error);
    }
};
  return (
    <tr>
      <td class={`${classes.row} ${classes.first}`}>{item?.credentials?.accountNumber}</td>
      <td class={classes.row}>{companyName}</td>
      <td class={classes.row}>{item.accountName}</td>
      <td class={classes.row}>
       <a href={`https://tracking.primebridge.io?id=${item?.credentials?.accountNumber}&company=${item?.company}`} target="_blank">tracking.primebridge.io?id={item?.credentials?.accountNumber}&company={item?.company}</a> 
        <svg width="30" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() =>{ navigator.clipboard.writeText(
                      `tracking.primebridge.io?id=${item?.credentials?.accountNumber}&company=${item?.company}`
                    ); toast.success("Copied to clipboard")}} role="button">
        <path d="M9.29453 3.04767H2.14029C1.57263 3.04862 1.02851 3.28738 0.627272 3.71156C0.226037 4.13574 0.000451957 4.71072 0 5.31036V15.2604C0.000451957 15.8601 0.226037 16.435 0.627272 16.8592C1.02851 17.2834 1.57263 17.5222 2.14029 17.5231H9.29453C9.861 17.5203 10.4034 17.2807 10.8031 16.8567C11.2029 16.4327 11.4275 15.8588 11.428 15.2604V5.31036C11.4275 4.71196 11.2029 4.13808 10.8031 3.7141C10.4034 3.29013 9.861 3.05053 9.29453 3.04767ZM9.84499 15.2604C9.84499 15.4146 9.787 15.5625 9.68377 15.6716C9.58053 15.7806 9.44052 15.8419 9.29453 15.8419H2.14029C1.9946 15.8414 1.85502 15.7799 1.75216 15.671C1.6493 15.562 1.59154 15.4143 1.59154 15.2604V5.31036C1.59154 5.15662 1.64936 5.00918 1.75227 4.90047C1.85518 4.79176 1.99475 4.73069 2.14029 4.73069H9.29453C9.44023 4.73069 9.57998 4.79171 9.68316 4.90036C9.78634 5.00902 9.84454 5.15646 9.84499 5.31036V15.2604Z" fill="black"/>
        <path d="M13.8623 0H7.26493C6.75292 0.000479122 6.26201 0.216241 5.89996 0.599921C5.53791 0.983602 5.33431 1.50385 5.33386 2.04645C5.34334 2.26309 5.43122 2.46752 5.57923 2.61728C5.72725 2.76703 5.92402 2.85059 6.12866 2.85059C6.3333 2.85059 6.53007 2.76703 6.67808 2.61728C6.8261 2.46752 6.91397 2.26309 6.92345 2.04645C6.92345 1.95047 6.95943 1.85843 7.02347 1.79056C7.08751 1.7227 7.17436 1.68457 7.26493 1.68457H13.8623C14.0078 1.68505 14.1472 1.74664 14.25 1.85586C14.3527 1.96507 14.4104 2.11299 14.4104 2.2672V11.6653C14.4104 11.7613 14.3744 11.8533 14.3104 11.9212C14.2463 11.9891 14.1595 12.0272 14.0689 12.0272H13.6626C13.4515 12.0272 13.2492 12.116 13.0999 12.2742C12.9507 12.4323 12.8669 12.6468 12.8669 12.8704C12.8669 13.094 12.9507 13.3085 13.0999 13.4666C13.2492 13.6247 13.4515 13.7136 13.6626 13.7136H14.0706C14.5823 13.7126 15.0728 13.4967 15.4345 13.113C15.7962 12.7294 15.9995 12.2094 16 11.6671V2.2672C15.9995 1.66636 15.7742 1.09024 15.3735 0.665216C14.9727 0.240189 14.4293 0.000958086 13.8623 0V0Z" fill="black"/>
        </svg>
      </td>
      <LeadDetailsModal isNewRequest isOpen={isOpen} setIsOpen={setIsOpen} item={item}/>
    </tr>
  );
};

export default AnalyticsItem;
