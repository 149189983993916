import React, { useEffect, useState } from "react";
import tableClasses from "../shared/styles/customTable.module.css";
import WithdrawalRequestModal from "./WithdrawalRequestModal";
import DepositRequestModal from "./DepositRequestModal";
import { env } from "../../constants/env";
import { numberWithCommas } from "../../utilities/functions";
import TransferRequestModal from "./TransferRequestModal";

const DepositRequestItem = ({
  isWithdrawal,
  isTransfer,
  isNewRequest = false,
  request,
}) => {
  const [isDepositOpen, setIsDepositOpen] = useState(false);
  const [isWithdrawalOpen, setIsWithdrawalOpen] = useState(false);
  const [isTransferOpen, setIsTransferOpen] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [accountName, setAccountName] = useState("");
  const [userName, setUserName] = useState("");

  useEffect(() => {
    fetchSingleCompany(request.company);
    fetchSingleAccount(request.account);
    fetchUser(request.user);
  }, [request]);

  const dateToYMD = (date) => {
    const formatedDate = date.split("T")[0];
    console.log("DATE", formatedDate);
    var newDate = new Date(date);
    var fullDate = new Date(date);
    var strArray = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    var d = newDate.getDate();
    var dayName = days[newDate.getDay()];
    var m = strArray[newDate.getMonth()];
    var y = newDate.getFullYear();
    var hr = newDate.getHours();
    var min = newDate.getMinutes();

    if (min < 10) {
      min = "0" + min;
    }
    var ampm = "AM";
    if (hr > 12) {
      hr -= 12;
      ampm = "PM";
    }

    return {
      full: "" + m + " " + (d <= 9 ? "0" + d : d) + ", " + y,
      date: d,
      month: m,
    };
  };

  const fetchSingleCompany = async (companyId) => {
    const sendRequest = async () => {
      const response = await fetch(
        `${env.primeBridgeUrl}/api/company/${companyId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("fetching company failed.");
      }

      const result = await response.json();

      console.log("Results get company redux", result);
      setCompanyName(result.companyName);

      return result;
    };

    try {
      const data = await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSingleAccount = async (accountId) => {
    const sendRequest = async () => {
      const response = await fetch(
        `${env.primeBridgeUrl}/api/account/${accountId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("fetching company failed.");
      }

      const result = await response.json();

      console.log("Results get Account", result);
      setAccountName(result.accountName);

      return result;
    };

    try {
      const data = await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUser = async (userId) => {
    const sendRequest = async () => {
      const response = await fetch(
        `${env.primeBridgeUrl}/api/auth/user/${userId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("fetching company failed.");
      }

      const result = await response.json();

      console.log("Results user Account", result);
      setUserName(`${result.firstName} ${result.lastName}`);

      return result;
    };

    try {
      const data = await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <tr>
      <td class={`${tableClasses.row} ${tableClasses.first}`}>
        {dateToYMD(request?.createdAt).full}
      </td>
      <td class={tableClasses.row}>{companyName}</td>
      <td class={tableClasses.row}>
        {request?.paymentMethod === "internal-transfer"
          ? request?.sourceAccount?.accountName
          : accountName}
      </td>
      <td class={tableClasses.row}>
        {request?.paymentMethod === "cash-account"
          ? "Cash Account"
          : request?.paymentMethod === "crypto"
          ? `Crypto (${request?.currency?.toUpperCase()})`
          : request?.paymentMethod === "internal-transfer"
          ? request?.destinationAccount?.accountName
          : "Wire Transfer"}
      </td>
      <td class={tableClasses.row}>
        <div style={{ gap: "3rem" }} className={tableClasses.flexItem}>
          <p>{`${
            request?.currency === "usd" || request?.paymentMethod === "crypto"
              ? "$"
              : request?.currency === "euro" && "€"
          } ${numberWithCommas(request?.totalWithdrawal)} ${
            request?.currency === "usd" || request?.paymentMethod === "crypto"
              ? "USD"
              : request?.currency === "euro" && "EUR"
          }`}</p>
          <div>
            <svg
              width="20"
              height="20"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() =>
                isTransfer
                  ? setIsTransferOpen(true)
                  : isWithdrawal
                  ? setIsWithdrawalOpen(true)
                  : setIsDepositOpen(true)
              }
            >
              <path
                d="M16 12.5H12.5M12.5 12.5H9M12.5 12.5V9M12.5 12.5V16M18.3333 23H6.66667C4.08934 23 2 20.9107 2 18.3333V6.66667C2 4.08934 4.08934 2 6.66667 2H18.3333C20.9107 2 23 4.08934 23 6.66667V18.3333C23 20.9107 20.9107 23 18.3333 23Z"
                stroke="#0077B5"
                stroke-width="3"
                stroke-linecap="round"
              />
            </svg>
          </div>
        </div>
      </td>

      <DepositRequestModal
        isOpen={isDepositOpen}
        setIsOpen={setIsDepositOpen}
        isNewRequest={isNewRequest}
        request={request}
        companyName={companyName}
        accountName={accountName}
        userName={userName}
      />
      <WithdrawalRequestModal
        isOpen={isWithdrawalOpen}
        setIsOpen={setIsWithdrawalOpen}
        isNewRequest={isNewRequest}
        request={request}
        companyName={companyName}
        accountName={accountName}
        userName={userName}
      />

      <TransferRequestModal
        isOpen={isTransferOpen}
        setIsOpen={setIsTransferOpen}
        isNewRequest={isNewRequest}
        request={request}
        companyName={companyName}
        accountName={accountName}
        userName={userName}
      />
    </tr>
  );
};

export default DepositRequestItem;
