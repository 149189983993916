import React, { useEffect } from "react";
import WithdrawalPage from "../../components/FinancialManagement/WithdrawalPage";
import MainLayout from "../../components/Layout/MainLayout";

const Withdrawal = () => {
  useEffect(() => {
    document.title = "Withdrawal Requests - Prime Bridge Admin";
  }, []);
  return (
    <MainLayout>
      <WithdrawalPage />
    </MainLayout>
  );
};

export default Withdrawal;
