import React, { useState } from "react";
import { env } from "../../constants/env";
import SharedModal from "../shared/Modal";
import classes from "./remove.module.css";
import { useDispatch } from "react-redux";
import { fetchCompanies } from "../../Redux/company-actions";
import { toast } from "react-hot-toast";

const DeleteViolationModal = ({ isOpen, setIsOpen, account, setReload, reload}) => {
  const dispatch = useDispatch()
  const [isSending, setIsSending] = useState(false)

  const  removeAccount = async (id) => {
    const sendRequest = async () => {
      setIsSending(true)
      const response = await fetch(`${env.pbTrackingUrl}/api/violation/remove/${id}`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        setIsSending(false)
        throw new Error("sending plays failed.");
      }

      const result = await response.json();

      setIsOpen(false)

      setIsSending(false)

      setReload(!reload)

      toast.success("Removed violation")

      console.log("Remove Results", result);

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      setIsSending(false)
      console.log(error);
    }
  };
  return (
    <SharedModal show={isOpen} setShow={setIsOpen}>
      <div>
        <p className={classes.header}>
        Are you sure you want to remove
        violation from account {account}?
        </p>

        <p className={classes.info}>This action cannot be undone.</p>
        <div className={classes.btnContainer}>
        {!isSending && (<>
          <button onClick={() => setIsOpen(false)} className={classes.cancel}>
            Cancel
          </button>

          <button className={classes.confirm} onClick={() => removeAccount(account)}>Remove</button>
          </>)}
          {isSending && (
          <div class="spinner-border" role="status">
          </div>
        )}
        </div>
      </div>
    </SharedModal>
  );
};

export default DeleteViolationModal;
