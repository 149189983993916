import React, { useState } from "react";
import SharedModal from "../shared/Modal";
import classes from "../Dashboard/remove.module.css";
import modalClasses from "../Dashboard/edit-company.module.css";
import { toast } from "react-hot-toast";
import { env } from "../../constants/env";

const NewTeamMemberLogin = ({ isOpen, setIsOpen, email, password, fullName }) => {
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    toast.success("Copied to clipboard");
  };
  const [isLoading, setIsLaoding] = useState()

  const sendCredentials = async () => {
    let dataContent = {email: email, password: password, access: "admin-portal", fullName: fullName}
    const sendRequest = async () => {
      setIsLaoding(true)
      const response = await fetch(`${env.primeBridgeUrl}/api/company/team-member-credentials`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataContent)
      });

      if (!response.ok) {
        setIsLaoding(false)
        const result = await response.json();
        alert(result.error)
        console.log("ERROR RESPONSE", result.error)
        throw new Error("sending plays failed.");
      }

      const result = await response.json();
      setIsLaoding(false)

      toast.success("Credentials sent");

      console.log("Reply from plays data", result);

      return result;
    };

    try {
        await sendRequest();
    } catch (error) {
      setIsLaoding(false)
      console.log(error);
    }
  };
  return (
    <SharedModal
      className={modalClasses.modal}
      show={isOpen}
      setShow={setIsOpen}
    >
      <div className={modalClasses.container}>
        <p className={modalClasses.header}>New Team Member</p>

        <div className={modalClasses.passwordContent}>
          <div className={modalClasses.loginContainer}>
            <p className={modalClasses.label}>Employee Login</p>
            <p className={modalClasses.value}>
              {email}{" "}
              <svg
                width="15"
                height="17"
                viewBox="0 0 15 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => handleCopy(email)}
              >
                <path
                  d="M8.71362 2.85742H2.00652C1.47434 2.85832 0.964226 3.08215 0.588068 3.47982C0.21191 3.87749 0.00042371 4.41653 0 4.9787V14.3069C0.00042371 14.869 0.21191 15.4081 0.588068 15.8058C0.964226 16.2034 1.47434 16.4273 2.00652 16.4282H8.71362C9.24469 16.4255 9.75316 16.2008 10.1279 15.8034C10.5027 15.4059 10.7133 14.8679 10.7137 14.3069V4.9787C10.7133 4.4177 10.5027 3.87968 10.1279 3.4822C9.75316 3.08473 9.24469 2.86011 8.71362 2.85742ZM9.22968 14.3069C9.22968 14.4515 9.17531 14.5901 9.07853 14.6923C8.98175 14.7946 8.85049 14.852 8.71362 14.852H2.00652C1.86993 14.8516 1.73908 14.7939 1.64265 14.6918C1.54622 14.5896 1.49207 14.4512 1.49207 14.3069V4.9787C1.49207 4.83457 1.54627 4.69634 1.64275 4.59443C1.73923 4.49251 1.87008 4.43526 2.00652 4.43526H8.71362C8.85021 4.43526 8.98123 4.49246 9.07796 4.59432C9.1747 4.69619 9.22925 4.83441 9.22968 4.9787V14.3069Z"
                  fill="#0077B5"
                />
                <path
                  d="M12.9954 0H6.81038C6.33036 0.000449177 5.87013 0.202726 5.53071 0.562426C5.1913 0.922127 5.00042 1.40986 5 1.91855C5.00888 2.12164 5.09127 2.3133 5.23003 2.4537C5.3688 2.59409 5.55327 2.67243 5.74512 2.67243C5.93697 2.67243 6.12144 2.59409 6.26021 2.4537C6.39897 2.3133 6.48135 2.12164 6.49024 1.91855C6.49024 1.82857 6.52397 1.74228 6.58401 1.67865C6.64404 1.61503 6.72547 1.57928 6.81038 1.57928H12.9954C13.1318 1.57973 13.2625 1.63748 13.3589 1.73987C13.4552 1.84225 13.5092 1.98093 13.5092 2.1255V10.9362C13.5092 11.0262 13.4755 11.1125 13.4155 11.1761C13.3554 11.2398 13.274 11.2755 13.1891 11.2755H12.8081C12.6103 11.2755 12.4206 11.3588 12.2807 11.507C12.1408 11.6553 12.0622 11.8563 12.0622 12.066C12.0622 12.2756 12.1408 12.4767 12.2807 12.625C12.4206 12.7732 12.6103 12.8565 12.8081 12.8565H13.1907C13.6704 12.8556 14.1303 12.6531 14.4693 12.2935C14.8084 11.9338 14.9991 11.4463 14.9995 10.9379V2.1255C14.9991 1.56221 14.7878 1.0221 14.4121 0.62364C14.0364 0.225177 13.527 0.000898206 12.9954 0V0Z"
                  fill="#0077B5"
                />
              </svg>
            </p>
          </div>

          <div className={modalClasses.loginContainer}>
            <p className={modalClasses.label}>Password</p>
            <p className={modalClasses.value}>
             {password}{" "}
              <svg
                width="15"
                height="17"
                viewBox="0 0 15 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => handleCopy(password)}
              >
                <path
                  d="M8.71362 2.85742H2.00652C1.47434 2.85832 0.964226 3.08215 0.588068 3.47982C0.21191 3.87749 0.00042371 4.41653 0 4.9787V14.3069C0.00042371 14.869 0.21191 15.4081 0.588068 15.8058C0.964226 16.2034 1.47434 16.4273 2.00652 16.4282H8.71362C9.24469 16.4255 9.75316 16.2008 10.1279 15.8034C10.5027 15.4059 10.7133 14.8679 10.7137 14.3069V4.9787C10.7133 4.4177 10.5027 3.87968 10.1279 3.4822C9.75316 3.08473 9.24469 2.86011 8.71362 2.85742ZM9.22968 14.3069C9.22968 14.4515 9.17531 14.5901 9.07853 14.6923C8.98175 14.7946 8.85049 14.852 8.71362 14.852H2.00652C1.86993 14.8516 1.73908 14.7939 1.64265 14.6918C1.54622 14.5896 1.49207 14.4512 1.49207 14.3069V4.9787C1.49207 4.83457 1.54627 4.69634 1.64275 4.59443C1.73923 4.49251 1.87008 4.43526 2.00652 4.43526H8.71362C8.85021 4.43526 8.98123 4.49246 9.07796 4.59432C9.1747 4.69619 9.22925 4.83441 9.22968 4.9787V14.3069Z"
                  fill="#0077B5"
                />
                <path
                  d="M12.9954 0H6.81038C6.33036 0.000449177 5.87013 0.202726 5.53071 0.562426C5.1913 0.922127 5.00042 1.40986 5 1.91855C5.00888 2.12164 5.09127 2.3133 5.23003 2.4537C5.3688 2.59409 5.55327 2.67243 5.74512 2.67243C5.93697 2.67243 6.12144 2.59409 6.26021 2.4537C6.39897 2.3133 6.48135 2.12164 6.49024 1.91855C6.49024 1.82857 6.52397 1.74228 6.58401 1.67865C6.64404 1.61503 6.72547 1.57928 6.81038 1.57928H12.9954C13.1318 1.57973 13.2625 1.63748 13.3589 1.73987C13.4552 1.84225 13.5092 1.98093 13.5092 2.1255V10.9362C13.5092 11.0262 13.4755 11.1125 13.4155 11.1761C13.3554 11.2398 13.274 11.2755 13.1891 11.2755H12.8081C12.6103 11.2755 12.4206 11.3588 12.2807 11.507C12.1408 11.6553 12.0622 11.8563 12.0622 12.066C12.0622 12.2756 12.1408 12.4767 12.2807 12.625C12.4206 12.7732 12.6103 12.8565 12.8081 12.8565H13.1907C13.6704 12.8556 14.1303 12.6531 14.4693 12.2935C14.8084 11.9338 14.9991 11.4463 14.9995 10.9379V2.1255C14.9991 1.56221 14.7878 1.0221 14.4121 0.62364C14.0364 0.225177 13.527 0.000898206 12.9954 0V0Z"
                  fill="#0077B5"
                />
              </svg>
            </p>
          </div>
        </div>

        {!isLoading && <div className={classes.btnContainer}>
          <button className={modalClasses.confirm} onClick={sendCredentials}>Send to Employee</button>
        </div>}
        {isLoading && (
          <div className="spinner-border" role="status">
          </div>
        )}
      </div>
    </SharedModal>
  );
};

export default NewTeamMemberLogin;
