import React, { useEffect, useRef, useState } from "react";
import SharedModal from "../shared/Modal";
import classes from "./view-acc.module.css";
import modalClasses from "./edit-company.module.css";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllAccounts } from "../../Redux/accounts-actions";
import { env } from "../../constants/env";

const ViewAccountDetailModal = ({ isOpen, setIsOpen, accountDetails,isMt4Account }) => {
  const dispatch = useDispatch()
  const selectRef = useRef();
  const [revealMaster, setRevealMaster] = useState(false);
  const [revealInvestor, setRevealInvestor] = useState(false);
  const [account, setAccount] = useState();

  const accounts = useSelector(state => state.accounts.allAccounts)

  const handleCopy = async (text) => {
    await navigator.clipboard.writeText(text);
    toast.success("Copied to clipboard");
  };

  useEffect(() => {
    dispatch(fetchAllAccounts(accountDetails.company))
    setAccount(accountDetails)
  }, [])

  const onChangeHandler = async(id) => {
    const sendRequest = async () => {
      const response = await fetch(`${env.primeBridgeUrl}/api/account/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Getting account failed.");
      }

      const result = await response.json();

      setAccount(result)

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <SharedModal
      Login
      className={modalClasses.modal}
      show={isOpen}
      setShow={setIsOpen}
    >
      <div className={modalClasses.container}>
        <p className={modalClasses.header}>{isMt4Account? "MT4" : "MT5"} Login</p>

        {!isMt4Account && <div className={classes.select}>
          <svg
            onClick={() => selectRef.current?.click()}
            width="11"
            height="7"
            viewBox="0 0 11 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 1L5.87165 5.58706C5.67303 5.80775 5.32697 5.80775 5.12835 5.58706L1 1"
              stroke="#141414"
              stroke-width="2"
              stroke-linecap="round"
            />
          </svg>

          <select ref={selectRef} onChange={(e) => onChangeHandler(e.target.value)}>
            {accounts.map((item, i) => (<option value={item._id} key={i}>
            {item.accountName}
            </option>))}
          </select>
        </div>}

        <div className={classes.accDetail}>
          {isMt4Account && <div className={classes.detailItem}>
            <div>
              <p>Account Name:</p>
            </div>
            <div>
              <p>{account?.accountName}</p>
            </div>
          </div>}
          <div className={classes.detailItem}>
            <div>
              <p>Account ID:</p>
            </div>
            <div>
              <p>{account?.credentials?.accountNumber}</p>
            </div>
          </div>
          {isMt4Account && <div className={classes.detailItem}>
            <div>
              <p>API id:</p>
            </div>
            <div>
              <p>{account?.credentials?.apiId}</p>
            </div>
          </div>}
          {!isMt4Account && <>
          <div className={classes.detailItem}>
            <div>
              <p>Master Password:</p>
            </div>
            <div className={classes.value}>
              {revealMaster ? (
                <p>{account?.credentials?.masterPassword}</p>
              ) : (
                <svg
                  width="56"
                  height="7"
                  viewBox="0 0 56 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="3" cy="3.5" r="3" fill="#E5F1F7" />
                  <circle cx="13" cy="3.5" r="3" fill="#E5F1F7" />
                  <circle cx="23" cy="3.5" r="3" fill="#E5F1F7" />
                  <circle cx="33" cy="3.5" r="3" fill="#E5F1F7" />
                  <circle cx="43" cy="3.5" r="3" fill="#E5F1F7" />
                  <circle cx="53" cy="3.5" r="3" fill="#E5F1F7" />
                </svg>
              )}

              <svg
                onClick={() => setRevealMaster((val) => !val)}
                width="11"
                height="15"
                viewBox="0 0 11 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.66113 5.86521H9.26552L9.26396 3.89367C9.26228 1.74583 7.51498 -0.00142915 5.36715 8.77163e-07C3.21943 0.00156498 1.47205 1.74898 1.47348 3.89681L1.47672 5.86847H1.33573C0.597381 5.87004 -0.00168387 6.46908 3.55608e-06 7.20743V13.0774C3.55608e-06 13.8157 0.600613 14.4148 1.33896 14.4148L9.66427 14.41C10.4026 14.41 11.0017 13.811 11 13.0726V7.2012C11 6.46285 10.3994 5.86535 9.66104 5.86535L9.66113 5.86521ZM2.91199 5.86845L2.90875 3.89679C2.90875 2.54016 4.01064 1.4367 5.36727 1.43515C6.72391 1.43515 7.82737 2.53873 7.82737 3.89367L7.83061 5.86533L2.91199 5.86845Z"
                  fill="black"
                />
              </svg>
              <svg
                onClick={() => handleCopy(accountDetails?.credentials?.masterPassword)}
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect y="3" width="11" height="11" rx="2" fill="black" />
                <rect
                  x="3.25"
                  y="0.25"
                  width="10.5"
                  height="10.5"
                  rx="1.75"
                  fill="black"
                  stroke="white"
                  stroke-width="0.5"
                />
              </svg>
            </div>
          </div>
          <div className={classes.detailItem}>
            <div>
              <p>Investor Password:</p>
            </div>
            <div className={classes.value}>
              {revealInvestor ? (
                <p>{account?.credentials?.investorPassword}</p>
              ) : (
                <svg
                  width="56"
                  height="7"
                  viewBox="0 0 56 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="3" cy="3.5" r="3" fill="#E5F1F7" />
                  <circle cx="13" cy="3.5" r="3" fill="#E5F1F7" />
                  <circle cx="23" cy="3.5" r="3" fill="#E5F1F7" />
                  <circle cx="33" cy="3.5" r="3" fill="#E5F1F7" />
                  <circle cx="43" cy="3.5" r="3" fill="#E5F1F7" />
                  <circle cx="53" cy="3.5" r="3" fill="#E5F1F7" />
                </svg>
              )}

              <svg
                onClick={() => setRevealInvestor((val) => !val)}
                width="11"
                height="15"
                viewBox="0 0 11 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.66113 5.86521H9.26552L9.26396 3.89367C9.26228 1.74583 7.51498 -0.00142915 5.36715 8.77163e-07C3.21943 0.00156498 1.47205 1.74898 1.47348 3.89681L1.47672 5.86847H1.33573C0.597381 5.87004 -0.00168387 6.46908 3.55608e-06 7.20743V13.0774C3.55608e-06 13.8157 0.600613 14.4148 1.33896 14.4148L9.66427 14.41C10.4026 14.41 11.0017 13.811 11 13.0726V7.2012C11 6.46285 10.3994 5.86535 9.66104 5.86535L9.66113 5.86521ZM2.91199 5.86845L2.90875 3.89679C2.90875 2.54016 4.01064 1.4367 5.36727 1.43515C6.72391 1.43515 7.82737 2.53873 7.82737 3.89367L7.83061 5.86533L2.91199 5.86845Z"
                  fill="black"
                />
              </svg>
              <svg
                onClick={() => handleCopy(account?.credentials?.investorPassword)}
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect y="3" width="11" height="11" rx="2" fill="black" />
                <rect
                  x="3.25"
                  y="0.25"
                  width="10.5"
                  height="10.5"
                  rx="1.75"
                  fill="black"
                  stroke="white"
                  stroke-width="0.5"
                />
              </svg>
            </div>
          </div>
          <div className={classes.detailItem}>
            <div>
              <p>Server:</p>
            </div>
            <div className={classes.value}>
              <p>{account?.credentials?.server}</p>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => handleCopy(accountDetails?.credentials?.server)}
              >
                <rect y="3" width="11" height="11" rx="2" fill="black" />
                <rect
                  x="3.25"
                  y="0.25"
                  width="10.5"
                  height="10.5"
                  rx="1.75"
                  fill="black"
                  stroke="white"
                  stroke-width="0.5"
                />
              </svg>
            </div>
          </div>
          </> }
        </div>
      </div>
    </SharedModal>
  );
};

export default ViewAccountDetailModal;
