import React, { useEffect, useState } from "react";
import tableClasses from "../shared/styles/customTable.module.css";
import CustomerSupportItemModal from "./CustomerSupportItemModal";
import { env } from "../../constants/env";

const CustomerSupportItem = ({ isSolved = false, request }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [companyName, setCompanyName] = useState("")
  const [customerName, setCustomerName] = useState("")

  console.log("REQUEST :)", request?.lastMessage?.content)

  useEffect(() => {
    fetchSingleCompany(request?.company)
    fetchSingleUser(request.user)
  },[request])

  const dateToYMD = date => {
    const formatedDate = date?.split('T')[0]
    console.log("DATE", formatedDate)
    var newDate = new Date(formatedDate)
    var today = new Date()
    var strArray = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ]
    var days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    var d = newDate.getDate()
    var dayName = days[newDate.getDay()]
    var m = strArray[newDate.getMonth()]
    var y = newDate.getFullYear()

    
    return '' + m + ' ' + (d <= 9 ? '0' + d : d) + ', ' + y
  }


  const fetchSingleCompany =  async(companyId) => {
    const sendRequest = async () => {
      const response = await fetch(
        `${env.primeBridgeUrl}/api/company/${companyId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("fetching company failed.");
      }

      const result = await response.json();

      console.log("Results get company redux", result);
      setCompanyName(result.companyName)

      return result;
    };

    try {
      const data = await sendRequest();
    } catch (error) {
      console.log(error);
    }
};


const  fetchSingleUser = async(userId) => {
  const sendRequest = async () => {
    const response = await fetch(`${env.primeBridgeUrl}/api/auth/user/${userId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Getting user failed.");
    }

    const result = await response.json();
   
    console.log("Results get user redux", result);

    setCustomerName(`${result.firstName} ${result.lastName}`)

    return result;
  };

  try {
    const data = await sendRequest();

  } catch (error) {
    console.log(error);
  }
};

  return (
    <tr>
      <td class={`${tableClasses.row} ${tableClasses.first}`}>{companyName}</td>
      <td class={tableClasses.row}>{isSolved ? dateToYMD(request?.updatedAt) : dateToYMD(request?.createdAt)}</td>
      <td class={tableClasses.row}>{request?.subject}</td>

      <td class={tableClasses.row}>
        <div className={tableClasses.flexItem}>
          <p style={{ color: "#D0D0D0", width: "100px" }}>
            {request?.lastMessage?.content}
          </p>
          <div>
            <svg
              width="20"
              height="20"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => setIsOpen(true)}
            >
              <path
                d="M16 12.5H12.5M12.5 12.5H9M12.5 12.5V9M12.5 12.5V16M18.3333 23H6.66667C4.08934 23 2 20.9107 2 18.3333V6.66667C2 4.08934 4.08934 2 6.66667 2H18.3333C20.9107 2 23 4.08934 23 6.66667V18.3333C23 20.9107 20.9107 23 18.3333 23Z"
                stroke="#0077B5"
                stroke-width="3"
                stroke-linecap="round"
              />
            </svg>
          </div>
        </div>
      </td>

      <CustomerSupportItemModal
        isSolved={isSolved}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        company={companyName}
        date={dateToYMD(request?.createdAt)}
        subject={request?.subject}
        message={request?.lastMessage?.content}
        requestId={request._id}
        customerName={customerName}
      />
    </tr>
  );
};

export default CustomerSupportItem;
