import React, { useEffect, useState } from "react";
import SharedModal from "../shared/Modal";
import classes from "./styles/withdrawal-modal.module.css";
import { env } from "../../constants/env";
import {
  fetchAllDepositRequests,
  fetchPendingDepositRequests,
} from "../../Redux/requests-actions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { numberWithCommas } from "../../utilities/functions";

const DepositRequestModal = ({
  isOpen,
  setIsOpen,
  isNewRequest,
  status = "pending",
  request,
  companyName,
  accountName,
  userName,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedStatus, setSelectedStatus] = useState("");
  const [depositFeeIsOpen, setDepositFeeIsOpen] = useState(false);
  const [depositAmountIsOpen, setDepositAmountIsOpen] = useState(false);
  const [totalDepositIsOpen, setTotalDepositIsOpen] = useState(false);

  const [isSending, setIsSending] = useState(false);

  const [depositFee, setDepositFee] = useState("");
  const [depositAmount, setDepositAmount] = useState("");
  const [totalDeposit, setTotalDeposit] = useState("");

  useEffect(() => {

    setDepositFee(request?.fee)
    setDepositAmount(request?.amount)
    setTotalDeposit(request?.totalAmount)
    setDepositAmount(request?.netPayout)
    setDepositFee(request?.withdrawalFee)
    setTotalDeposit(request?.totalWithdrawal)
  },[])


  const dateToYMD = date => {
    const formatedDate = date.split('T')[0]
    console.log("DATE", formatedDate)
    var newDate = new Date(date)
    var fullDate = new Date(date)

    var strArray = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    var d = newDate.getDate();
    var dayName = days[newDate.getDay()];
    var m = strArray[newDate.getMonth()];
    var y = newDate.getFullYear();
    var hr = newDate.getHours();
    var min = newDate.getMinutes();

    if (min < 10) {
      min = "0" + min;
    }
    var ampm = "AM";
    if (hr > 12) {
      hr -= 12;
      ampm = "PM";
    }

    return {
      full:
        "" +
        m +
        " " +
        (d <= 9 ? "0" + d : d) +
        ", " +
        (hr <= 9 ? "0" + hr : hr) +
        ":" +
        min +
        " " +
        ampm,
      date: d,
      month: m,
    };
  };

  const requestStatusUpdate = async () => {
    let dataContent = { status: selectedStatus };
    const sendRequest = async () => {
      setIsSending(true);
      const response = await fetch(
        `${env.primeBridgeUrl}/api/transaction/update-status/${request._id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataContent),
        }
      );

      if (!response.ok) {
        setIsSending(false);
        const error = await response.json();

        console.log("ERROR", error);

        throw new Error("sending plays failed.");
      }

      const result = await response.json();

      console.log("Reply from REQUEST UPDATE data", result);
      if (isNewRequest) {
        dispatch(fetchPendingDepositRequests());
      } else {
        dispatch(fetchAllDepositRequests());
      }

      setIsSending(false);
      setIsOpen(false);
      navigate("/financial-management/funds/deposit");
      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      setIsSending(false);
      console.log(error);
    }
  };

  const requestFeeUpdate = async () => {
    let dataContent = { fee: depositFee };
    const sendRequest = async () => {
      const response = await fetch(
        `${env.primeBridgeUrl}/api/transaction/update-fee/${request._id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataContent),
        }
      );

      if (!response.ok) {
        const error = await response.json();

        console.log("ERROR", error);

        throw new Error("sending plays failed.");
      }

      const result = await response.json();

      console.log("Reply from REQUEST UPDATE data", result);
      if (isNewRequest) {
        dispatch(fetchPendingDepositRequests());
      } else {
        dispatch(fetchAllDepositRequests());
      }
      setDepositFeeIsOpen(false);

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };

  const requestAmountUpdate = async () => {
    let dataContent = { amount: depositAmount };
    const sendRequest = async () => {
      const response = await fetch(
        `${env.primeBridgeUrl}/api/transaction/update-amount/${request._id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataContent),
        }
      );

      if (!response.ok) {
        const error = await response.json();

        console.log("ERROR", error);

        throw new Error("sending plays failed.");
      }

      const result = await response.json();

      console.log("Reply from REQUEST UPDATE data", result);


      setDepositAmount(result.netPayout)
      if(isNewRequest){
        dispatch(fetchPendingDepositRequests())
      }else{
        dispatch(fetchAllDepositRequests())

      }
      setDepositAmountIsOpen(false);

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };

  const requestTotalUpdate = async () => {
    let dataContent = { totalAmount: totalDeposit };
    const sendRequest = async () => {
      const response = await fetch(
        `${env.primeBridgeUrl}/api/transaction/update-total/${request._id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataContent),
        }
      );

      if (!response.ok) {
        const error = await response.json();

        console.log("ERROR", error);

        throw new Error("sending plays failed.");
      }

      const result = await response.json();

      console.log("Reply from REQUEST UPDATE data", result);
      if (isNewRequest) {
        dispatch(fetchPendingDepositRequests());
      } else {
        dispatch(fetchAllDepositRequests());
      }
      setTotalDepositIsOpen(false);

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <SharedModal className={classes.modal} show={isOpen} setShow={setIsOpen}>
      <div className={classes.body}>
        <div className={classes.headerSection}>
          <p className={classes.header}>
            {isNewRequest ? "New Deposit Request" : "Deposit Request"}
          </p>
        </div>

        <div>
          <div className={classes.group}>
            <div className={classes.groupItem}>
              <p className={classes.label}>Request details</p>

              <p className={classes.value}>
                {dateToYMD(request?.createdAt).full}
              </p>
            </div>

            <div className={classes.groupItem}>
              <p className={classes.label}>Company</p>

              <p className={classes.value}>{companyName}</p>
            </div>
          </div>
          <div className={classes.group}>
            <div className={classes.groupItem}>
              <p className={classes.label}>Team Member</p>

              <p className={classes.value}>{userName}</p>
            </div>

            <div className={classes.groupItem}>
              <p className={classes.label}>Trading Account</p>

              <p className={classes.value}>{accountName}</p>
            </div>
          </div>
          <div className={classes.group}>
            <div className={classes.groupItem}>
              <p className={classes.label}>Method</p>

              <p className={classes.value}>
                {request?.paymentMethod === "cash-account"
                  ? "Cash Account"
                  : request?.paymentMethod === "crypto"
                  ? `Crypto (${request?.currency?.toUpperCase()})`
                  : "Wire Transfer"}
              </p>
            </div>

            <div className={classes.groupItem}>
              <p className={classes.label}>Deposit Fee</p>

              {!depositFeeIsOpen ? (
                <p className={classes.value}>
                  {`${
                    request?.currency === "usd" ||
                    request?.paymentMethod === "crypto"
                      ? "$"
                      : request?.currency === "euro" && "€"
                  } ${numberWithCommas(
                    parseFloat(request?.withdrawalFee).toFixed(2)
                  )} ${
                    request?.currency === "usd" ||
                    request?.paymentMethod === "crypto"
                      ? "USD"
                      : request?.currency === "euro" && "EUR"
                  }`}
                  {isNewRequest && (
                    <svg
                      width="16"
                      height="15"
                      viewBox="0 0 16 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => setDepositFeeIsOpen(true)}
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M9.85792 0.927237C10.9223 -0.137147 12.648 -0.137147 13.7124 0.927237L14.4833 1.69813C15.5477 2.76251 15.5477 4.48822 14.4833 5.55261L5.618 14.4179L2.08284 14.4179C1.48073 14.4179 0.992628 13.9298 0.992628 13.3277L0.992628 9.79253L9.85792 0.927237ZM12.1706 2.46903L12.9415 3.23992C13.1544 3.4528 13.1544 3.79794 12.9415 4.01082L11.7852 5.16716L10.2434 3.62537L11.3997 2.46903C11.6126 2.25615 11.9577 2.25615 12.1706 2.46903ZM10.2434 6.70895L4.71484 12.2375L3.17305 12.2375L3.17305 10.6957L8.70158 5.16716L10.2434 6.70895Z"
                        fill="black"
                      />
                    </svg>
                  )}
                </p>
              ) : (
                <div className={classes.form_group}>
                  <input
                    value={depositFee}
                    onChange={(e) => setDepositFee(e.target.value)}
                  />
                  <button onClick={requestFeeUpdate}>Save</button>
                </div>
              )}
            </div>
          </div>
          <div className={classes.group}>
            <div className={classes.groupItem}>
              <p className={classes.label}>Deposit Amount</p>

              {!depositAmountIsOpen ? (
                <p className={classes.value}>
                  {`${request?.currency === "usd" || request?.paymentMethod === "crypto" ? "$" : request?.currency === "euro"  && "€"} ${numberWithCommas(depositAmount)} ${request?.currency === "usd" || request?.paymentMethod === "crypto" ? "USD" : request?.currency === "euro"  && "EUR"}`}

                  {isNewRequest && (
                    <svg
                      width="16"
                      height="15"
                      viewBox="0 0 16 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => setDepositAmountIsOpen(true)}
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M9.85792 0.927237C10.9223 -0.137147 12.648 -0.137147 13.7124 0.927237L14.4833 1.69813C15.5477 2.76251 15.5477 4.48822 14.4833 5.55261L5.618 14.4179L2.08284 14.4179C1.48073 14.4179 0.992628 13.9298 0.992628 13.3277L0.992628 9.79253L9.85792 0.927237ZM12.1706 2.46903L12.9415 3.23992C13.1544 3.4528 13.1544 3.79794 12.9415 4.01082L11.7852 5.16716L10.2434 3.62537L11.3997 2.46903C11.6126 2.25615 11.9577 2.25615 12.1706 2.46903ZM10.2434 6.70895L4.71484 12.2375L3.17305 12.2375L3.17305 10.6957L8.70158 5.16716L10.2434 6.70895Z"
                        fill="black"
                      />
                    </svg>
                  )}
                </p>
              ) : (
                <div className={classes.form_group}>
                  <input
                    value={depositAmount}
                    onChange={(e) => setDepositAmount(e.target.value)}
                  />
                  <button onClick={requestAmountUpdate}>Save</button>
                </div>
              )}
            </div>

            <div className={classes.groupItem}>
              <p className={classes.label}>Total Deposit</p>

              {!totalDepositIsOpen ? (
                <p className={`${classes.value} ${classes.blue}`}>
                  {`${
                    request?.currency === "usd" ||
                    request?.paymentMethod === "crypto"
                      ? "$"
                      : request?.currency === "euro" && "€"
                  } ${numberWithCommas(request?.totalWithdrawal)} ${
                    request?.currency === "usd" ||
                    request?.paymentMethod === "crypto"
                      ? "USD"
                      : request?.currency === "euro" && "EUR"
                  }`}
                  {isNewRequest && (
                    <svg
                      width="16"
                      height="15"
                      viewBox="0 0 16 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => setTotalDepositIsOpen(true)}
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M9.85792 0.927237C10.9223 -0.137147 12.648 -0.137147 13.7124 0.927237L14.4833 1.69813C15.5477 2.76251 15.5477 4.48822 14.4833 5.55261L5.618 14.4179L2.08284 14.4179C1.48073 14.4179 0.992628 13.9298 0.992628 13.3277L0.992628 9.79253L9.85792 0.927237ZM12.1706 2.46903L12.9415 3.23992C13.1544 3.4528 13.1544 3.79794 12.9415 4.01082L11.7852 5.16716L10.2434 3.62537L11.3997 2.46903C11.6126 2.25615 11.9577 2.25615 12.1706 2.46903ZM10.2434 6.70895L4.71484 12.2375L3.17305 12.2375L3.17305 10.6957L8.70158 5.16716L10.2434 6.70895Z"
                        fill="black"
                      />
                    </svg>
                  )}
                </p>
              ) : (
                <div className={classes.form_group}>
                  <input
                    value={totalDeposit}
                    onChange={(e) => setTotalDeposit(e.target.value)}
                  />
                  <button onClick={requestTotalUpdate}>Save</button>
                </div>
              )}
            </div>
          </div>
        </div>

        {request?.status === "pending" ? (
          <>
            <div className={classes.actionBox}>
              <div className={classes.heading}>
                <p className={classes.label}>Status</p>
              </div>

              <div className={classes.content}>
                <div className={classes.statuses}>
                  <label class={classes.container}>
                    Received
                    <input
                      type="radio"
                      value={"received"}
                      name="status"
                      onChange={(e) => setSelectedStatus(e.target.value)}
                    />
                    <span class={classes.checkmark}></span>
                  </label>

                  <label class={classes.container}>
                    Not Received
                    <input
                      value={"not-received"}
                      type="radio"
                      name="status"
                      onChange={(e) => setSelectedStatus(e.target.value)}
                    />
                    <span class={classes.checkmark}></span>
                  </label>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className={classes.decision}>
            <div className={classes.heading}>
              <p
                className={`${classes.status} ${
                  request?.status == "received"
                    ? classes.success
                    : classes.danger
                }`}
              >
                {request?.status == "received"
                  ? "Funds were added to the account."
                  : "Funds were not received."}
              </p>
            </div>

            <div className={classes.message}>
              <p>Admin review done {dateToYMD(request?.updatedAt).full}.</p>
            </div>
          </div>
        )}

        {request?.status === "pending" && (
          <div>
            {!isSending && (
              <button
                className={classes.actionBtn}
                onClick={requestStatusUpdate}
              >
                Process
              </button>
            )}
            {isSending && (
              <div className="d-flex justify-content-center align-items-center">
                <div className="spinner-border" role="status"></div>
              </div>
            )}
          </div>
        )}
      </div>
    </SharedModal>
  );
};

export default DepositRequestModal;
