import React, { useEffect, useState } from "react";
import classes from "./PBShareSettings.module.css";
import { env } from "../../constants/env";
import { useDispatch } from "react-redux";
import { fetchAllAccounts } from "../../Redux/accounts-actions";

const PBShareSettings = ({pbSettings, accountId, companyId}) => {

  const dispatch = useDispatch()
  const [isEdit, setIsEdit] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [selectedOption, setSelectedOption] = useState();

  // console.log("PBSETTINGS", selectedOption)

  useEffect(() => {
    if(pbSettings <= 0){
      setSelectedOption("0.03")
    }else{
      setSelectedOption(pbSettings)
    }
  },[pbSettings])

  const updateDepositSettingsHandler = async () => {
    let dataContent = {pbShareSettings: selectedOption}
    const sendRequest = async () => {
      setIsSending(true)
      const response = await fetch(`${env.primeBridgeUrl}/api/account/update-pbshare/${accountId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataContent)
      });

      if (!response.ok) {
        setIsSending(false)
        const error = await response.json();
        console.log("ERRORRR", error)
        throw new Error("sending plays failed.");
      }

      const result = await response.json();

      setIsEdit(false)
      dispatch(fetchAllAccounts(companyId))

      console.log("Reply from plays data", result);

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      setIsSending(false)
      console.log(error);
    }
  };
  return (
    <div className={`${classes.transactionItem} d-flex align-items-center`}>
      <p className={classes.transactionText}>
        PB Share:{" "}
        {!isEdit && (
          <span>
            {pbSettings ? +pbSettings * 100 : 0}%{" "}
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => setIsEdit(true)}
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.35082 1.01441C10.4634 -0.0981163 12.2671 -0.098116 13.3796 1.01441L14.1854 1.82018C15.2979 2.93271 15.2979 4.73648 14.1854 5.84901L4.91911 15.1153L1.22404 15.1153C0.594699 15.1153 0.0845151 14.6051 0.0845156 13.9758L0.0845146 10.2807L9.35082 1.01441ZM11.7681 2.62595L12.5739 3.43171C12.7964 3.65422 12.7964 4.01497 12.5739 4.23748L11.3652 5.44613L9.7537 3.83459L10.9624 2.62595C11.1849 2.40344 11.5456 2.40344 11.7681 2.62595ZM9.7537 7.05766L3.9751 12.8363L2.36356 12.8363L2.36356 11.2247L8.14217 5.44613L9.7537 7.05766Z"
                fill="#0077B5"
              />
            </svg>
          </span>
        )}
      </p>
      {isEdit && (
        <div className={classes.transactionEdit}>
          <select onChange={(e) => setSelectedOption(e.target.value)}>
            <option selected={pbSettings === "0.02" && pbSettings} value="0.02">2%</option>
            <option selected={pbSettings === "0.03" && pbSettings} value="0.03">3%</option>
            <option selected={pbSettings === "0.04" && pbSettings} value="0.04">4%</option>
            <option selected={pbSettings === "0.05" && pbSettings} value="0.05">5%</option>
            <option selected={pbSettings === "0.10" && pbSettings} value="0.10">10%</option>
          </select>

          <button onClick={updateDepositSettingsHandler}>Save</button>
        </div>
      )}
    </div>
  );
};

export default PBShareSettings;
