import React, { useEffect, useState } from "react";
import classes from "./customer-support.module.css";
import tableClasses from "../shared/styles/customTable.module.css";
import CustomerSupportItem from "./CustomerSupportItem";
import { env } from "../../constants/env";

const MainPage = () => {

  const [unsolved, setUnsolved] = useState([])
  const [solved, setSolved] = useState([])

  useEffect(() => {
    getNewRequests()
    getSolvedRequests()
  },[])


  const getNewRequests = async () => {
    const sendRequest = async () => {
      const response = await fetch(`${env.primeBridgeUrl}/api/conversation/unsolved`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        const error = await response.json();
        console.log("ERROR RESPONSE", error?.error)
        throw new Error("sending plays failed.");
      }

      const result = await response.json();
      setUnsolved(result)
      // console.log("UNSOLVED", result);

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };

  const getSolvedRequests = async () => {
    const sendRequest = async () => {
      const response = await fetch(`${env.primeBridgeUrl}/api/conversation/solved`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        const error = await response.json();
        console.log("ERROR RESPONSE", error?.error)
        throw new Error("sending plays failed.");
      }

      const result = await response.json();
      setSolved(result)
      console.log("UNSOLVED", result);

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.navContainer}>
        <p className={classes.headerSection}>Customer Support</p>

        <div className={classes.actionsContainer}>
          <div className={classes.searchContainer}>
            <div className="searchIcon">
              <svg
                width="26"
                height="20"
                viewBox="0 0 26 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="10.5"
                  cy="10.5"
                  r="9"
                  stroke="#ABABAB"
                  stroke-width="3"
                />
                <rect
                  x="15"
                  y="16.9434"
                  width="3"
                  height="13.1583"
                  rx="1.5"
                  transform="rotate(-40.3606 15 16.9434)"
                  fill="#ABABAB"
                />
              </svg>
            </div>
            <input
              className={classes.searchInput}
              placeholder="Search"
              type="text"
              name=""
              id=""
            />
          </div>
        </div>
      </div>
      <div>
        <h2 className={classes.tableTitle}>New Requests</h2>
        <table class={tableClasses.container}>
          <thead style={{ display: "none" }} class="bg-gray-50">
            <tr>
              <th
                scope="col"
                class={`${tableClasses.header} ${tableClasses.first}`}
              >
                Company Name
              </th>
              <th scope="col" class={tableClasses.header}>
                Director
              </th>
              <th scope="col" class={tableClasses.header}>
                Email
              </th>
              <th scope="col" class={tableClasses.header}>
                Website
              </th>
              <th scope="col" class={tableClasses.header}>
                Headquarters
              </th>
            </tr>
          </thead>
          <tbody class={tableClasses.body}>
            {unsolved.sort(
          (a, b) =>
            new Date(b.createdAt) - new Date(a.createdAt)
        ).map((item, i) => (
              <CustomerSupportItem  key={i} request={item}/>
            ))}
          </tbody>
        </table>
      </div>
      <div className={classes.solvedTable}>
        <h2 className={classes.tableTitle}>Solved</h2>
        <table class={tableClasses.container}>
          <thead style={{ display: "none" }} class="bg-gray-50">
            <tr>
              <th
                scope="col"
                class={`${tableClasses.header} ${tableClasses.first}`}
              >
                Company Name
              </th>
              <th scope="col" class={tableClasses.header}>
                Director
              </th>
              <th scope="col" class={tableClasses.header}>
                Email
              </th>
              <th scope="col" class={tableClasses.header}>
                Website
              </th>
              <th scope="col" class={tableClasses.header}>
                Headquarters
              </th>
            </tr>
          </thead>
          <tbody class={tableClasses.body}>
            {solved?.sort(
          (a, b) =>
            new Date(b.createdAt) - new Date(a.createdAt)
        ).map((item, i) => (
              <CustomerSupportItem isSolved request={item}  key={i}/>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MainPage
