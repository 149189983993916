const InfoIcon = ({fillColor, size, onMouseOver, onMouseOut}) => {
  return (
    <svg
      width={size ? size : "20"}
      height={size ? size : "20"}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="button"
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    >
      <path
        d="M10 5C10.5523 5 11 5.44772 11 6V11C11 11.5523 10.5523 12 10 12C9.44771 12 9 11.5523 9 11V6C9 5.44772 9.44771 5 10 5Z"
        fill={fillColor ? "#FFFFFF" : "#EEEEEE"}
      />
      <path
        d="M10 15C10.5523 15 11 14.5523 11 14C11 13.4477 10.5523 13 10 13C9.44771 13 9 13.4477 9 14C9 14.5523 9.44771 15 10 15Z"
        fill={fillColor ? "#FFFFFF" : "#EEEEEE"}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0ZM2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10Z"
        fill={fillColor ? "#FFFFFF" : "#EEEEEE"}
      />
    </svg>
  );
};
export default InfoIcon;
