import React, { useEffect, useState } from "react";
import SharedModal from "../shared/Modal";
import classes from "./remove.module.css";
import modalClasses from "./edit-company.module.css";
import { env } from "../../constants/env";
import { useDispatch } from "react-redux";
import { fetchAllAccounts, fetchAllMt4Accounts } from "../../Redux/accounts-actions";
import DropDownIcon from "../icons/DropDownIcon";


const AddNewMT4AccountModal = ({ isOpen, setIsOpen, isEdit, companyId, accountId, overview, management, lpAccounts=[], addCashAccount }) => {

  const dispatch = useDispatch()
  const [accountName, setAccountName] = useState()
  const [accountID, setAccountID] = useState()
  const [masterPassword, setMasterPassword] = useState()
  const [investorPassword, setInvestorPassword] = useState()
  const [apiId, setApiId] = useState()
  const [depositCorrection, setDepositCorrection] = useState()
  const [isSending, setIsSending] = useState(false)
  const [dropDown, setDropDown] = useState(false)
  const [selectedOption, setSelectedOption] = useState()
  const [cashAccountOn, setCashAccountOn] = useState(false)
  


  

  useEffect(() =>{
    if(isEdit){
      getSingleAccount()
    }
   
  }, [accountId])

  const addAccountHandler = async () => {
    let dataContent = { accountName: accountName, credentials: {accountNumber: accountID, apiId }}
    const sendRequest = async () => {
      setIsSending(true)
      const response = await fetch(`${env.primeBridgeUrl}/api/account/add-mt4`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataContent)
      });

      if (!response.ok) {
        setIsSending(false)
        const result = await response.json();
        console.log("ERRRR", result)
        throw new Error("sending plays failed.");
      }

      const result = await response.json();
      setIsSending(false)
      setIsOpen(false);

      setAccountName("")
      setAccountID("")
      setApiId("")

      dispatch(fetchAllMt4Accounts())

      console.log("Reply from plays data", result);

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      setIsSending(false)
      console.log(error);
    }
  };

  const getSingleAccount = async () => {
    const sendRequest = async () => {
      const response = await fetch(`${env.primeBridgeUrl}/api/account/${accountId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Getting account failed.");
      }

      const result = await response.json();

      console.log("ACCOUNT I WANT TO SEE", result)

      setAccountName(result.accountName)
      setAccountID(result?.credentials?.accountNumber)
      setMasterPassword(result?.credentials?.masterPassword)
      setInvestorPassword(result?.credentials?.investorPassword)
      setApiId(result?.credentials?.apiId)
      setDepositCorrection(result?.credentials?.depositCorrection)
      setCashAccountOn(result.cashAccount)

      if(isEdit)
        getLPAccount(result?.lpAccount)

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };


  const getLPAccount = async (id) => {
    const sendRequest = async () => {
      const response = await fetch(`${env.primeBridgeUrl}/api/account/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Getting account failed.");
      }

      const result = await response.json();

      console.log("OVERVIEW STATE: ", result)

      setSelectedOption(result)

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };

  const updateAccount = async () => {
    let dataContent = { accountName: accountName, credentials: {accountNumber: accountID, apiId}}
    const sendRequest = async () => {
      setIsSending(true)
      const response = await fetch(`${env.primeBridgeUrl}/api/account/update-account/${accountId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataContent)
      });

      if (!response.ok) {
        setIsSending(false)
        throw new Error("Getting account failed.");
      }

      const result = await response.json();
      setIsSending(false)
      setIsOpen(false);
      dispatch(fetchAllMt4Accounts(companyId))
      
      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      setIsSending(false)
      console.log(error);
    }
  };

  
  return (
    <SharedModal
      className={modalClasses.modal}
      show={isOpen}
      setShow={setIsOpen}
    >
      <div className={modalClasses.container}>
        <p className={modalClasses.header}>
          {isEdit ? "Edit" : "Add"} MT4 Login
        </p>
        <div className={modalClasses.inputContainer}>
          <p className={modalClasses.label}>Account Name</p>
          <input type="text" className={modalClasses.input} value={accountName} onChange={(e) => setAccountName(e.target.value)}/>
        </div>
        <div className={modalClasses.inputContainer}>
          <p className={modalClasses.label}>Account ID</p>
          <input type="text" className={modalClasses.input} value={accountID} onChange={(e) => setAccountID(e.target.value)} />
        </div>

        <div className={modalClasses.inputContainer}>
          <p className={modalClasses.label}>API id</p>
          <input
            type="text"
            className={`${modalClasses.input}`}
            value={apiId}
            onChange={(e) => setApiId(e.target.value)}
          />
        </div>

        <div className={classes.btnContainer}>
        {!isSending && (<button className={modalClasses.confirm} onClick={() => { 
            if(isEdit){
              updateAccount()
            }else{
              addAccountHandler()
            }
          }}>
            {isEdit ? "Update" : "Save"}
          </button>)}
          {isSending && (
          <div className="spinner-border" role="status">
          </div>
        )}
        </div>
      </div>
    </SharedModal>
  );
};

export default AddNewMT4AccountModal;
