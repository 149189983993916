import React, { useEffect } from "react";
import MainLayout from "../../components/Layout/MainLayout";
import AllLeadsPage from "../../components/Leads/AllLeadsPage";

const AllLeads = () => {
  useEffect(() => {
    document.title = "All Leads - Prime Bridge Admin";
  }, []);
  return (
    <MainLayout>
      <AllLeadsPage />
    </MainLayout>
  );
};

export default AllLeads;
