import React, { useState } from "react";
import classes from "./detailpage.module.css";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import RemoveDocumentModal from "./RemoveDocument";

const CompliantItem = ({file, companyId}) => {
  const [isRemoveOpen, setIsRemoveOpen] = useState(false);
  const hidePopover = () => {
    document.body.click();
  };
  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        <div
          onClick={() => {
            setIsRemoveOpen(true);
            hidePopover();
          }}
          className={`${classes.popover_item} ${classes.last}`}
        >
          <svg
            width="17"
            height="21"
            viewBox="0 0 17 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.66565 9.1534C1.57629 7.99181 2.49473 7 3.65975 7H13.3402C14.5053 7 15.4237 7.9918 15.3344 9.15339L14.642 18.1534C14.5619 19.1954 13.693 20 12.6479 20H4.35206C3.30699 20 2.43811 19.1954 2.35795 18.1534L1.66565 9.1534Z"
              stroke="#BD3437"
              stroke-width="2"
            />
            <path
              d="M16 4H1"
              stroke="#BD3437"
              stroke-width="2"
              stroke-linecap="round"
            />
            <path
              d="M6.5 2C6.5 1.44772 6.94772 1 7.5 1H9.5C10.0523 1 10.5 1.44772 10.5 2V4H6.5V2Z"
              stroke="#BD3437"
              stroke-width="2"
            />
            <path
              d="M10.5 11V16"
              stroke="#BD3437"
              stroke-width="2"
              stroke-linecap="round"
            />
            <path
              d="M6.5 11V16"
              stroke="#BD3437"
              stroke-width="2"
              stroke-linecap="round"
            />
          </svg>

          <p style={{ color: "#BD3437" }}>Remove Document</p>
        </div>
      </Popover.Body>
    </Popover>
  );
  return (
    <div className={classes.compliantItem}>
      <RemoveDocumentModal isOpen={isRemoveOpen} setIsOpen={setIsRemoveOpen} file={file} companyId={companyId}/>
      <svg
        width="31"
        height="30"
        viewBox="0 0 31 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M29.5607 11.8243C29.2471 11.8243 28.9463 11.9489 28.7246 12.1706C28.5028 12.3924 28.3783 12.6931 28.3783 13.0067V34.2904C28.3783 34.604 28.2537 34.9048 28.0319 35.1265C27.8102 35.3483 27.5094 35.4728 27.1958 35.4728H3.54728C3.23368 35.4728 2.93293 35.3483 2.71118 35.1265C2.48943 34.9048 2.36486 34.604 2.36486 34.2904V3.54728C2.36486 3.23368 2.48943 2.93293 2.71118 2.71118C2.93293 2.48943 3.23368 2.36486 3.54728 2.36486H21.2837V8.27699C21.2837 8.59059 21.4083 8.89135 21.63 9.1131C21.8518 9.33484 22.1525 9.45942 22.4661 9.45942H29.5607C29.7956 9.46079 30.0255 9.39218 30.2212 9.26235C30.4169 9.13252 30.5695 8.94734 30.6596 8.73043C30.7497 8.51352 30.7731 8.27471 30.7269 8.04443C30.6807 7.81416 30.567 7.60286 30.4002 7.43747L23.3057 0.342904C23.095 0.145821 22.8244 0.0250792 22.5371 0C22.1823 0 21.5793 0 3.54728 0C2.60649 0 1.70422 0.37373 1.03898 1.03898C0.373731 1.70422 0 2.60648 0 3.54728V34.2904C0 35.2312 0.373731 36.1335 1.03898 36.7987C1.70422 37.464 2.60649 37.8377 3.54728 37.8377H27.1958C28.1366 37.8377 29.0389 37.464 29.7041 36.7987C30.3694 36.1335 30.7431 35.2312 30.7431 34.2904V13.0067C30.7431 12.6931 30.6185 12.3924 30.3968 12.1706C30.175 11.9489 29.8743 11.8243 29.5607 11.8243ZM23.6486 7.09457V4.03208L26.711 7.09457H23.6486Z"
          fill="#033C59"
        />
        <path
          d="M23.6485 16.5543H13.0066C12.693 16.5543 12.3923 16.4297 12.1705 16.208C11.9488 15.9862 11.8242 15.6855 11.8242 15.3719C11.8242 15.0583 11.9488 14.7575 12.1705 14.5358C12.3923 14.314 12.693 14.1895 13.0066 14.1895H23.6485C23.9621 14.1895 24.2629 14.314 24.4846 14.5358C24.7063 14.7575 24.8309 15.0583 24.8309 15.3719C24.8309 15.6855 24.7063 15.9862 24.4846 16.208C24.2629 16.4297 23.9621 16.5543 23.6485 16.5543Z"
          fill="#D0D0D0"
        />
        <path
          d="M23.6485 21.2848H7.09454C6.78094 21.2848 6.48018 21.1602 6.25843 20.9385C6.03669 20.7167 5.91211 20.4159 5.91211 20.1023C5.91211 19.7888 6.03669 19.488 6.25843 19.2662C6.48018 19.0445 6.78094 18.9199 7.09454 18.9199H23.6485C23.9621 18.9199 24.2629 19.0445 24.4846 19.2662C24.7064 19.488 24.831 19.7888 24.831 20.1023C24.831 20.4159 24.7064 20.7167 24.4846 20.9385C24.2629 21.1602 23.9621 21.2848 23.6485 21.2848Z"
          fill="#D0D0D0"
        />
        <path
          d="M23.6485 26.0133H7.09454C6.78094 26.0133 6.48018 25.8887 6.25843 25.667C6.03669 25.4452 5.91211 25.1445 5.91211 24.8309C5.91211 24.5173 6.03669 24.2165 6.25843 23.9948C6.48018 23.773 6.78094 23.6484 7.09454 23.6484H23.6485C23.9621 23.6484 24.2629 23.773 24.4846 23.9948C24.7064 24.2165 24.831 24.5173 24.831 24.8309C24.831 25.1445 24.7064 25.4452 24.4846 25.667C24.2629 25.8887 23.9621 26.0133 23.6485 26.0133Z"
          fill="#D0D0D0"
        />
        <path
          d="M17.7364 30.7438H7.09454C6.78094 30.7438 6.48018 30.6192 6.25843 30.3974C6.03669 30.1757 5.91211 29.8749 5.91211 29.5613C5.91211 29.2477 6.03669 28.947 6.25843 28.7252C6.48018 28.5035 6.78094 28.3789 7.09454 28.3789H17.7364C18.05 28.3789 18.3507 28.5035 18.5725 28.7252C18.7942 28.947 18.9188 29.2477 18.9188 29.5613C18.9188 29.8749 18.7942 30.1757 18.5725 30.3974C18.3507 30.6192 18.05 30.7438 17.7364 30.7438Z"
          fill="#D0D0D0"
        />
      </svg>
      <a
        style={{ textDecoration: "none", color: "black" }}
        href={`https://cdn.traders-central.s3.amazonaws.com/${file.key}`}
        download
        target={"_blank"}
      >
        <p className={classes.compliantItemText}>
          {file.fileType}
        </p>
      </a>
      <OverlayTrigger
        trigger="click"
        placement="bottom"
        overlay={popover}
        rootClose
      >
        <svg
          width="15"
          height="4"
          viewBox="0 0 15 4"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 1.575C0 1.35833 0.0374999 1.15417 0.1125 0.9625C0.195833 0.770833 0.304167 0.604167 0.4375 0.4625C0.579167 0.320834 0.745833 0.208333 0.9375 0.125C1.12917 0.0416666 1.33333 0 1.55 0C1.76667 0 1.97083 0.0416666 2.1625 0.125C2.35417 0.208333 2.52083 0.320834 2.6625 0.4625C2.80417 0.604167 2.91667 0.770833 3 0.9625C3.08333 1.15417 3.125 1.35833 3.125 1.575C3.125 1.8 3.08333 2.00833 3 2.2C2.91667 2.38333 2.80417 2.54583 2.6625 2.6875C2.52083 2.82917 2.35417 2.9375 2.1625 3.0125C1.97083 3.09583 1.76667 3.1375 1.55 3.1375C1.33333 3.1375 1.12917 3.09583 0.9375 3.0125C0.745833 2.9375 0.579167 2.82917 0.4375 2.6875C0.304167 2.54583 0.195833 2.38333 0.1125 2.2C0.0374999 2.00833 0 1.8 0 1.575Z"
            fill="#0077B5"
          />
          <path
            d="M5.9082 1.575C5.9082 1.35833 5.9457 1.15417 6.0207 0.9625C6.10404 0.770833 6.21237 0.604167 6.3457 0.4625C6.48737 0.320834 6.65404 0.208333 6.8457 0.125C7.03737 0.0416666 7.24154 0 7.4582 0C7.67487 0 7.87904 0.0416666 8.0707 0.125C8.26237 0.208333 8.42904 0.320834 8.5707 0.4625C8.71237 0.604167 8.82487 0.770833 8.9082 0.9625C8.99154 1.15417 9.0332 1.35833 9.0332 1.575C9.0332 1.8 8.99154 2.00833 8.9082 2.2C8.82487 2.38333 8.71237 2.54583 8.5707 2.6875C8.42904 2.82917 8.26237 2.9375 8.0707 3.0125C7.87904 3.09583 7.67487 3.1375 7.4582 3.1375C7.24154 3.1375 7.03737 3.09583 6.8457 3.0125C6.65404 2.9375 6.48737 2.82917 6.3457 2.6875C6.21237 2.54583 6.10404 2.38333 6.0207 2.2C5.9457 2.00833 5.9082 1.8 5.9082 1.575Z"
            fill="#0077B5"
          />
          <path
            d="M11.8164 1.575C11.8164 1.35833 11.8539 1.15417 11.9289 0.9625C12.0122 0.770833 12.1206 0.604167 12.2539 0.4625C12.3956 0.320834 12.5622 0.208333 12.7539 0.125C12.9456 0.0416666 13.1497 0 13.3664 0C13.5831 0 13.7872 0.0416666 13.9789 0.125C14.1706 0.208333 14.3372 0.320834 14.4789 0.4625C14.6206 0.604167 14.7331 0.770833 14.8164 0.9625C14.8997 1.15417 14.9414 1.35833 14.9414 1.575C14.9414 1.8 14.8997 2.00833 14.8164 2.2C14.7331 2.38333 14.6206 2.54583 14.4789 2.6875C14.3372 2.82917 14.1706 2.9375 13.9789 3.0125C13.7872 3.09583 13.5831 3.1375 13.3664 3.1375C13.1497 3.1375 12.9456 3.09583 12.7539 3.0125C12.5622 2.9375 12.3956 2.82917 12.2539 2.6875C12.1206 2.54583 12.0122 2.38333 11.9289 2.2C11.8539 2.00833 11.8164 1.8 11.8164 1.575Z"
            fill="#0077B5"
          />
        </svg>
      </OverlayTrigger>
    </div>
  );
};

export default CompliantItem;
