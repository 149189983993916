import React, { useEffect, useState } from "react";
import { getCookie } from "../../utilities/functions";
import SharedModal from "../shared/Modal";
import classes from "../FinancialManagement/styles/withdrawal-modal.module.css";
import "./vertical-timeline.css";
import { env } from "../../constants/env";

const CustomerSupportItemModal = ({
  isOpen,
  setIsOpen,
  isSolved = false,
  hasHistory = false,
  company,
  date,
  subject,
  message,
  customerName,
  requestId
}) => {
  const [isSending, setIsSending] = useState()
  const [replyMessage, setReplyMessage] = useState()
  const [messages, setMessages] = useState([]);

  const userId = getCookie("userId");

  useEffect(() => {
    getMessages()
  }, [])


  const dateToYMD = date => {
    const formatedDate = date.split('T')[0]
    console.log("DATE", formatedDate)
    var newDate = new Date(date)
    var fullDate = new Date(date)
    var strArray = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ]
    var days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    var d = newDate.getDate()
    var dayName = days[newDate.getDay()]
    var m = strArray[newDate.getMonth()]
    var y = newDate.getFullYear()
    var hr = newDate.getHours()
    var min = newDate.getMinutes()

    if (min < 10) {
      min = "0" + min;
    }
    var ampm = "am";
    if( hr > 12 ) {
        hr -= 12;
        ampm = "pm";
    }

    return {
      full: '' + m + ' ' + (d <= 9 ? '0' + d : d) + ', ' + (hr <= 9 ? '0' + hr : hr) + ':' + min + ' ' + ampm,
      date: d,
      month: m
    }
  }

  const  getMessages = async () => {
    const sendRequest = async () => {
      const response = await fetch(`${env.primeBridgeUrl}/api/message/conversations/${requestId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        
      });

      if (!response.ok) {
        throw new Error("sending plays failed.");
      }

      const result = await response.json();
      
      setMessages(result)

      console.log(`Message data`, result);

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };

  const sendMessage = async () => {
    let dataContent = {user: userId, conversation: requestId, message: replyMessage}
    const sendRequest = async () => {
      setIsSending(true)
      const response = await fetch(`${env.primeBridgeUrl}/api/message`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataContent)
      });

      if (!response.ok) {
        const error = await response.json();
        console.log("ERROR RESPONSE", error?.error)
        setIsSending(false)
        throw new Error("sending plays failed.");
      }

      const result = await response.json();
      getMessages()
      setReplyMessage("")
      setIsSending(false)

      console.log("Message saved", result);

      return result;
    };

    try {
        await sendRequest();
    } catch (error) {
      setIsSending(false)
      console.log(error);
    }
  };

  const solvedHandler = async() => {
    let dataContent = {
      status: "solved",
      solvedBy: "support"
    };
    const sendRequest = async () => {
      setIsSending(true);
      const response = await fetch(`${env.primeBridgeUrl}/api/conversation/update/${requestId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataContent),
      });

      if (!response.ok) {
        setIsSending(false);
        const error = await response.json();
        console.log("ERROR RESPONSE", error?.error);
        throw new Error("sending plays failed.");
      }

      const result = await response.json();
      setIsSending(false);

      setIsOpen(false)

      console.log("Reply from plays data", result);

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      setIsSending(false);
      console.log(error);
    }
  }
  return (
    <SharedModal
      className={`${classes.modal} ${classes.custom}`}
      show={isOpen}
      setShow={setIsOpen}
    >
      <div className={classes.body}>
        <div className={classes.headerSection}>
          <p className={classes.header}>Customer Inquiry </p>
        </div>

        <div>
          <div className={classes.group}>
            <div className={classes.groupItem}>
              <p className={classes.label}>Customer</p>

              <p className={classes.value}>{customerName}</p>
            </div>

            <div className={classes.groupItem}>
              <p className={classes.label}>Company</p>

              <p className={classes.value}>{company}</p>
            </div>
          </div>
          <div className={classes.group}>
            <div className={classes.groupItem}>
              <p className={classes.label}>Inquiry Detail</p>

              <p className={classes.value}>{date}</p>
            </div>

            <div className={classes.groupItem}>
              <p className={classes.label}>Subject</p>

              <p className={classes.value}>{subject}</p>
            </div>
          </div>

          {!hasHistory && (
            <div className={classes.group}>
              <div style={{ width: "100%" }} className={classes.groupItem}>
                <p className={classes.label}>Message</p>

                <p className={classes.value}>
                 {message}
                </p>
              </div>
            </div>
          )}

          {/* {!isSolved && hasHistory && ( */}
            <div>
              <p style={{ marginBottom: "1rem" }} className={classes.label}>
                History
              </p>
              {messages.map((message, i) => (<div
            className={`d-flex justify-content-center align-items-start ${classes.stepWrapper}`} key={i}
            id={`stepDiv-${i}`}
          >
            <div
              className={`d-flex flex-column align-items-center ${classes.stepNav}`}
            >
              <div className={classes.stepDot} style={{background: message.user !== userId && "linear-gradient(0deg, #0077B5, #0077B5), linear-gradient(0deg, #0077B5, #0077B5), #0077B5"}}></div>
              {i !== (messages.length - 1) && <div className={classes.verticleDivider} ></div>}
            </div>

            <div className="col">
              <h6>
                <span className="me-1" style={{color: message.user !== userId && "#0077B5"}}>{message.user === userId ? "Support" : "Customer"}</span> | {dateToYMD(message.createdAt).full}
              </h6>
              <p>
                {message.message}
              </p>
            </div>
          </div>))}
          </div>

          {!isSolved && (
            <div className={classes.message_area}>
              <p className={classes.label}>
                {hasHistory ? "Reply" : "Message"}
              </p>

              <textarea
                className={classes.textbox}
                name=""
                id=""
                cols="30"
                rows="5"
                placeholder="Type in the reply..."
                value={replyMessage} onChange={(e) => setReplyMessage(e.target.value)}
              ></textarea>
            </div>
          )}
        </div>

        {isSolved ? (
          <div className={classes.decision}>
            <div className={classes.message}>
              <p>Answer sent Feb 11, 2023 at 11:30 AM PST</p>
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-center align-items-center">
          {!isSending && <div className="d-flex justify-content-center align-items-center gap-4"><button className={classes.actionBtn} onClick={solvedHandler} style={{background: "#033C59"}}>Close Ticket</button> <button className={classes.actionBtn} onClick={sendMessage}>Send</button></div>}
          {isSending && (
              <div className="spinner-border" role="status">
              </div>
            )}
          </div>
        )}
      </div>
    </SharedModal>
  );
};

export default CustomerSupportItemModal;
