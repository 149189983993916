import React, { useEffect, useRef, useState } from "react";
import SharedModal from "../shared/Modal";
import classes from "./view-acc.module.css";
import modalClasses from "./edit-company.module.css";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllAccounts } from "../../Redux/accounts-actions";
import { env } from "../../constants/env";
import LPGRoupItem from "./LPGroupItem";

const ViewGroupDetailModal = ({ isOpen, setIsOpen, accountDetails }) => {
  const dispatch = useDispatch()
  const selectRef = useRef();
  const [revealMaster, setRevealMaster] = useState(false);
  const [revealInvestor, setRevealInvestor] = useState(false);
  const [account, setAccount] = useState();
  const [groupAccounts, setGroupAccounts] = useState([])

  

  const accounts = useSelector(state => state.accounts.allAccounts)

  const handleCopy = async (text) => {
    await navigator.clipboard.writeText(text);
    toast.success("Copied to clipboard");
  };

  useEffect(() => {
    dispatch(fetchAllAccounts(accountDetails.company))
    async function fetchData(){
      let groupAccountsArr = []
      for (let i = 0; i < accountDetails?.lpAccounts?.length; i++) {
        const result = await getAccount(accountDetails?.lpAccounts[i]?._id)
        if(result.length > 0){
          for (let j = 0; j < result.length; j++) {
            groupAccountsArr.push({accountName: result[j].accountName, accountNumber: result[j].credentials.accountNumber, lpAccount: accountDetails?.lpAccounts[i]?.accountName})
          }
        }else{
          groupAccountsArr.push({accountName: "", accountNumber: "", lpAccount: accountDetails?.lpAccounts[i]?.accountName})
        }
      }

      setGroupAccounts(groupAccountsArr)

      console.log("ACCCCCCCOUNT GROUP", groupAccountsArr)

    }

    fetchData();
    
  }, [])

  const onChangeHandler = async(id) => {
    const sendRequest = async () => {
      const response = await fetch(`${env.primeBridgeUrl}/api/account/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Getting account failed.");
      }

      const result = await response.json();

      // setAccount(result)

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  }


  const getAccount = async(id) => {
    try {
      const response = await fetch(`${env.primeBridgeUrl}/api/account/lp-account/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Getting account failed.");
      }

      const result = await response.json();

      console.log("ACCOUNT RESULTS :)", result)

      // setAccount(result)

      return result;
    } catch (error) {
      console.log(error);
    }
  }


  return (
    <SharedModal
      Login
      className={modalClasses.modal}
      show={isOpen}
      setShow={setIsOpen}
    >
      <div className={modalClasses.container}>
        <p className={modalClasses.header}>Group Details</p>

       

        <div className={classes.accDetail}>
          <div className={classes.detailItem}>
            <div>
              <p>Group Name: </p>
            </div>
            <div>
              <p>{accountDetails.groupName}</p>
            </div>
          </div>
          <div className={classes.detailItem}>
            <div>
              <p>NOP Limit: </p>
            </div>
            <div className={classes.value}>
                <p>{accountDetails.nopLimit}</p>
            </div>
          </div>
          <table style={{ width: "100%" }} class={classes.container}>
          <thead class="bg-gray-50">
            <tr>
              <th
                scope="col"
                class={`${classes.header} ${classes.first}`}
              >
                Account name
              </th>
              <th scope="col" class={classes.header}>
                Account number
              </th>
              <th scope="col" class={classes.header}>
                LP Account 
              </th>
            </tr>
          </thead>
          <tbody class={classes.body}>
            {groupAccounts.map((item, i) => (<LPGRoupItem  list={item} key={i}/>))}
          </tbody>
        </table>
        </div>
      </div>
    </SharedModal>
  );
};

export default ViewGroupDetailModal;
