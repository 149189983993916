import React, { useEffect, useState } from "react";
import classes from "../FinancialManagement/styles/withdrawal-modal.module.css";

const DocumentItem = ({ document, documentCheck}) => {

  const [boxChecked, setBoxChecked] = useState()
  const [documentType, setDocumentype] = useState()

  function truncateString(str, num) {
    if (str.length > num) {
      return str.slice(0, 6) + "(...)" + str.slice(-8);
    } else {
      return str;
    }
  }

  useEffect(() => {
    setBoxChecked(document?.received)
    setDocumentype(document?.fileType)
  }, [])

  return (
    <div className={`${classes.compliantItem} d-flex justify-content-between align-items-center`}>
      <div className="d-flex justify-content-center align-items-center">
      <label class={classes.container}>
        <input type="checkbox" value={"received"} checked={boxChecked} name="statuses" onChange={(e) => {setBoxChecked(!boxChecked); documentCheck(document?._id, boxChecked, documentType, 'checked')}}/>
        <span class={classes.checkmark}></span>
      </label>
      <p>{truncateString(document?.fileName, 20)}</p>
      <div className={classes.iconContainer}>
        {/* <a
          style={{ textDecoration: "none", color: "black" }}
          href="http://www.africau.edu/images/default/sample.pdf"
          target={"_blank"}
        >
          <svg
            width="16"
            height="11"
            viewBox="0 0 16 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8 3.14286C6.67452 3.14286 5.6 4.19819 5.6 5.5C5.6 6.80181 6.67452 7.85714 8 7.85714C9.32548 7.85714 10.4 6.80181 10.4 5.5C10.4 4.19819 9.32548 3.14286 8 3.14286ZM7.2 5.5C7.2 5.06606 7.55817 4.71429 8 4.71429C8.44183 4.71429 8.8 5.06606 8.8 5.5C8.8 5.93394 8.44183 6.28571 8 6.28571C7.55817 6.28571 7.2 5.93394 7.2 5.5Z"
              fill="#D0D0D0"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M15.864 4.93486C14.0336 1.69075 11.0498 0 8 0C4.95022 0 1.96637 1.69075 0.136025 4.93486C-0.0429071 5.25201 -0.0451081 5.64307 0.128233 5.96168C1.92947 9.27245 4.93024 11 8 11C11.0698 11 14.0705 9.27245 15.8718 5.96168C16.0451 5.64306 16.0429 5.252 15.864 4.93486ZM8 9.42857C5.64877 9.42857 3.23241 8.14975 1.67339 5.45967C3.2549 2.82554 5.65855 1.57143 8 1.57143C10.3414 1.57143 12.7451 2.82554 14.3266 5.45967C12.7676 8.14975 10.3512 9.42857 8 9.42857Z"
              fill="#D0D0D0"
            />
          </svg>
        </a> */}

        <a
          style={{ textDecoration: "none", color: "black" }}
          href={`https://cdn.traders-central.s3.amazonaws.com/${document.key}`}
          download
          target={"_blank"}
        >
          <svg
            width="10"
            height="16"
            viewBox="0 0 10 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4.80003 0C5.24186 0 5.60003 0.358172 5.60003 0.8V10.3355L8.30027 8.17531C8.64528 7.8993 9.14872 7.95523 9.42472 8.30024C9.70073 8.64525 9.6448 9.14869 9.29979 9.4247L4.80003 13.0245L0.300273 9.4247C-0.0447361 9.14869 -0.100673 8.64525 0.175334 8.30024C0.451342 7.95523 0.954776 7.8993 1.29979 8.17531L4.00003 10.3355V0.8C4.00003 0.358172 4.3582 0 4.80003 0Z"
              fill="#D0D0D0"
            />
            <path
              d="M0 15.2004C0 14.7586 0.358172 14.4004 0.8 14.4004H8.8C9.24183 14.4004 9.6 14.7586 9.6 15.2004C9.6 15.6422 9.24183 16.0004 8.8 16.0004H0.8C0.358172 16.0004 0 15.6422 0 15.2004Z"
              fill="#D0D0D0"
            />
          </svg>
        </a>
      </div>
      </div>
      <div className={`${classes.selectWrapper} col`}>
            <select name="" id="" value={documentType} onChange={(e) =>{setDocumentype(e.target.value); documentCheck(document?._id , boxChecked, e.target.value, 'selected')}}>
              <option>Choose document type</option>
              <option value="board-resolution-to-open-account">Board Resolution to Open Account</option>
              <option value="certificate-of-incorporation">Certificate of Incorporation</option>
              <option value="corporate-address-verification">Corporate Address Verification</option>
              <option value="memorandum-&-articles-of-association">Memorandum & Articles of Association</option>
              <option value="director-identification">Director Identification</option>
              <option value="director-address-verification">Director Address Verification</option>
            </select>
            <svg
              width="13"
              height="8"
              viewBox="0 0 13 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.7168 2.10938L6.68885 6.03617C6.49112 6.22893 6.17457 6.2249 5.9818 6.02717L2.05501 1.99923"
                stroke="black"
                stroke-width="3"
                stroke-linecap="round"
              />
            </svg>
          </div>
    </div>
  );
};

export default DocumentItem;
