import React, { useEffect } from "react";
import DepositPage from "../../components/FinancialManagement/DepositPage";
import MainLayout from "../../components/Layout/MainLayout";

const Deposit = () => {
  useEffect(() => {
    document.title = "Deposit Request - Prime Bridge Admin";
  }, []);
  return (
    <MainLayout>
      <DepositPage />
    </MainLayout>
  );
};

export default Deposit;
