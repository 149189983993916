import { createSlice } from '@reduxjs/toolkit';

const usersSlice = createSlice({
  name: 'users',
  initialState: {
    user: "",
    users:[],
    companyUsers: []
  },
  reducers: {

    getUser (state, action) {
      state.user = action.payload.user
    },

    getUsers (state, action) {
      state.users = action.payload.users
    },
    getCompanyUsers (state, action) {
      state.companyUsers = action.payload.companyUsers
    },

  }
});

export const usersActions = usersSlice.actions;

export default usersSlice;
