export function getCurrentTime(date) {
  var today = new Date(date);
  var h = today.getHours() > 9 ? today.getHours() : `0${today.getHours()}`
  var min = today.getMinutes() > 9 ? today.getMinutes() : `0${today.getMinutes()}`
  var time = h + ":" + min;
  return time;
}

export function getTodaysDate(date) {
  var today = new Date(date);
  var d = today.getDate() > 9 ? today.getDate() : `0${today.getDate()}`
  var m = (today.getMonth() + 1) > 9 ? (today.getMonth() + 1) : `0${(today.getMonth() + 1)}`
  var date =  today.getFullYear() + "-" + m + "-" + d;
  return date;
}
