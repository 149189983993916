import React, { useEffect, useState } from "react";
import tableClasses from "../shared/styles/customTable.module.css";
import { env } from "../../constants/env";

const LPGRoupItem = ({ list = [] }) => {

  const [account, setAccount] = useState()

  // useEffect(() => {
  //   getAccount(list?._id)
  // },[list])


  console.log(list)


 
  return (
    <tr>
      <td class={`${tableClasses.row} ${tableClasses.first}`}>{list?.accountName}</td>
      <td class={tableClasses.row}>{list?.accountNumber}</td>
      <td class={tableClasses.row}>{list?.lpAccount}</td>
    </tr>
  );
};

export default LPGRoupItem;
