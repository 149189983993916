import { useEffect } from "react";
import MainLayout from "../../components/Layout/MainLayout"
import RiskManagementMain from "../../components/RiskManagement/RiskManagementMain";

const RiskManagement = () => {
    useEffect(() => {
        document.title = "Risk Management - Prime Bridge Admin";
      }, []);

    return(
        <MainLayout>
            <RiskManagementMain />
        </MainLayout>
    )
}

export default RiskManagement