import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { env } from "../../constants/env";
import classes from "../Dashboard/AdminDashboard.module.css";
import tableClasses from "../Dashboard/detailpage.module.css";
import TeamItem from "../Dashboard/TeamItem";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsers } from "../../Redux/users-actions";
import { getCookie } from "../../utilities/functions";

const MainPage = () => {

  const dispatch = useDispatch()

  // const [teamMembers, setTeamMembers] = useState([])
  const teamMembers = useSelector(state => state.users.users)

  const userId = getCookie('userId')

  useEffect(() => {
    dispatch(fetchUsers())
  }, [])

  return (
    <div className={classes.container}>
      <div className={classes.navContainer}>
        <p className={classes.headerSection}>Team</p>

        <div
          style={{ justifyContent: "flex-end", marginRight: 0 }}
          className={classes.actionsContainer}
        >
          <div>
            <Link style={{ textDecoration: "none" }} to={"/team/add"}>
              <button
                //   onClick={() => setShowAddModal(true)}
                className={classes.newCompanyBtn}
              >
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20 11L2 11"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                  <path
                    d="M11 2L11 20"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                </svg>
                Add Member
              </button>
            </Link>
          </div>
        </div>
      </div>
      <table className={tableClasses.teamsTable}>
        <thead>
          <th className={tableClasses.teamsHeader}>Team Member</th>
          <th className={tableClasses.teamsHeader}>Access</th>
          <th className={tableClasses.teamsHeader}>Email</th>
          <th className={tableClasses.teamsHeader}></th>
        </thead>
        <tbody className={tableClasses.body}>
          {teamMembers.map((member, i) => ((member._id !== userId) && <TeamItem userDetails={member} key={i}/>))}
        </tbody>
      </table>
    </div>
  );
};

export default MainPage;
