import React, { useEffect, useState } from "react";
import SharedModal from "../shared/Modal";
import classes from "./remove.module.css";
import modalClasses from "./edit-company.module.css";
import { env } from "../../constants/env";

const EditCompanyModal = ({ isOpen, setIsOpen, companyDetails, companyId }) => {
  const [companyName, setCompanyName] = useState("")
  const [refNumber, setRefNumber] = useState("")
  const [director, setDirector] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [email, setEmail] = useState("")
  const [address, setAddress] = useState("")
  const [city, setCity] = useState("")
  const [province, setProvince] = useState("")
  const [postalCode, setPostalCode] = useState("")
  const [country, setCountry] = useState("")
  const [isSending, setIsSending] = useState(false)

  useEffect(() => {
    setCompanyName(companyDetails?.companyName)
    setDirector(`${companyDetails?.firstName} ${companyDetails?.lastName}`)
    setPhoneNumber(companyDetails?.phone)
    setEmail(companyDetails?.email)
    setAddress(companyDetails?.address)
    setCity(companyDetails?.city)
    setProvince(companyDetails?.province)
    setPostalCode(companyDetails?.postalCode)
    setCountry(companyDetails?.country)
  },[companyDetails])

  const updateCompanyHandler = async () => {
    let dataContent = { companyName: companyName, email: email,
      refNumber: refNumber, phone: phoneNumber, address: address, city: city, province: province, postalCode: postalCode, country: country}
    const sendRequest = async () => {
      setIsSending(true)
      const response = await fetch(`${env.primeBridgeUrl}/api/company/update/${companyId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataContent)
      });

      if (!response.ok) {
        setIsSending(false)
        const error = await response.json();
        console.log("ERRORRR", error)
        throw new Error("sending plays failed.");
      }

      const result = await response.json();

      setIsSending(false)
      setIsOpen(false);

      console.log("Reply from plays data", result);

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      setIsSending(false)
      console.log(error);
    }
  };

  return (
    <SharedModal
      className={modalClasses.modal}
      show={isOpen}
      setShow={setIsOpen}
    >
      <div className={modalClasses.container}>
        <p className={modalClasses.header}>Company Information</p>
        <div className={modalClasses.group}>
          <div className={modalClasses.inputContainer}>
            <p className={modalClasses.label}> Company Name</p>
            <input className={modalClasses.input} type="text" value={companyName} onChange={(e) => setCompanyName(e.target.value)}/>
          </div>
          <div className={modalClasses.inputContainer}>
            <p className={modalClasses.label}>Reference Number</p>
            <input className={modalClasses.input} type="text" value={refNumber} onChange={(e) => setRefNumber(e.target.value)}/>
          </div>
        </div>
        <div className={modalClasses.group}>
          <div className={modalClasses.inputContainer}>
            <p className={modalClasses.label}>Phone number</p>
            <input className={modalClasses.input} type="text" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)}/>
          </div>
          <div className={modalClasses.inputContainer}>
            <p className={modalClasses.label}>Email</p>
            <input className={modalClasses.input} type="text" value={email} onChange={(e) => setEmail(e.target.value)}/>
          </div>
        </div>
        <div className={modalClasses.inputContainer}>
          <p className={modalClasses.label}>Address</p>
          <input type="text" className={modalClasses.input} value={address} onChange={(e) => setAddress(e.target.value)}/>
        </div>

        <div className={modalClasses.group}>
          <div className={modalClasses.inputContainer}>
            <p className={modalClasses.label}>City</p>
            <input className={modalClasses.input} type="text" value={city} onChange={(e) => setCity(e.target.value)}/>
          </div>
          <div className={modalClasses.inputContainer}>
            <p className={modalClasses.label}>Province</p>
            <input className={modalClasses.input} type="text" value={province} onChange={(e) => setProvince(e.target.value)}/>
          </div>
        </div>

        <div className={modalClasses.group}>
          <div className={modalClasses.inputContainer}>
            <p className={modalClasses.label}>Postal Code</p>
            <input className={modalClasses.input} type="text" value={postalCode} onChange={(e) => setPostalCode(e.target.value)}/>
          </div>
          <div className={modalClasses.inputContainer}>
            <p className={modalClasses.label}>Country</p>
            <input className={modalClasses.input} type="text" value={country} onChange={(e) => setCountry(e.target.value)}/>
          </div>
        </div>

        <div className={classes.btnContainer}>
        {!isSending && (<button className={modalClasses.confirm} onClick={updateCompanyHandler}>Save</button>)}
          {isSending && (
          <div class="spinner-border" role="status">
          </div>
        )}
        </div>
      </div>
    </SharedModal>
  );
};

export default EditCompanyModal;
