import { env } from "../constants/env";
import { companyActions } from "./company-slice";

export const fetchSingleCompany =  (companyId) => {
  return async (dispatch) => {
    const sendRequest = async () => {
      const response = await fetch(
        `${env.primeBridgeUrl}/api/company/${companyId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("fetching company failed.");
      }

      const result = await response.json();

      return result;
    };

    try {
      const data = await sendRequest();
      dispatch(companyActions.getCompany({ companyName: data?.companyName }));
      dispatch(companyActions.getDirector({ companyDirector: `${data?.firstName} ${data?.lastName}` }));
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchCompanies =  () => {
  return async (dispatch) => {
    const sendRequest = async () => {
      const response = await fetch(
        `${env.primeBridgeUrl}/api/company`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("fetching company failed.");
      }

      const result = await response.json();

      console.log("Results get companies redux", result);

      return result;
    };

    try {
      const data = await sendRequest();
      dispatch(companyActions.getCompanies({ companies: data }));
    } catch (error) {
      console.log(error);
    }
  };
};