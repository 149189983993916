import classes from "./AdminDashboard.module.css";
import tableClasses from "../shared/styles/customTable.module.css";
import { useEffect, useState } from "react";
import CompanyListItem from "./CompanyListItem";
import NewCompanyModal from "./NewCompanyModal";
import NewCompanyLogin from "./NewCompanyLogin";
import { env } from "../../constants/env";
import { useDispatch, useSelector } from "react-redux";
import { fetchCompanies } from "../../Redux/company-actions";

const AdminDashboard = () => {

  const dispatch = useDispatch()

  const [showAddModal, setShowAddModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [companiesList, setCompaniesList] = useState([])
  const [companyEmail, setCompanyEmail] = useState()
  const [companyPassword, setCompanyPassword] = useState()
  const [companyDirector, setCompanyDirector] = useState()
  const companies = useSelector(state => state.company.companies)

  useEffect(() => {
    // getCompany()
    dispatch(fetchCompanies())
  }, [])

  useEffect(() => {
    setCompaniesList(companies)
  },[companies])


  console.log("COMPAINES", companies)

  

  const searchCompanies = async (text) => {
    
    const sendRequest = async () => {
      const response = await fetch(`${env.primeBridgeUrl}/api/company/search/${text}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        const result = await response.json()
        console.log("ERROR", result)
        throw new Error("sending plays failed.");
      }

      const result = await response.json();

      setCompaniesList(result)

      console.log("search LISt", result);

      return result;
    };

    try {
      if(text.length <= 0){
        dispatch(fetchCompanies())
      }else{
        await sendRequest();
      }
     
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className={classes.container}>
      <div className={classes.navContainer}>
        <p className={classes.headerSection}>Companies</p>

        <div className={classes.actionsContainer}>
          <div className={classes.searchContainer}>
            <div className="searchIcon">
              <svg
                width="26"
                height="20"
                viewBox="0 0 26 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="10.5"
                  cy="10.5"
                  r="9"
                  stroke="#ABABAB"
                  stroke-width="3"
                />
                <rect
                  x="15"
                  y="16.9434"
                  width="3"
                  height="13.1583"
                  rx="1.5"
                  transform="rotate(-40.3606 15 16.9434)"
                  fill="#ABABAB"
                />
              </svg>
            </div>
            <input
              className={classes.searchInput}
              placeholder="Search"
              type="text"
              name=""
              id=""
              onChange={(e) => searchCompanies(e.target.value)}
            />
          </div>
          <div>
            <button
              onClick={() => setShowAddModal(true)}
              className={classes.newCompanyBtn}
            >
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 11L2 11"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                />
                <path
                  d="M11 2L11 20"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                />
              </svg>
              Add Company
            </button>

            <NewCompanyModal
              isOpen={showAddModal}
              setIsOpen={setShowAddModal}
              setDone={setShowLoginModal}
              loginCredentails={(email, password, director) => {setCompanyEmail(email); setCompanyPassword(password); setCompanyDirector(director)}}
            />
            <NewCompanyLogin
              isOpen={showLoginModal}
              setIsOpen={setShowLoginModal}
              email={companyEmail}
              password={companyPassword}
              director={companyDirector}
            />
          </div>
        </div>
      </div>
      <div>
        <table class={tableClasses.container}>
          <thead class="bg-gray-50">
            <tr>
              <th
                scope="col"
                class={`${tableClasses.header} ${tableClasses.first}`}
              >
                Company Name
              </th>
              <th scope="col" class={tableClasses.header}>
                Director
              </th>
              <th scope="col" class={tableClasses.header}>
                Email
              </th>
              <th scope="col" class={tableClasses.header}>
                Website
              </th>
              <th scope="col" class={tableClasses.header}>
               Ref. Number
              </th>
            </tr>
          </thead>
          <tbody class={tableClasses.body}>
            {companiesList?.map((item, index) => (
              <CompanyListItem companyName={item.companyName} director={item.director} email={item.email} refNumber={item.refNumber} directorId={item.user}  website={item.website} companyId={item._id} key={index}/>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default AdminDashboard;
