import React, { useEffect } from "react";
import classes from "../FinancialManagement/styles/deposit.module.css";
import tableClasses from "../shared/styles/customTable.module.css";
import StatusRequestItem from "../FinancialManagement/StatusRequestItem";
import { NavLink } from "react-router-dom";
import LeadItemStatus from "./LeadItemStatus";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllLeads } from "../../Redux/leads-actions";

const AllLeadsPage = () => {
  const dispatch = useDispatch()

  const allLeadsList = useSelector(state => state.leads.allLeads)

  useEffect(() => {
    dispatch(fetchAllLeads())
  }, [])

  return (
    <div className={classes.container}>
      <div className={classes.navContainer}>
        <NavLink style={{ textDecoration: "none" }} to={"/leads"}>
          <p className={classes.headerSection}>Leads</p>
        </NavLink>

        <div className={classes.actionsContainer}></div>
      </div>

      <div className={classes.tableContainer}>
        <div className={classes.headerSection}>
          <p className={classes.header}> All Demo Requests</p>
        </div>

        <table style={{ width: "100%" }} class={tableClasses.container}>
          <thead class="bg-gray-50">
            <tr>
              <th
                scope="col"
                class={`${tableClasses.header} ${tableClasses.first}`}
              >
                Status
              </th>
              <th scope="col" class={tableClasses.header}>
                Request Date
              </th>
              <th scope="col" class={tableClasses.header}>
                Company Name
              </th>
              <th scope="col" class={tableClasses.header}>
                Director
              </th>
              <th scope="col" class={tableClasses.header}>
                Website
              </th>
            </tr>
          </thead>
          <tbody class={tableClasses.body}>
            {allLeadsList.map((item, i) => <LeadItemStatus lead={item} key={i}/>)}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AllLeadsPage;
