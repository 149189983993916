import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import MainLayout from "../Layout/MainLayout";
import classes from "./edit-member.module.css";
import { env } from "../../constants/env";

const EditCompanyMember = () => {
  const { id } = useParams()
  const { companyId } = useParams()

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [accessLevel, setAccessLevel] = useState([])
  const [overviewOn, setOverviewOn] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)
  const navigate = useNavigate();
  

  const [isSending, setIsSending] = useState(false)

  useEffect(() => {
    getUser()
    getCompany()
  }, [])

  const  getUser = async () => {
    const sendRequest = async () => {
      const response = await fetch(`${env.primeBridgeUrl}/api/auth/user/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("sending plays failed.");
      }

      const result = await response.json();
      
      setFirstName(result?.firstName)
      setLastName(result?.lastName)
      setEmail(result?.email)
      setAccessLevel(result?.accessLevel)
      setIsAdmin(result?.isAdmin)

      console.log("USER EDIT RESULTS", result);

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };


  const updateUserHandler = async () => {
    
    let dataContent = {firstName: firstName, lastName: lastName, email: email,  accessLevel: accessLevel, overview: overviewOn}
    const sendRequest = async () => {
      setIsSending(true)
      const response = await fetch(`${env.primeBridgeUrl}/api/auth/update-user/${id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataContent)
      });

      if (!response.ok) {
        setIsSending(false)
        console.log("ERROR RESPONSE", response)
        throw new Error("sending plays failed.");
      }

      const result = await response.json();
      setIsSending(false)


      navigate(`/company/${companyId}`)

      console.log("Updated User", result);

      return result;
    };

    try {
        await sendRequest();
    } catch (error) {
      setIsSending(false)
      console.log(error);
    }
  };


  const  getCompany = async () => {
    const sendRequest = async () => {
      const response = await fetch(`${env.primeBridgeUrl}/api/company/${companyId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("sending plays failed.");
      }

      const result = await response.json();
      
      setOverviewOn(result.overview)

      console.log("Single Comapny data", result);

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <MainLayout>
      <div className={classes.container}>
        <div className={classes.header}>
          <NavLink style={{ textDecoration: "none" }} to={`/company/${companyId}`}>
            <p className={classes.name}>Company Name</p>
          </NavLink>
          <h3 className={classes.title}>Edit Member</h3>
        </div>
        <div className={classes.form}>
          <p className={classes.title}>Login Information</p>

          <div className={classes.group}>
            <div className={classes.inputContainer}>
              <input
                placeholder="First Name"
                className={classes.input}
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div className={classes.inputContainer}>
              <input
                placeholder="Last Name"
                className={classes.input}
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
          </div>

          <div className={classes.inputContainer}>
            <input placeholder="Email" className={classes.input} type="text" value={email} onChange={(e) => setEmail(e.target.value)}/>
          </div>

          

          {isAdmin ? <div className={classes.access}>
            <p className={classes.title}>User Access</p>
            <label class={`${classes.check_cont}`}>
                <input type="checkbox" checked={overviewOn}
              onChange={(e) => setOverviewOn(!overviewOn)}/>
                <span className={classes.checkmark}></span>
                <p>Overview section</p>
              </label></div> : <div className={classes.access}>
            <p className={classes.title}>User Access</p>
            <div>
              <label class={classes.check_cont}>
                <input type="checkbox" checked={accessLevel.includes("asset-dashboard")}
              onChange={(e) => {
                if(!accessLevel.includes("asset-dashboard")){
                  setAccessLevel([...accessLevel, "asset-dashboard"])
                }else{
                  setAccessLevel((current) => current.filter((access) => access !== "asset-dashboard"))
                }
              }}/>
                <span className={classes.checkmark}></span>
                <p>Asset Dashboard</p>
              </label>
              <label class={classes.check_cont}>
                <input type="checkbox" checked={accessLevel.includes("transactions")}
              onChange={(e) => {
                if(!accessLevel.includes("transactions")){
                  setAccessLevel([...accessLevel, "transactions"])
                }else{
                  setAccessLevel((current) => current.filter((access) => access !== "transactions"))
                }
              }}/>
                <span className={classes.checkmark}></span>
                <p>Transactions</p>
              </label>
              <label class={classes.check_cont}>
                <input type="checkbox" checked={accessLevel.includes("team")}
              onChange={(e) => {
                if(!accessLevel.includes("team")){
                  setAccessLevel([...accessLevel, "team"])
                }else{
                  setAccessLevel((current) => current.filter((access) => access !== "team"))
                }
              }}/>
                <span className={classes.checkmark}></span>
                <p>Team</p>
              </label>
              <label class={classes.check_cont}>
                <input type="checkbox"  checked={accessLevel.includes("help-center")}
              onChange={(e) => {
                if(!accessLevel.includes("help-center")){
                  setAccessLevel([...accessLevel, "help-center"])
                }else{
                  setAccessLevel((current) => current.filter((access) => access !== "help-center"))
                }
              }}/>
                <span className={classes.checkmark}></span>
                <p>Help Center</p>
              </label>
             {overviewOn && <label class={classes.check_cont}>
                <input type="checkbox"  checked={accessLevel.includes("overview")}
              onChange={(e) => {
                if(!accessLevel.includes("overview")){
                  setAccessLevel([...accessLevel, "overview"])
                }else{
                  setAccessLevel((current) => current.filter((access) => access !== "overview"))
                }
              }}/>
                <span className={classes.checkmark}></span>
                <p>Overview section</p>
              </label>}
            </div>
          </div>}

          <div className={classes.btnContainer}>
          {!isSending && (<>
            <Link style={{ textDecoration: "none", display: "block" }} to={`/company/${companyId}`}>
              <button className={classes.cancel}>Cancel</button>
            </Link>

            <Link
              style={{ textDecoration: "none", display: "block" }}
            >
              <button className={classes.confirm} onClick={updateUserHandler}>Save</button>
            </Link>
            </>)}
            {isSending && (
              <div className="spinner-border" role="status">
              </div>
            )}
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default EditCompanyMember;
