import { useState } from "react";

import styles from "./StatsList.module.css";
import InfoIcon from "../../../icons/InfoIcon";

const StatsList = ({title, num, firstRow, style, underline, descText}) => {
  const [showFeePopup, setShowFeePopup] = useState(false)
  return (
    <div
      className={`d-flex justify-content-between ${styles.innerStats}  ${firstRow && "mt-0"}`}
    >
      {showFeePopup && <div className={`${styles.infoPopup}`}>{descText}</div>}
                
      <div className={styles.statsText} ><span style={{borderBottom: underline && `1px dashed #000000`, marginRight: '10px'}}>{title}</span> {underline && <InfoIcon size="16" onMouseOver={() => setShowFeePopup(true)} onMouseOut={() => setShowFeePopup(false)}/>}</div>
      
      <div className={styles.numText}>{num}</div>
    </div>
  );
};

export default StatsList;
