import { env } from "../constants/env";
import { leadsActions } from "./leads-slice";


export const fetchPendingLeads =  () => {
  return async (dispatch) => {
    const sendRequest = async () => {
      const response = await fetch(`${env.primeBridgeUrl}/api/lead/pending`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Getting accounts failed.");
      }

      const result = await response.json();

      console.log("Results get accounts redux", result);

      return result;
    };

    try {
      const data = await sendRequest();
      dispatch(leadsActions.getLeads({ pendingLeads: data }));
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchAllLeads =  () => {
  return async (dispatch) => {
    const sendRequest = async () => {
      const response = await fetch(`${env.primeBridgeUrl}/api/lead`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Getting accounts failed.");
      }

      const result = await response.json();

      
      result.forEach(function (element) {
        if(element.status === "pending"){
          element.order = 1;
        }else if(element.status === "contacted"){
          element.order = 2;
        }else if(element.status === "discarded"){
          element.order = 3;
        }
       
      });

      result.sort(function (a, b) {
        return a.order - b.order;
      });

      return result;
    };

    try {
      const data = await sendRequest();
      dispatch(leadsActions.getAllLeads({ allLeads: data }));
    } catch (error) {
      console.log(error);
    }
  };
};
