import React, { useEffect, useState } from "react";
import classes from "../FinancialManagement/styles/index.module.css";
import tableClasses from "../shared/styles/customTable.module.css";
import { NavLink } from "react-router-dom";
import CompliantItem from "./CompliantItem";
import { env } from "../../constants/env";

const MainPage = () => {
  const [companies, setCompanies] = useState([]);
  useEffect(() => {
    getCompnies()
  }, [])

  const  getCompnies = async () => {
    const sendRequest = async () => {
      const response = await fetch(`${env.primeBridgeUrl}/api/company/pending`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        const result = await response.json();
        console.log("ERRROR", result)
        throw new Error("sending plays failed.");
      }

      const result = await response.json();
      setCompanies(result)

      console.log("Reply from plays data", result);

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className={classes.container}>
      <div className={classes.navContainer}>
        <p className={classes.headerSection}>Compliance</p>

        <div className={classes.actionsContainer}></div>
      </div>

      <div className={classes.tableContainer}>
        <div className={classes.headerSection}>
          <p className={classes.header}>Document Verification</p>
        </div>

        <table style={{ width: "100%" }} class={tableClasses.container}>
          <thead class="bg-gray-50">
            <tr>
              <th
                scope="col"
                class={`${tableClasses.header} ${tableClasses.first}`}
              >
                Send Date
              </th>
              <th scope="col" class={tableClasses.header}>
                Company Name
              </th>
              <th scope="col" class={tableClasses.header}>
                Director
              </th>
              <th scope="col" class={tableClasses.header}>
                Email
              </th>
              <th scope="col" class={tableClasses.header}>
                Verified
              </th>
            </tr>
          </thead>
          <tbody class={tableClasses.body}>
            {companies.sort(function (a, b) {
              return a.count - b.count;
            }).map((item, index) => (
                    <CompliantItem company={item} key={index} updateCount={getCompnies}/>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MainPage;
