export function setCookie (cname, cvalue, exdays) {
  const d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  let expires = 'expires=' + d.toUTCString()
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
}

export function getCookie (cname) {
  let name = cname + '='
  let ca = document.cookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

export function checkCookie () {
  let user = getCookie('username')
  if (user !== '') {
    alert('Welcome again ' + user)
  } else {
    user = prompt('Please enter your name:', '')
    if (user !== '' && user !== null) {
      setCookie('username', user, 365)
    }
  }
}

export function deleteCookie (name, path, domain = 'traderscentral.com') {
  if (getCookie(name)) {
    document.cookie =
      name +
      '= ;' +
      (path ? ';path=' + path + '' : '') +
      (domain ? ';domain=' + domain + '' : '') +
      ';expires=Thu, 01 Jan 1970 00:00:01 GMT'
  }
}

export const nFormatter = (num, digits) => {
  var si = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
};

export const checkNum = (num) => {
  if(num > 0){
      return `$${numberWithCommas(Math.round(num * 100) / 100)} USD`
    }else{
      return `-$${numberWithCommas(Math.abs(Math.round(num * 100) / 100))} USD`
    }
}

export const numberWithCommas = (x) => {
  return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const calcDuration = (date1, date2) => {
  const first = new Date(date1)
  const last = new Date(date2)
  const diffTime = last - first;
  var seconds = Math.floor((diffTime / 1000) % 60),
  minutes = Math.floor((diffTime / (1000 * 60)) % 60),
  hours = Math.floor((diffTime / (1000 * 60 * 60)) % 24),
  days = Math.floor((diffTime / (1000 * 60 * 60 * 24)) % 30),
  months = Math.floor((diffTime / (1000 * 60 * 60 * 24 * 30))),
  months = (months <= 0) ? "" : ` ${months} m`;
  days = (days <= 0) ? "" : ` ${days} d`;
  hours = (days <= 0) ? ((hours <= 0) ? "" : ` ${hours} h`) : "";
  minutes = (days || hours) ?  "" : ((minutes <= 0) ? "" : ` ${minutes} min `);
  seconds = (hours || minutes) ? "" :  ((seconds <= 0) ? "" : ` ${seconds} sec`);

  return months + days + hours + minutes + seconds;
}

  // Function to replace objects in array1 with the ones in array2 based on a matching key
export const replaceObjects = (array1, array2) => {
    return array1.map(obj1 => {
        const matchingObj = array2.find(obj2 => obj2.credentials.accountNumber === obj1.credentials.accountNumber);
        
        if (matchingObj) {
          return { ...matchingObj, checked: true };
        }
        return obj1;
    });
  }
