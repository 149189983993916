import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import SharedModal from "../shared/Modal";
import classes from "./styles/withdrawal-modal.module.css";
import { env } from "../../constants/env";
import { useDispatch } from "react-redux";
import {
  fetchAllWithdrawalRequests,
  fetchPendingWithdrawalRequests,
} from "../../Redux/requests-actions";
import { numberWithCommas } from "../../utilities/functions";

const WithdrawalRequestModal = ({
  isOpen,
  setIsOpen,
  isCrypto,
  isNewRequest,
  status,
  request,
  companyName,
  accountName,
  userName,
}) => {
  const dispatch = useDispatch();
  const [selectedStatus, setSelectedStatus] = useState("");
  const [reason, setReason] = useState("Not enough funds");
  const [reasonText, setReasonText] = useState("");
  const [withdrawalFeeIsOpen, setWithdrawalFeeIsOpen] = useState(false);
  const [withdrawalAmountIsOpen, setWithdrawalAmountIsOpen] = useState(false);
  const [totalWithdrawalIsOpen, setTotalWithdrawalIsOpen] = useState(false);
  const [pbShareIsOpen, setPBShareIsOpen] = useState(false);

  const [withdrawalFee, setWithdrawalFee] = useState("");
  const [withdrawalAmount, setWithdrawalAmount] = useState("");
  const [totalWithdrawal, setTotalWithdrawal] = useState("");
  const [pbShare, setPBShare] = useState("");

  useEffect(() => {
    setWithdrawalFee(request?.withdrawalFee)
    setWithdrawalAmount(request?.netPayout)
    setTotalWithdrawal(request?.totalWithdrawal)
    setPBShare(request?.pbShare)
  },[])

  const [isSending, setIsSending] = useState(false);

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    toast.success("Copied to clipboard");
  };

  const dateToYMD = (date) => {
    const formatedDate = date.split("T")[0];
    console.log("DATE", formatedDate);
    var newDate = new Date(date);
    var fullDate = new Date(date);
    var strArray = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    var d = newDate.getDate();
    var dayName = days[newDate.getDay()];
    var m = strArray[newDate.getMonth()];
    var y = newDate.getFullYear();
    var hr = newDate.getHours();
    var min = newDate.getMinutes();

    if (min < 10) {
      min = "0" + min;
    }
    var ampm = "AM";
    if (hr > 12) {
      hr -= 12;
      ampm = "PM";
    }

    return {
      full:
        "" +
        m +
        " " +
        (d <= 9 ? "0" + d : d) +
        ", " +
        (hr <= 9 ? "0" + hr : hr) +
        ":" +
        min +
        " " +
        ampm,
      date: d,
      month: m,
    };
  };

  const requestStatusUpdate = async () => {
    let dataContent = {
      status: selectedStatus,
      reason: reason === "Other" ? reasonText : reason,
    };
    const sendRequest = async () => {
      setIsSending(true);
      const response = await fetch(
        `${env.primeBridgeUrl}/api/transaction/update-status/${request._id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataContent),
        }
      );

      if (!response.ok) {
        setIsSending(false);
        const error = await response.json();

        console.log("ERROR", error);

        throw new Error("sending plays failed.");
      }

      const result = await response.json();

      console.log("Reply from REQUEST UPDATE data", result);
      if (isNewRequest) {
        dispatch(fetchPendingWithdrawalRequests());
      } else {
        dispatch(fetchAllWithdrawalRequests());
      }

      setIsSending(false);

      setIsOpen(false);

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      setIsSending(false);
      console.log(error);
    }
  };

  const requestFeeUpdate = async () => {
    let dataContent = { fee: withdrawalFee };
    const sendRequest = async () => {
      const response = await fetch(
        `${env.primeBridgeUrl}/api/transaction/update-fee/${request._id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataContent),
        }
      );

      if (!response.ok) {
        const error = await response.json();

        console.log("ERROR", error);

        throw new Error("sending plays failed.");
      }

      const result = await response.json();

      console.log("Reply from REQUEST UPDATE data", result);
      if (isNewRequest) {
        dispatch(fetchPendingWithdrawalRequests());
      } else {
        dispatch(fetchAllWithdrawalRequests());
      }
      setWithdrawalFeeIsOpen(false);

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };

  const requestAmountUpdate = async () => {
    let dataContent = { amount: withdrawalAmount };
    const sendRequest = async () => {
      const response = await fetch(
        `${env.primeBridgeUrl}/api/transaction/update-amount/${request._id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataContent),
        }
      );

      if (!response.ok) {
        const error = await response.json();

        console.log("ERROR", error);

        throw new Error("sending plays failed.");
      }

      const result = await response.json();

      console.log("Reply from REQUEST UPDATE data", result);
      if (isNewRequest) {
        dispatch(fetchPendingWithdrawalRequests());
      } else {
        dispatch(fetchAllWithdrawalRequests());
      }
      setWithdrawalAmountIsOpen(false);

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };


  const requestPBShareUpdate = async () => {
    let dataContent = {pbShare}
    const sendRequest = async () => {

      const response = await fetch(`${env.primeBridgeUrl}/api/transaction/update-pbshare/${request._id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataContent)
      });

      if (!response.ok) {
        const error = await response.json();

        console.log("ERROR", error)

        throw new Error("sending plays failed.");
      }

      const result = await response.json();

      console.log("Reply from REQUEST UPDATE data", result);
      if(isNewRequest){
        dispatch(fetchPendingWithdrawalRequests())
      }else{
        dispatch(fetchAllWithdrawalRequests())
      }
      setPBShareIsOpen(false)

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };

  const requestTotalUpdate = async () => {
    let dataContent = { totalAmount: totalWithdrawal };
    const sendRequest = async () => {
      const response = await fetch(
        `${env.primeBridgeUrl}/api/transaction/update-total/${request._id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataContent),
        }
      );

      if (!response.ok) {
        const error = await response.json();

        console.log("ERROR", error);

        throw new Error("sending plays failed.");
      }

      const result = await response.json();

      console.log("Reply from REQUEST UPDATE data", result);
      if (isNewRequest) {
        dispatch(fetchPendingWithdrawalRequests());
      } else {
        dispatch(fetchAllWithdrawalRequests());
      }
      setTotalWithdrawalIsOpen(false);

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <SharedModal className={classes.modal} show={isOpen} setShow={setIsOpen}>
      <div className={classes.body}>
        <div className={classes.headerSection}>
          <p className={classes.header}>
            {isNewRequest ? "New Withdrawal Request" : "Withdrawal Request"}
          </p>
        </div>

        <div>
          <div className={classes.group}>
            <div className={classes.groupItem}>
              <p className={classes.label}>Request details</p>

              <p className={classes.value}>
                {dateToYMD(request?.createdAt).full}
              </p>
            </div>

            <div className={classes.groupItem}>
              <p className={classes.label}>Company</p>

              <p className={classes.value}>{companyName}</p>
            </div>
          </div>
          <div className={classes.group}>
            <div className={classes.groupItem}>
              <p className={classes.label}>Team Member</p>

              <p className={classes.value}>{userName}</p>
            </div>

            <div className={classes.groupItem}>
              <p className={classes.label}>Trading Account</p>

              <p className={classes.value}>{accountName}</p>
            </div>
          </div>
          <div className={classes.group}>
            <div className={classes.groupItem}>
              <p className={classes.label}>Method</p>

              <p className={classes.value}>
                {request?.paymentMethod === "crypto"
                  ? `Crypto (${request?.currency?.toUpperCase()})`
                  : request?.paymentMethod === "cash-account"
                  ? "Cash Account"
                  : "Wire Transfer"}
              </p>
            </div>

            {request?.paymentMethod === "crypto" ? (
              <div className={classes.groupItem}>
                <p className={classes.label}>Wallet address</p>

                <p className={classes.value}>
                  {request?.address?.length <= 15
                    ? request?.address
                    : `${request?.address?.substring(0, 15)}...`}
                  <svg
                    width="15"
                    height="17"
                    viewBox="0 0 15 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => handleCopy(request?.address)}
                  >
                    <path
                      d="M8.71362 2.85742H2.00652C1.47434 2.85832 0.964226 3.08215 0.588068 3.47982C0.21191 3.87749 0.00042371 4.41653 0 4.9787V14.3069C0.00042371 14.869 0.21191 15.4081 0.588068 15.8058C0.964226 16.2034 1.47434 16.4273 2.00652 16.4282H8.71362C9.24469 16.4255 9.75316 16.2008 10.1279 15.8034C10.5027 15.4059 10.7133 14.8679 10.7137 14.3069V4.9787C10.7133 4.4177 10.5027 3.87968 10.1279 3.4822C9.75316 3.08473 9.24469 2.86011 8.71362 2.85742ZM9.22968 14.3069C9.22968 14.4515 9.17531 14.5901 9.07853 14.6923C8.98175 14.7946 8.85049 14.852 8.71362 14.852H2.00652C1.86993 14.8516 1.73908 14.7939 1.64265 14.6918C1.54622 14.5896 1.49207 14.4512 1.49207 14.3069V4.9787C1.49207 4.83457 1.54627 4.69634 1.64275 4.59443C1.73923 4.49251 1.87008 4.43526 2.00652 4.43526H8.71362C8.85021 4.43526 8.98123 4.49246 9.07796 4.59432C9.1747 4.69619 9.22925 4.83441 9.22968 4.9787V14.3069Z"
                      fill="#0077B5"
                    />
                    <path
                      d="M12.9954 0H6.81038C6.33036 0.000449177 5.87013 0.202726 5.53071 0.562426C5.1913 0.922127 5.00042 1.40986 5 1.91855C5.00888 2.12164 5.09127 2.3133 5.23003 2.4537C5.3688 2.59409 5.55327 2.67243 5.74512 2.67243C5.93697 2.67243 6.12144 2.59409 6.26021 2.4537C6.39897 2.3133 6.48135 2.12164 6.49024 1.91855C6.49024 1.82857 6.52397 1.74228 6.58401 1.67865C6.64404 1.61503 6.72547 1.57928 6.81038 1.57928H12.9954C13.1318 1.57973 13.2625 1.63748 13.3589 1.73987C13.4552 1.84225 13.5092 1.98093 13.5092 2.1255V10.9362C13.5092 11.0262 13.4755 11.1125 13.4155 11.1761C13.3554 11.2398 13.274 11.2755 13.1891 11.2755H12.8081C12.6103 11.2755 12.4206 11.3588 12.2807 11.507C12.1408 11.6553 12.0622 11.8563 12.0622 12.066C12.0622 12.2756 12.1408 12.4767 12.2807 12.625C12.4206 12.7732 12.6103 12.8565 12.8081 12.8565H13.1907C13.6704 12.8556 14.1303 12.6531 14.4693 12.2935C14.8084 11.9338 14.9991 11.4463 14.9995 10.9379V2.1255C14.9991 1.56221 14.7878 1.0221 14.4121 0.62364C14.0364 0.225177 13.527 0.000898206 12.9954 0V0Z"
                      fill="#0077B5"
                    />
                  </svg>
                </p>
              </div>
            ) : request?.paymentMethod === "wire-transfer" ? (
              <div className={classes.groupItem}>
                <p className={classes.label}>Bank Name</p>

                <p className={classes.value}>
                  {request?.bankName}
                  <svg
                    width="15"
                    height="17"
                    viewBox="0 0 15 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => handleCopy(request?.bankName)}
                  >
                    <path
                      d="M8.71362 2.85742H2.00652C1.47434 2.85832 0.964226 3.08215 0.588068 3.47982C0.21191 3.87749 0.00042371 4.41653 0 4.9787V14.3069C0.00042371 14.869 0.21191 15.4081 0.588068 15.8058C0.964226 16.2034 1.47434 16.4273 2.00652 16.4282H8.71362C9.24469 16.4255 9.75316 16.2008 10.1279 15.8034C10.5027 15.4059 10.7133 14.8679 10.7137 14.3069V4.9787C10.7133 4.4177 10.5027 3.87968 10.1279 3.4822C9.75316 3.08473 9.24469 2.86011 8.71362 2.85742ZM9.22968 14.3069C9.22968 14.4515 9.17531 14.5901 9.07853 14.6923C8.98175 14.7946 8.85049 14.852 8.71362 14.852H2.00652C1.86993 14.8516 1.73908 14.7939 1.64265 14.6918C1.54622 14.5896 1.49207 14.4512 1.49207 14.3069V4.9787C1.49207 4.83457 1.54627 4.69634 1.64275 4.59443C1.73923 4.49251 1.87008 4.43526 2.00652 4.43526H8.71362C8.85021 4.43526 8.98123 4.49246 9.07796 4.59432C9.1747 4.69619 9.22925 4.83441 9.22968 4.9787V14.3069Z"
                      fill="#0077B5"
                    />
                    <path
                      d="M12.9954 0H6.81038C6.33036 0.000449177 5.87013 0.202726 5.53071 0.562426C5.1913 0.922127 5.00042 1.40986 5 1.91855C5.00888 2.12164 5.09127 2.3133 5.23003 2.4537C5.3688 2.59409 5.55327 2.67243 5.74512 2.67243C5.93697 2.67243 6.12144 2.59409 6.26021 2.4537C6.39897 2.3133 6.48135 2.12164 6.49024 1.91855C6.49024 1.82857 6.52397 1.74228 6.58401 1.67865C6.64404 1.61503 6.72547 1.57928 6.81038 1.57928H12.9954C13.1318 1.57973 13.2625 1.63748 13.3589 1.73987C13.4552 1.84225 13.5092 1.98093 13.5092 2.1255V10.9362C13.5092 11.0262 13.4755 11.1125 13.4155 11.1761C13.3554 11.2398 13.274 11.2755 13.1891 11.2755H12.8081C12.6103 11.2755 12.4206 11.3588 12.2807 11.507C12.1408 11.6553 12.0622 11.8563 12.0622 12.066C12.0622 12.2756 12.1408 12.4767 12.2807 12.625C12.4206 12.7732 12.6103 12.8565 12.8081 12.8565H13.1907C13.6704 12.8556 14.1303 12.6531 14.4693 12.2935C14.8084 11.9338 14.9991 11.4463 14.9995 10.9379V2.1255C14.9991 1.56221 14.7878 1.0221 14.4121 0.62364C14.0364 0.225177 13.527 0.000898206 12.9954 0V0Z"
                      fill="#0077B5"
                    />
                  </svg>
                </p>
              </div>
            ) : null}
          </div>

          {request?.paymentMethod !== "crypto" &&
            request?.paymentMethod !== "cash-account" && (
              <>
                <div className={classes.group}>
                  <div className={classes.groupItem}>
                    <p className={classes.label}>Account Name</p>

                    <p className={classes.value}>
                      {request?.accountName}
                      <svg
                        width="15"
                        height="17"
                        viewBox="0 0 15 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={() => handleCopy(request?.accountName)}
                      >
                        <path
                          d="M8.71362 2.85742H2.00652C1.47434 2.85832 0.964226 3.08215 0.588068 3.47982C0.21191 3.87749 0.00042371 4.41653 0 4.9787V14.3069C0.00042371 14.869 0.21191 15.4081 0.588068 15.8058C0.964226 16.2034 1.47434 16.4273 2.00652 16.4282H8.71362C9.24469 16.4255 9.75316 16.2008 10.1279 15.8034C10.5027 15.4059 10.7133 14.8679 10.7137 14.3069V4.9787C10.7133 4.4177 10.5027 3.87968 10.1279 3.4822C9.75316 3.08473 9.24469 2.86011 8.71362 2.85742ZM9.22968 14.3069C9.22968 14.4515 9.17531 14.5901 9.07853 14.6923C8.98175 14.7946 8.85049 14.852 8.71362 14.852H2.00652C1.86993 14.8516 1.73908 14.7939 1.64265 14.6918C1.54622 14.5896 1.49207 14.4512 1.49207 14.3069V4.9787C1.49207 4.83457 1.54627 4.69634 1.64275 4.59443C1.73923 4.49251 1.87008 4.43526 2.00652 4.43526H8.71362C8.85021 4.43526 8.98123 4.49246 9.07796 4.59432C9.1747 4.69619 9.22925 4.83441 9.22968 4.9787V14.3069Z"
                          fill="#0077B5"
                        />
                        <path
                          d="M12.9954 0H6.81038C6.33036 0.000449177 5.87013 0.202726 5.53071 0.562426C5.1913 0.922127 5.00042 1.40986 5 1.91855C5.00888 2.12164 5.09127 2.3133 5.23003 2.4537C5.3688 2.59409 5.55327 2.67243 5.74512 2.67243C5.93697 2.67243 6.12144 2.59409 6.26021 2.4537C6.39897 2.3133 6.48135 2.12164 6.49024 1.91855C6.49024 1.82857 6.52397 1.74228 6.58401 1.67865C6.64404 1.61503 6.72547 1.57928 6.81038 1.57928H12.9954C13.1318 1.57973 13.2625 1.63748 13.3589 1.73987C13.4552 1.84225 13.5092 1.98093 13.5092 2.1255V10.9362C13.5092 11.0262 13.4755 11.1125 13.4155 11.1761C13.3554 11.2398 13.274 11.2755 13.1891 11.2755H12.8081C12.6103 11.2755 12.4206 11.3588 12.2807 11.507C12.1408 11.6553 12.0622 11.8563 12.0622 12.066C12.0622 12.2756 12.1408 12.4767 12.2807 12.625C12.4206 12.7732 12.6103 12.8565 12.8081 12.8565H13.1907C13.6704 12.8556 14.1303 12.6531 14.4693 12.2935C14.8084 11.9338 14.9991 11.4463 14.9995 10.9379V2.1255C14.9991 1.56221 14.7878 1.0221 14.4121 0.62364C14.0364 0.225177 13.527 0.000898206 12.9954 0V0Z"
                          fill="#0077B5"
                        />
                      </svg>
                    </p>
                  </div>

                  <div className={classes.groupItem}>
                    <p className={classes.label}>IBAN</p>

                    <p className={classes.value}>
                      {request?.accountNumber}
                      <svg
                        width="15"
                        height="17"
                        viewBox="0 0 15 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={() => handleCopy(request?.bankNumber)}
                      >
                        <path
                          d="M8.71362 2.85742H2.00652C1.47434 2.85832 0.964226 3.08215 0.588068 3.47982C0.21191 3.87749 0.00042371 4.41653 0 4.9787V14.3069C0.00042371 14.869 0.21191 15.4081 0.588068 15.8058C0.964226 16.2034 1.47434 16.4273 2.00652 16.4282H8.71362C9.24469 16.4255 9.75316 16.2008 10.1279 15.8034C10.5027 15.4059 10.7133 14.8679 10.7137 14.3069V4.9787C10.7133 4.4177 10.5027 3.87968 10.1279 3.4822C9.75316 3.08473 9.24469 2.86011 8.71362 2.85742ZM9.22968 14.3069C9.22968 14.4515 9.17531 14.5901 9.07853 14.6923C8.98175 14.7946 8.85049 14.852 8.71362 14.852H2.00652C1.86993 14.8516 1.73908 14.7939 1.64265 14.6918C1.54622 14.5896 1.49207 14.4512 1.49207 14.3069V4.9787C1.49207 4.83457 1.54627 4.69634 1.64275 4.59443C1.73923 4.49251 1.87008 4.43526 2.00652 4.43526H8.71362C8.85021 4.43526 8.98123 4.49246 9.07796 4.59432C9.1747 4.69619 9.22925 4.83441 9.22968 4.9787V14.3069Z"
                          fill="#0077B5"
                        />
                        <path
                          d="M12.9954 0H6.81038C6.33036 0.000449177 5.87013 0.202726 5.53071 0.562426C5.1913 0.922127 5.00042 1.40986 5 1.91855C5.00888 2.12164 5.09127 2.3133 5.23003 2.4537C5.3688 2.59409 5.55327 2.67243 5.74512 2.67243C5.93697 2.67243 6.12144 2.59409 6.26021 2.4537C6.39897 2.3133 6.48135 2.12164 6.49024 1.91855C6.49024 1.82857 6.52397 1.74228 6.58401 1.67865C6.64404 1.61503 6.72547 1.57928 6.81038 1.57928H12.9954C13.1318 1.57973 13.2625 1.63748 13.3589 1.73987C13.4552 1.84225 13.5092 1.98093 13.5092 2.1255V10.9362C13.5092 11.0262 13.4755 11.1125 13.4155 11.1761C13.3554 11.2398 13.274 11.2755 13.1891 11.2755H12.8081C12.6103 11.2755 12.4206 11.3588 12.2807 11.507C12.1408 11.6553 12.0622 11.8563 12.0622 12.066C12.0622 12.2756 12.1408 12.4767 12.2807 12.625C12.4206 12.7732 12.6103 12.8565 12.8081 12.8565H13.1907C13.6704 12.8556 14.1303 12.6531 14.4693 12.2935C14.8084 11.9338 14.9991 11.4463 14.9995 10.9379V2.1255C14.9991 1.56221 14.7878 1.0221 14.4121 0.62364C14.0364 0.225177 13.527 0.000898206 12.9954 0V0Z"
                          fill="#0077B5"
                        />
                      </svg>
                    </p>
                  </div>
                </div>

                {/* <div className={classes.group}> */}

                <div className={`${classes.groupItem} mb-3`}>
                  <p className={classes.label}>Address</p>

                  <p className={classes.value}>{request?.address} </p>
                </div>
                {/* </div> */}

                <div className={classes.group}>
                  <div className={classes.groupItem}>
                    <p className={classes.label}>Branch/Sort Code</p>

                    <p className={classes.value}>
                      {request?.branch}
                      <svg
                        width="15"
                        height="17"
                        viewBox="0 0 15 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={() => handleCopy(request?.branch)}
                      >
                        <path
                          d="M8.71362 2.85742H2.00652C1.47434 2.85832 0.964226 3.08215 0.588068 3.47982C0.21191 3.87749 0.00042371 4.41653 0 4.9787V14.3069C0.00042371 14.869 0.21191 15.4081 0.588068 15.8058C0.964226 16.2034 1.47434 16.4273 2.00652 16.4282H8.71362C9.24469 16.4255 9.75316 16.2008 10.1279 15.8034C10.5027 15.4059 10.7133 14.8679 10.7137 14.3069V4.9787C10.7133 4.4177 10.5027 3.87968 10.1279 3.4822C9.75316 3.08473 9.24469 2.86011 8.71362 2.85742ZM9.22968 14.3069C9.22968 14.4515 9.17531 14.5901 9.07853 14.6923C8.98175 14.7946 8.85049 14.852 8.71362 14.852H2.00652C1.86993 14.8516 1.73908 14.7939 1.64265 14.6918C1.54622 14.5896 1.49207 14.4512 1.49207 14.3069V4.9787C1.49207 4.83457 1.54627 4.69634 1.64275 4.59443C1.73923 4.49251 1.87008 4.43526 2.00652 4.43526H8.71362C8.85021 4.43526 8.98123 4.49246 9.07796 4.59432C9.1747 4.69619 9.22925 4.83441 9.22968 4.9787V14.3069Z"
                          fill="#0077B5"
                        />
                        <path
                          d="M12.9954 0H6.81038C6.33036 0.000449177 5.87013 0.202726 5.53071 0.562426C5.1913 0.922127 5.00042 1.40986 5 1.91855C5.00888 2.12164 5.09127 2.3133 5.23003 2.4537C5.3688 2.59409 5.55327 2.67243 5.74512 2.67243C5.93697 2.67243 6.12144 2.59409 6.26021 2.4537C6.39897 2.3133 6.48135 2.12164 6.49024 1.91855C6.49024 1.82857 6.52397 1.74228 6.58401 1.67865C6.64404 1.61503 6.72547 1.57928 6.81038 1.57928H12.9954C13.1318 1.57973 13.2625 1.63748 13.3589 1.73987C13.4552 1.84225 13.5092 1.98093 13.5092 2.1255V10.9362C13.5092 11.0262 13.4755 11.1125 13.4155 11.1761C13.3554 11.2398 13.274 11.2755 13.1891 11.2755H12.8081C12.6103 11.2755 12.4206 11.3588 12.2807 11.507C12.1408 11.6553 12.0622 11.8563 12.0622 12.066C12.0622 12.2756 12.1408 12.4767 12.2807 12.625C12.4206 12.7732 12.6103 12.8565 12.8081 12.8565H13.1907C13.6704 12.8556 14.1303 12.6531 14.4693 12.2935C14.8084 11.9338 14.9991 11.4463 14.9995 10.9379V2.1255C14.9991 1.56221 14.7878 1.0221 14.4121 0.62364C14.0364 0.225177 13.527 0.000898206 12.9954 0V0Z"
                          fill="#0077B5"
                        />
                      </svg>
                    </p>
                  </div>

                  <div className={classes.groupItem}>
                    <p className={classes.label}>Swift</p>

                    <p className={classes.value}>
                      {request?.swift}
                      <svg
                        width="15"
                        height="17"
                        viewBox="0 0 15 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={() => handleCopy(request?.swift)}
                      >
                        <path
                          d="M8.71362 2.85742H2.00652C1.47434 2.85832 0.964226 3.08215 0.588068 3.47982C0.21191 3.87749 0.00042371 4.41653 0 4.9787V14.3069C0.00042371 14.869 0.21191 15.4081 0.588068 15.8058C0.964226 16.2034 1.47434 16.4273 2.00652 16.4282H8.71362C9.24469 16.4255 9.75316 16.2008 10.1279 15.8034C10.5027 15.4059 10.7133 14.8679 10.7137 14.3069V4.9787C10.7133 4.4177 10.5027 3.87968 10.1279 3.4822C9.75316 3.08473 9.24469 2.86011 8.71362 2.85742ZM9.22968 14.3069C9.22968 14.4515 9.17531 14.5901 9.07853 14.6923C8.98175 14.7946 8.85049 14.852 8.71362 14.852H2.00652C1.86993 14.8516 1.73908 14.7939 1.64265 14.6918C1.54622 14.5896 1.49207 14.4512 1.49207 14.3069V4.9787C1.49207 4.83457 1.54627 4.69634 1.64275 4.59443C1.73923 4.49251 1.87008 4.43526 2.00652 4.43526H8.71362C8.85021 4.43526 8.98123 4.49246 9.07796 4.59432C9.1747 4.69619 9.22925 4.83441 9.22968 4.9787V14.3069Z"
                          fill="#0077B5"
                        />
                        <path
                          d="M12.9954 0H6.81038C6.33036 0.000449177 5.87013 0.202726 5.53071 0.562426C5.1913 0.922127 5.00042 1.40986 5 1.91855C5.00888 2.12164 5.09127 2.3133 5.23003 2.4537C5.3688 2.59409 5.55327 2.67243 5.74512 2.67243C5.93697 2.67243 6.12144 2.59409 6.26021 2.4537C6.39897 2.3133 6.48135 2.12164 6.49024 1.91855C6.49024 1.82857 6.52397 1.74228 6.58401 1.67865C6.64404 1.61503 6.72547 1.57928 6.81038 1.57928H12.9954C13.1318 1.57973 13.2625 1.63748 13.3589 1.73987C13.4552 1.84225 13.5092 1.98093 13.5092 2.1255V10.9362C13.5092 11.0262 13.4755 11.1125 13.4155 11.1761C13.3554 11.2398 13.274 11.2755 13.1891 11.2755H12.8081C12.6103 11.2755 12.4206 11.3588 12.2807 11.507C12.1408 11.6553 12.0622 11.8563 12.0622 12.066C12.0622 12.2756 12.1408 12.4767 12.2807 12.625C12.4206 12.7732 12.6103 12.8565 12.8081 12.8565H13.1907C13.6704 12.8556 14.1303 12.6531 14.4693 12.2935C14.8084 11.9338 14.9991 11.4463 14.9995 10.9379V2.1255C14.9991 1.56221 14.7878 1.0221 14.4121 0.62364C14.0364 0.225177 13.527 0.000898206 12.9954 0V0Z"
                          fill="#0077B5"
                        />
                      </svg>
                    </p>
                  </div>
                </div>
              </>
            )}
          <div className={classes.group}>
            <div className={classes.groupItem}>
              <p className={classes.label}>Withdrawal Fee</p>

              {!withdrawalFeeIsOpen ? (
                <p className={classes.value}>
                  {`${
                    request?.currency === "usd" ||
                    request?.paymentMethod === "crypto"
                      ? "$"
                      : request?.currency === "euro" && "€"
                  } ${numberWithCommas(
                    (Math.round(request?.withdrawalFee * 100) / 100).toFixed(2)
                  )} ${
                    request?.currency === "usd" ||
                    request?.paymentMethod === "crypto"
                      ? "USD"
                      : request?.currency === "euro" && "EUR"
                  }`}
                  {isNewRequest && (
                    <svg
                      width="16"
                      height="15"
                      viewBox="0 0 16 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => setWithdrawalFeeIsOpen(true)}
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M9.85792 0.927237C10.9223 -0.137147 12.648 -0.137147 13.7124 0.927237L14.4833 1.69813C15.5477 2.76251 15.5477 4.48822 14.4833 5.55261L5.618 14.4179L2.08284 14.4179C1.48073 14.4179 0.992628 13.9298 0.992628 13.3277L0.992628 9.79253L9.85792 0.927237ZM12.1706 2.46903L12.9415 3.23992C13.1544 3.4528 13.1544 3.79794 12.9415 4.01082L11.7852 5.16716L10.2434 3.62537L11.3997 2.46903C11.6126 2.25615 11.9577 2.25615 12.1706 2.46903ZM10.2434 6.70895L4.71484 12.2375L3.17305 12.2375L3.17305 10.6957L8.70158 5.16716L10.2434 6.70895Z"
                        fill="black"
                      />
                    </svg>
                  )}
                </p>
              ) : (
                <div className={classes.form_group}>
                  <input
                    value={withdrawalFee}
                    onChange={(e) => setWithdrawalFee(e.target.value)}
                  />
                  <button onClick={requestFeeUpdate}>Save</button>
                </div>
              )}
            </div>

            <div className={classes.groupItem}>
              <p className={classes.label}>PB Share</p>

              {!pbShareIsOpen ? (
                <p className={`${classes.value} ${classes.blue}`}>
                  {`${
                    request?.currency === "usd" ||
                    request?.paymentMethod === "crypto"
                      ? "$"
                      : request?.currency === "euro" && "€"
                  } ${numberWithCommas(
                    Math.round(request?.pbShare * 100) / 100
                  )} ${
                    request?.currency === "usd" ||
                    request?.paymentMethod === "crypto"
                      ? "USD"
                      : request?.currency === "euro" && "EUR"
                  }`}
                  {isNewRequest && (
                    <svg
                      width="16"
                      height="15"
                      viewBox="0 0 16 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => setPBShareIsOpen(true)}
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M9.85792 0.927237C10.9223 -0.137147 12.648 -0.137147 13.7124 0.927237L14.4833 1.69813C15.5477 2.76251 15.5477 4.48822 14.4833 5.55261L5.618 14.4179L2.08284 14.4179C1.48073 14.4179 0.992628 13.9298 0.992628 13.3277L0.992628 9.79253L9.85792 0.927237ZM12.1706 2.46903L12.9415 3.23992C13.1544 3.4528 13.1544 3.79794 12.9415 4.01082L11.7852 5.16716L10.2434 3.62537L11.3997 2.46903C11.6126 2.25615 11.9577 2.25615 12.1706 2.46903ZM10.2434 6.70895L4.71484 12.2375L3.17305 12.2375L3.17305 10.6957L8.70158 5.16716L10.2434 6.70895Z"
                        fill="black"
                      />
                    </svg>
                  )}
                </p>
              ) : (
                <div className={classes.form_group}>
                  <input
                    value={pbShare}
                    onChange={(e) => setPBShare(e.target.value)}
                  />
                  <button onClick={requestPBShareUpdate}>
                    Save
                  </button>
                </div>
              )}
            </div>
          </div>

          <div className={classes.group}>
            <div className={classes.groupItem}>
              <p className={classes.label}>Net Payout</p>

              {!withdrawalAmountIsOpen ? (
                <p className={classes.value}>
                  {`${
                    request?.currency === "usd" ||
                    request?.paymentMethod === "crypto"
                      ? "$"
                      : request?.currency === "euro" && "€"
                  } ${numberWithCommas(
                    Math.round(request?.netPayout * 100) / 100
                  )} ${
                    request?.currency === "usd" ||
                    request?.paymentMethod === "crypto"
                      ? "USD"
                      : request?.currency === "euro" && "EUR"
                  }`}
                  {isNewRequest && (
                    <svg
                      width="16"
                      height="15"
                      viewBox="0 0 16 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => setWithdrawalAmountIsOpen(true)}
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M9.85792 0.927237C10.9223 -0.137147 12.648 -0.137147 13.7124 0.927237L14.4833 1.69813C15.5477 2.76251 15.5477 4.48822 14.4833 5.55261L5.618 14.4179L2.08284 14.4179C1.48073 14.4179 0.992628 13.9298 0.992628 13.3277L0.992628 9.79253L9.85792 0.927237ZM12.1706 2.46903L12.9415 3.23992C13.1544 3.4528 13.1544 3.79794 12.9415 4.01082L11.7852 5.16716L10.2434 3.62537L11.3997 2.46903C11.6126 2.25615 11.9577 2.25615 12.1706 2.46903ZM10.2434 6.70895L4.71484 12.2375L3.17305 12.2375L3.17305 10.6957L8.70158 5.16716L10.2434 6.70895Z"
                        fill="black"
                      />
                    </svg>
                  )}
                </p>
              ) : (
                <div className={classes.form_group}>
                  <input
                    value={withdrawalAmount}
                    onChange={(e) => setWithdrawalAmount(e.target.value)}
                  />
                  <button onClick={requestAmountUpdate}>Save</button>
                </div>
              )}
            </div>

            <div className={classes.groupItem}>
              <p className={classes.label}>Total Withdrawal</p>

              {!totalWithdrawalIsOpen ? (
                <p className={`${classes.value} ${classes.blue}`}>
                  {`${
                    request?.currency === "usd" ||
                    request?.paymentMethod === "crypto"
                      ? "$"
                      : request?.currency === "euro" && "€"
                  } ${numberWithCommas(request?.totalWithdrawal)} ${
                    request?.currency === "usd" ||
                    request?.paymentMethod === "crypto"
                      ? "USD"
                      : request?.currency === "euro" && "EUR"
                  }`}
                  {isNewRequest && (
                    <svg
                      width="16"
                      height="15"
                      viewBox="0 0 16 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => setTotalWithdrawalIsOpen(true)}
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M9.85792 0.927237C10.9223 -0.137147 12.648 -0.137147 13.7124 0.927237L14.4833 1.69813C15.5477 2.76251 15.5477 4.48822 14.4833 5.55261L5.618 14.4179L2.08284 14.4179C1.48073 14.4179 0.992628 13.9298 0.992628 13.3277L0.992628 9.79253L9.85792 0.927237ZM12.1706 2.46903L12.9415 3.23992C13.1544 3.4528 13.1544 3.79794 12.9415 4.01082L11.7852 5.16716L10.2434 3.62537L11.3997 2.46903C11.6126 2.25615 11.9577 2.25615 12.1706 2.46903ZM10.2434 6.70895L4.71484 12.2375L3.17305 12.2375L3.17305 10.6957L8.70158 5.16716L10.2434 6.70895Z"
                        fill="black"
                      />
                    </svg>
                  )}
                </p>
              ) : (
                <div className={classes.form_group}>
                  <input
                    value={totalWithdrawal}
                    onChange={(e) => setTotalWithdrawal(e.target.value)}
                  />
                  <button onClick={requestTotalUpdate}>Save</button>
                </div>
              )}
            </div>
          </div>
        </div>
        {request?.status === "pending" ? (
          <>
            <div className={classes.actionBox}>
              <div className={classes.heading}>
                <p className={classes.label}>Status</p>
              </div>

              <div className={classes.content}>
                <div className={classes.statuses}>
                  <label class={classes.container}>
                    Paid
                    <input
                      type="radio"
                      value={"paid"}
                      name="status"
                      onChange={(e) => setSelectedStatus(e.target.value)}
                    />
                    <span class={classes.checkmark}></span>
                  </label>

                  <label class={classes.container}>
                    Not paid
                    <input
                      value={"not-paid"}
                      type="radio"
                      name="status"
                      onChange={(e) => setSelectedStatus(e.target.value)}
                    />
                    <span class={classes.checkmark}></span>
                  </label>
                </div>
              </div>
            </div>
            {selectedStatus == "not-paid" && (
              <div className={`${classes.actionBox} ${classes.second}`}>
                <div className={classes.heading}>
                  <p className={classes.label}>Reason</p>
                </div>

                <div className={classes.content}>
                  <div className={classes.reasonSelect}>
                    <select
                      name=""
                      id=""
                      onChange={(e) => setReason(e.target.value)}
                    >
                      <option value="Not enough funds">Not enough funds</option>
                      <option value="Wrong crypto wallet address">
                        Wrong crypto wallet address
                      </option>
                      <option value="Wrong banking information">
                        Wrong banking information
                      </option>
                      <option value="Other">Other</option>
                    </select>

                    <svg
                      width="13"
                      height="8"
                      viewBox="0 0 13 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.7168 2.10938L6.68885 6.03617C6.49112 6.22893 6.17457 6.2249 5.9818 6.02717L2.05501 1.99923"
                        stroke="black"
                        stroke-width="3"
                        stroke-linecap="round"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            )}
            {reason === "Other" && selectedStatus === "not-paid" && (
              <div className="mt-3 d-flex justify-content-between align-items-center">
                <p className={classes.label}>Specify</p>
                <div className={`${classes.reasonTextInput}`}>
                  <input
                    className="col"
                    onChange={(e) => setReasonText(e.target.value)}
                  />
                </div>
              </div>
            )}
          </>
        ) : (
          <div className={classes.decision}>
            <div className={classes.heading}>
              <p
                className={`${classes.status} ${
                  request?.status == "paid" ? classes.success : classes.danger
                }`}
              >
                {request?.status == "paid"
                  ? "Payout was done."
                  : `Payout was not done due to ${request?.reason}`}
              </p>
            </div>

            <div className={classes.message}>
              <p>Status update {dateToYMD(request?.updatedAt).full}.</p>
            </div>
          </div>
        )}

        {request?.status === "pending" && (
          <div>
            {!isSending && (
              <button
                className={classes.actionBtn}
                onClick={requestStatusUpdate}
              >
                Update
              </button>
            )}
            {isSending && (
              <div className="d-flex justify-content-center align-items-center">
                <div className="spinner-border" role="status"></div>
              </div>
            )}
          </div>
        )}
      </div>
    </SharedModal>
  );
};

export default WithdrawalRequestModal;
