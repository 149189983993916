import React, { useRef, useState } from "react";
import classes from "./detailpage.module.css";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import AddNewAccountModal from "./AddNewAccount";
import RemoveAccountModal from "./RemoveAccount";
import ViewAccountDetailModal from "./ViewAccountDetailModal";
import PBShareSettings from "./PBShareSettings";
import ViewGroupDetailModal from "./ViewGroupDetailModal";
import AddNewGroupModal from "./AddNewGroup";
import RemoveGroupModal from "./RemoveGroup";
import Mt5SyncAccountModal from "./Mt5SyncAccount";
import { useDispatch } from "react-redux";
import { fetchSyncedAccounts } from "../../Redux/accounts-actions";
import LPLineDetailsModal from "./LPLineDetails";
import ManagementAddAccountModal from "./ManagementAddAccount";

const AccountItem = ({account, getCompany, overview, management, group, lpAccounts, addCashAccount, mt4Account }) => {

  const dispatch = useDispatch()

  console.log("IDDDDD ACCCOUNT", account)

  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isEditGroupOpen, setIsEditGroupOpen] = useState(false);
  const [isRemoveOpen, setIsRemoveOpen] = useState(false);
  const [isRemoveGroupOpen, setIsRemoveGroupOpen] = useState(false);
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [isDetailGroupOpen, setIsDetailGroupOpen] = useState(false);
  const [mt5SyncOpen, setMt5SyncOpen] = useState(false)
  const [mt5SyncManagementOpen, setMt5SyncManagementOpen] = useState(false)
  const [accountId, setAccountId] = useState()
  const [lpLineSyncOpen, setLPLineSyncOpen] = useState(false)

  const popoverRef = useRef();

  const hidePopover = () => {
    document.body.click();
  };

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        <div
          onClick={() => {
            if(group){
              setIsDetailGroupOpen(true)
            }else if(overview){
              setLPLineSyncOpen(true)
              dispatch(fetchSyncedAccounts(account?.company, account._id));
            }else{
              setIsDetailOpen(true);
            }

            hidePopover();
            
          }}
          className={classes.popover_item}
        >
          <svg
            width="20"
            height="14"
            viewBox="0 0 20 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10 4C8.34315 4 7 5.34315 7 7C7 8.65685 8.34315 10 10 10C11.6569 10 13 8.65685 13 7C13 5.34315 11.6569 4 10 4ZM9 7C9 6.44772 9.44771 6 10 6C10.5523 6 11 6.44772 11 7C11 7.55228 10.5523 8 10 8C9.44771 8 9 7.55228 9 7Z"
              fill="black"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M19.83 6.28074C17.542 2.15186 13.8122 0 10 0C6.18777 0 2.45796 2.15186 0.170031 6.28074C-0.0536339 6.68437 -0.0563851 7.18209 0.160292 7.58759C2.41183 11.8013 6.1628 14 10 14C13.8372 14 17.5882 11.8013 19.8397 7.58759C20.0564 7.18208 20.0536 6.68437 19.83 6.28074ZM10 12C7.06097 12 4.04052 10.3724 2.09173 6.94867C4.06862 3.59614 7.07319 2 10 2C12.9268 2 15.9314 3.59614 17.9083 6.94867C15.9595 10.3724 12.939 12 10 12Z"
              fill="black"
            />
          </svg>

          <p>See Details</p>
        </div>
        <div
          onClick={() => {
            if(group){
              setIsEditGroupOpen(true)
            }else if(management){
              setMt5SyncManagementOpen(true)
            }else{
              setIsEditOpen(true);
              dispatch(fetchSyncedAccounts(account?.company, account._id));
              setAccountId(account._id)
              
            }
            
            hidePopover();
          }}
          className={classes.popover_item}
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.34984 1.01441C10.4624 -0.0981162 12.2661 -0.098116 13.3787 1.01441L14.1844 1.82018C15.297 2.93271 15.297 4.73648 14.1844 5.84901L4.91813 15.1153L1.22306 15.1153C0.593721 15.1153 0.083537 14.6051 0.083539 13.9758L0.083538 10.2807L9.34984 1.01441ZM11.7671 2.62595L12.5729 3.43171C12.7954 3.65422 12.7954 4.01497 12.5729 4.23748L11.3643 5.44613L9.75273 3.83459L10.9614 2.62595C11.1839 2.40344 11.5446 2.40344 11.7671 2.62595ZM9.75273 7.05766L3.97412 12.8363L2.36259 12.8363L2.36259 11.2247L8.1412 5.44613L9.75273 7.05766Z"
              fill="black"
            />
          </svg>

          <p>Edit {group ? "Group" : "Account Login"}</p>
        </div>
        <div
          onClick={() => {
            if(group){
              setIsRemoveGroupOpen(true)
              setIsRemoveOpen(false);
            }else{
              setIsRemoveOpen(true);
              setIsDetailGroupOpen(false)
            }
            
            hidePopover();
          }}
          className={`${classes.popover_item} ${classes.last}`}
        >
          <svg
            width="17"
            height="21"
            viewBox="0 0 17 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.66565 9.1534C1.57629 7.99181 2.49473 7 3.65975 7H13.3402C14.5053 7 15.4237 7.9918 15.3344 9.15339L14.642 18.1534C14.5619 19.1954 13.693 20 12.6479 20H4.35206C3.30699 20 2.43811 19.1954 2.35795 18.1534L1.66565 9.1534Z"
              stroke="#BD3437"
              stroke-width="2"
            />
            <path
              d="M16 4H1"
              stroke="#BD3437"
              stroke-width="2"
              stroke-linecap="round"
            />
            <path
              d="M6.5 2C6.5 1.44772 6.94772 1 7.5 1H9.5C10.0523 1 10.5 1.44772 10.5 2V4H6.5V2Z"
              stroke="#BD3437"
              stroke-width="2"
            />
            <path
              d="M10.5 11V16"
              stroke="#BD3437"
              stroke-width="2"
              stroke-linecap="round"
            />
            <path
              d="M6.5 11V16"
              stroke="#BD3437"
              stroke-width="2"
              stroke-linecap="round"
            />
          </svg>

          <p style={{ color: "#BD3437" }}>Remove {group ? "Group" : "Account"}</p>
        </div>
      </Popover.Body>
    </Popover>
  );
  return (
    <div className={`${classes.accountItem} row`}>
      <div className={`${(management || group) ? 'col' : 'col-4'} d-flex align-items-center gap-2`}>
      <AddNewAccountModal
        isEdit={true}
        isOpen={isEditOpen}
        setIsOpen={setIsEditOpen}
        accountId={accountId}
        companyId={account?.company}
        lpAccounts={lpAccounts}
        management={management}
        overview={overview}
        addCashAccount={addCashAccount}
        showMt5Sync={() => {
          setIsEditOpen(false)
          setMt5SyncOpen(true)
        }}
      />

      <Mt5SyncAccountModal 
        isOpen={mt5SyncOpen}
        setIsOpen={setMt5SyncOpen}
        accountId={account?._id}
        companyId={account?.company}
      />

      <ManagementAddAccountModal
          isOpen={mt5SyncManagementOpen}
          setIsOpen={setMt5SyncManagementOpen}
          companyId={account?.company}
      />

    <LPLineDetailsModal 
        isOpen={lpLineSyncOpen}
        setIsOpen={setLPLineSyncOpen}
        accountId={account?._id}
        companyId={account?.company}
        accountName={account?.accountName}
      />

      <AddNewGroupModal
          isEdit={true}
          isOpen={isEditGroupOpen}
          setIsOpen={setIsEditGroupOpen}
          groupId={account?._id}
          companyId={account?.company}
          lpAccounts={lpAccounts}
      />

      <RemoveAccountModal isOpen={isRemoveOpen} setIsOpen={setIsRemoveOpen} account={account}/>
      <RemoveGroupModal isOpen={isRemoveGroupOpen} setIsOpen={setIsRemoveGroupOpen} group={account} />
      <ViewAccountDetailModal
        isOpen={isDetailOpen}
        setIsOpen={setIsDetailOpen}
        accountDetails={account}
      />
      <ViewGroupDetailModal isOpen={isDetailGroupOpen} setIsOpen={setIsDetailGroupOpen} accountDetails={account} />
      <div className={`d-flex flex-row gap-2 justify-content-between ${(management || group) && 'w-100'}`}>
      <p className={classes.accountItemText}>{group ? account?.groupName : management ? account?.account?.accountName : account?.accountName}</p>
      <div>
        <OverlayTrigger
          trigger="click"
          placement="bottom"
          overlay={popover}
          rootClose
          ref={popoverRef}
        >
          <svg
            width="15"
            height="4"
            viewBox="0 0 15 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 1.575C0 1.35833 0.0374999 1.15417 0.1125 0.9625C0.195833 0.770833 0.304167 0.604167 0.4375 0.4625C0.579167 0.320834 0.745833 0.208333 0.9375 0.125C1.12917 0.0416666 1.33333 0 1.55 0C1.76667 0 1.97083 0.0416666 2.1625 0.125C2.35417 0.208333 2.52083 0.320834 2.6625 0.4625C2.80417 0.604167 2.91667 0.770833 3 0.9625C3.08333 1.15417 3.125 1.35833 3.125 1.575C3.125 1.8 3.08333 2.00833 3 2.2C2.91667 2.38333 2.80417 2.54583 2.6625 2.6875C2.52083 2.82917 2.35417 2.9375 2.1625 3.0125C1.97083 3.09583 1.76667 3.1375 1.55 3.1375C1.33333 3.1375 1.12917 3.09583 0.9375 3.0125C0.745833 2.9375 0.579167 2.82917 0.4375 2.6875C0.304167 2.54583 0.195833 2.38333 0.1125 2.2C0.0374999 2.00833 0 1.8 0 1.575Z"
              fill="#0077B5"
            />
            <path
              d="M5.9082 1.575C5.9082 1.35833 5.9457 1.15417 6.0207 0.9625C6.10404 0.770833 6.21237 0.604167 6.3457 0.4625C6.48737 0.320834 6.65404 0.208333 6.8457 0.125C7.03737 0.0416666 7.24154 0 7.4582 0C7.67487 0 7.87904 0.0416666 8.0707 0.125C8.26237 0.208333 8.42904 0.320834 8.5707 0.4625C8.71237 0.604167 8.82487 0.770833 8.9082 0.9625C8.99154 1.15417 9.0332 1.35833 9.0332 1.575C9.0332 1.8 8.99154 2.00833 8.9082 2.2C8.82487 2.38333 8.71237 2.54583 8.5707 2.6875C8.42904 2.82917 8.26237 2.9375 8.0707 3.0125C7.87904 3.09583 7.67487 3.1375 7.4582 3.1375C7.24154 3.1375 7.03737 3.09583 6.8457 3.0125C6.65404 2.9375 6.48737 2.82917 6.3457 2.6875C6.21237 2.54583 6.10404 2.38333 6.0207 2.2C5.9457 2.00833 5.9082 1.8 5.9082 1.575Z"
              fill="#0077B5"
            />
            <path
              d="M11.8164 1.575C11.8164 1.35833 11.8539 1.15417 11.9289 0.9625C12.0122 0.770833 12.1206 0.604167 12.2539 0.4625C12.3956 0.320834 12.5622 0.208333 12.7539 0.125C12.9456 0.0416666 13.1497 0 13.3664 0C13.5831 0 13.7872 0.0416666 13.9789 0.125C14.1706 0.208333 14.3372 0.320834 14.4789 0.4625C14.6206 0.604167 14.7331 0.770833 14.8164 0.9625C14.8997 1.15417 14.9414 1.35833 14.9414 1.575C14.9414 1.8 14.8997 2.00833 14.8164 2.2C14.7331 2.38333 14.6206 2.54583 14.4789 2.6875C14.3372 2.82917 14.1706 2.9375 13.9789 3.0125C13.7872 3.09583 13.5831 3.1375 13.3664 3.1375C13.1497 3.1375 12.9456 3.09583 12.7539 3.0125C12.5622 2.9375 12.3956 2.82917 12.2539 2.6875C12.1206 2.54583 12.0122 2.38333 11.9289 2.2C11.8539 2.00833 11.8164 1.8 11.8164 1.575Z"
              fill="#0077B5"
            />
          </svg>
        </OverlayTrigger>
      </div>
      </div>
      </div>

      {(overview || management || group || mt4Account) ? <div></div> : <div className="col-4">

      <PBShareSettings pbSettings={account?.pbShareSettings} accountId={account?._id} companyId={account?.company}/>
      </div>}
      
    </div>
  );
};

export default AccountItem;
