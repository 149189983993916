import React, { useEffect } from "react";
import MainPage from "../../components/CustomerSupport/MainPage";
import MainLayout from "../../components/Layout/MainLayout";

const Index = () => {
  useEffect(() => {
    document.title = "Customer Support - Prime Bridge Admin";
  }, []);
  return (
    <MainLayout>
      <MainPage />
    </MainLayout>
  );
};

export default Index;
