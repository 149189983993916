import { useNavigate, useParams } from "react-router-dom";
import AuthWrapper from "../../components/Auth/AuthWrapper";
import classes from "./PasswordReset.module.css";
import { env } from "../../constants/env";
import { useState } from "react";
import { toast } from "react-hot-toast";

const PasswordReset = () => {
  const navigate = useNavigate();
  const { userId, token } = useParams()

  const [isSending, setIsSending] = useState()
  const [password, setPassword] = useState()
  const [loginError, setLoginError] = useState("")
  const [confirmpassword, setConfirmPassword] = useState()

  const updatePassword = async() => {
    if(password !== confirmpassword){
      setLoginError("Passwords don't match")
      return
    }
    const dataContent = {password}
    const sendRequest = async () => {
      setIsSending(true)
      const response = await fetch(`${env.primeBridgeUrl}/api/password-reset/${userId}/${token}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataContent)
      });

      if (!response.ok) {
        const error = await response.json();
        console.log("Error from Reset Password: ",error)
        setLoginError(error.error)
        setIsSending(false)
        throw new Error("sending plays failed.");
      }

      const result = await response.json();
      
      setIsSending(false)
      setPassword("")
      setConfirmPassword("")
      toast.success("Password reset");
      navigate("/auth")

      console.log("Reset response", result);

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      setIsSending(false)
      console.log(error);
    }
  }
  return (
    <AuthWrapper>
      <h3>Reset Password?</h3>
        <input placeholder="Password" type="password" value={password} onChange={(e) => setPassword(e.target.value)}/>
        <input placeholder="Confirm Password" type="password" value={confirmpassword} onChange={(e) => setConfirmPassword(e.target.value)}/>
        {loginError && <h6 style={{color: "#BD3437"}}>{loginError}</h6>}
        {!isSending && <button onClick={updatePassword}>
        Update
        </button>}
        {isSending && (
          <div class="spinner-border" role="status">
          </div>
        )}
    </AuthWrapper>
  );
};
export default PasswordReset;
