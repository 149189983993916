import { createSlice } from '@reduxjs/toolkit';

const documentsSlice = createSlice({
  name: 'documents',
  initialState: {
    companyFiles: [],
  },
  reducers: {

    getDocuments (state, action) {
      state.companyFiles = action.payload.companyFiles
    },
  }
});

export const documentsActions = documentsSlice.actions;

export default documentsSlice;
