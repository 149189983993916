import { createSlice } from '@reduxjs/toolkit';

const requestsSlice = createSlice({
  name: 'requests',
  initialState: {
    pendingDepositRequests: [],
    allDepositRequests: [],
    pendingWithdrawalRequests: [],
    allWithdrawalRequests: [],
    pendingTransferRequests: [],
    allTransferRequests: []
  },
  reducers: {

    getPendingDepositRequests (state, action) {
      state.pendingDepositRequests = action.payload.pendingDepositRequests
    },
    getAllDepositRequests (state, action) {
      state.allDepositRequests = action.payload.allDepositRequests
    },
    getPendingWithdrawalRequests (state, action) {
      state.pendingWithdrawalRequests = action.payload.pendingWithdrawalRequests
    },
    getAllWithdrawalRequests (state, action) {
      state.allWithdrawalRequests = action.payload.allWithdrawalRequests
    },
    getPendingTransferRequests (state, action) {
      state.pendingTransferRequests = action.payload.pendingTransferRequests
    },
    getAllTransferRequests (state, action) {
      state.allTransferRequests = action.payload.allTransferRequests
    },
  }
});

export const requestsActions = requestsSlice.actions;

export default requestsSlice;
