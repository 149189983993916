import AuthCard from "./AuthCard";
import LogoContainer from "./LogoContainer";
import classes from "./AuthWrapper.module.css";
import { env } from "../../constants/env";

const AuthWrapper = ({children, cardHeight}) => {
  return (
    <div className={`${classes.wrapper}`}>
      <div className={`container`}>
        <LogoContainer />
        <div className="d-flex flex-column justify-content-center align-items-center mt-5">
            <AuthCard height={{cardHeight}}>
              {children}
            </AuthCard>
            <button className={classes.employeeLoginButton} onClick={() => window.location.assign(env.portalUrl)}>Client Login</button>
        </div>
      </div>
    </div>
  );
};
export default AuthWrapper;
