import React, { useState } from "react";
import SharedModal from "../shared/Modal";
import classes from "../FinancialManagement/styles/withdrawal-modal.module.css";
import { env } from "../../constants/env";
import { fetchAllLeads, fetchPendingLeads } from "../../Redux/leads-actions";
import { useDispatch } from "react-redux";

const LeadDetailsModal = ({
  isOpen,
  setIsOpen,
  status,
  isNewRequest = false,
  lead
}) => {
  const dispatch = useDispatch()
  const [selectedStatus, setSelectedStatus] = useState("");
  const [reason, setReason] = useState("")
  const [reasonText, setReasonText] = useState("")


  const [isSending, setIsSending] = useState(false)


  const leadStatusUpdate = async () => {
    let dataContent = {status: selectedStatus, reason: reason === "Other" ? reasonText : reason}
    const sendRequest = async () => {
      setIsSending(true)
      const response = await fetch(`${env.primeBridgeUrl}/api/lead/update-company-status/${lead._id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataContent)
      });

      if (!response.ok) {
        setIsSending(false)
        const error = await response.json();

        console.log("ERROR", error)

        throw new Error("sending plays failed.");
      }

      const result = await response.json();

      console.log("Reply from plays data", result);

      if(isNewRequest){
        dispatch(fetchPendingLeads())
      }else{
        dispatch(fetchAllLeads())
      }

      

      setIsSending(false)

      setIsOpen(false)

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      setIsSending(false)
      console.log(error);
    }
  };

  const dateToYMD = date => {
    const formatedDate = date.split('T')[0]
    console.log("DATE", formatedDate)
    var newDate = new Date(date)
    var fullDate = new Date(date)
    var strArray = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ]
    var days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    var d = newDate.getDate()
    var dayName = days[newDate.getDay()]
    var m = strArray[newDate.getMonth()]
    var y = newDate.getFullYear()
    var hr = newDate.getHours()
    var min = newDate.getMinutes()

    if (min < 10) {
      min = "0" + min;
    }
    var ampm = "AM";
    if( hr > 12 ) {
        hr -= 12;
        ampm = "PM";
    }

    return {
      full: '' + m + ' ' + (d <= 9 ? '0' + d : d) + ', ' + (hr <= 9 ? '0' + hr : hr) + ':' + min + ' ' + ampm,
      date: d,
      month: m
    }
  }

  return (
    <SharedModal className={classes.modal} show={isOpen} setShow={setIsOpen}>
      <div className={classes.body}>
        <div className={classes.headerSection}>
          <p className={classes.header}>
            {isNewRequest ? "New Demo Request" : "Demo Request"}{" "}
          </p>
        </div>

        <div>
          <div className={classes.group}>
            <div className={classes.groupItem}>
              <p className={classes.label}>Director</p>

              <p className={classes.value}>{lead ? `${lead?.firstName} ${lead?.lastName}` : ""}</p>
            </div>

            <div className={classes.groupItem}>
              <p className={classes.label}>Phone number</p>

              <p className={classes.value}>{lead?.phone}</p>
            </div>
          </div>
          <div className={classes.group}>
            <div className={classes.groupItem}>
              <p className={classes.label}>Email address</p>

              <p className={classes.value}>{lead?.email}</p>
            </div>

            <div className={classes.groupItem}></div>
          </div>
          <div className={classes.group}>
            <div className={classes.groupItem}>
              <p className={classes.label}>Headquarters</p>

              <p className={classes.value}>{lead?.country}</p>
            </div>

            <div className={classes.groupItem}>
              <p className={classes.label}>Number of employees</p>

              <p className={classes.value}>{lead?.employeesNum}</p>
            </div>
          </div>
          <div className={classes.group}>
            <div className={classes.groupItem}>
              <p className={classes.label}>Company</p>

              <p className={classes.value}>{lead?.companyName}</p>
            </div>

            <div className={classes.groupItem}>
              <p className={classes.label}>Website</p>

              <p className={`${classes.value} ${classes.blue}`}>
              {lead?.website}
              </p>
            </div>
          </div>

          <div className={classes.group}>
            <div>
              <p className={classes.label}>Description</p>

              <p className={classes.value}>
              {lead?.description}
              </p>
            </div>
          </div>
        </div>

        {lead?.status == "pending" ? (
          <>
            <div className={classes.actionBox}>
              <div className={classes.heading}>
                <p className={classes.label}>Status</p>
              </div>

              <div className={classes.content}>
                <div
                  className={classes.statuses}
                >
                  <label class={classes.container}>
                    Contacted
                    <input type="radio" value={"contacted"} name="status" onChange={(e) => setSelectedStatus(e.target.value)}/>
                    <span class={classes.checkmark}></span>
                  </label>

                  <label class={classes.container}>
                    Discarded
                    <input
                      value={"discarded"}
                      type="radio"
                      name="status"
                      onChange={(e) => setSelectedStatus(e.target.value)}
                    />
                    <span class={classes.checkmark}></span>
                  </label>
                </div>
              </div>
            </div>
            {selectedStatus === "discarded" && (
              <div className={`${classes.actionBox} ${classes.second}`}>
                <div className={classes.heading}>
                  <p className={classes.label}>Reason</p>
                </div>

                <div className={classes.content}>
                  <div className={classes.reasonSelect}>
                    <select name="" id="" onChange={(e) => setReason(e.target.value)}>
                      <option>
                        Select reason
                      </option>
                      <option value="Location restriction">Location restriction</option>
                      <option value="Already a customer">Already a customer</option>
                      <option value="Other">Other</option>
                    </select>

                    <svg
                      width="13"
                      height="8"
                      viewBox="0 0 13 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.7168 2.10938L6.68885 6.03617C6.49112 6.22893 6.17457 6.2249 5.9818 6.02717L2.05501 1.99923"
                        stroke="black"
                        stroke-width="3"
                        stroke-linecap="round"
                      />
                    </svg>
                  </div>
                  
                  
                </div>
              </div>
            )}
            {(reason === "Other" && selectedStatus === "discarded") && <div className="mt-3 d-flex justify-content-between align-items-center">
            <p className={classes.label}>Specify</p>
            <div className={`${classes.reasonTextInput}`}>
                  <input className="col" onChange={(e) => setReasonText(e.target.value)}/>
                  </div>
            </div>}
          </>
        ) : (
          <div className={classes.decision}>
            <div className={classes.heading}>
              <p
                style={{ color: "#0077B5" }}
                className={`${classes.status} ${classes.blue}`}
              >
                {lead?.status === "contacted" ? (
                  <>
                    Admin status update was done{" "}
                    <strong>{dateToYMD(lead?.updatedAt).full}</strong>
                  </>
                ) : (
                  <>
                    Request was declined due to{" "}
                    <strong>{lead?.reason}</strong>{" "}
                  </>
                )}
              </p>
            </div>

            {lead?.status === "declined" && (
              <div className={classes.message}>
                <p>Admin review done {dateToYMD(lead?.updatedAt).full}.</p>
              </div>
            )}
          </div>
        )}

        {lead?.status === "pending" && (
          <div>
            {!isSending && (<button className={classes.actionBtn} onClick={leadStatusUpdate}>Update</button>)}
            {isSending && (
              <div className="d-flex justify-content-center align-items-center">
                <div className="spinner-border" role="status">
              </div>
            </div>
        )}
          </div>
        )}
      </div>
    </SharedModal>
  );
};

export default LeadDetailsModal;
