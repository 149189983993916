import React, { useEffect } from "react";
import MainLayout from "../../components/Layout/MainLayout";
import MainPage from "../../components/Teams/MainPage";

const IndexPage = () => {
  useEffect(() => {
    document.title = "Team - Prime Bridge Admin";
  }, []);
  return (
    <MainLayout>
      <MainPage />
    </MainLayout>
  );
};

export default IndexPage;
