import React, { useEffect } from "react";
import MainPage from "../../components/Compliance/MainPage";
import MainLayout from "../../components/Layout/MainLayout";

const IndexPage = () => {
  useEffect(() => {
    document.title = "Compliance - Prime Bridge Admin";
  }, []);
  return (
    <MainLayout>
      {" "}
      <MainPage />{" "}
    </MainLayout>
  );
};

export default IndexPage;
