import { useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthCard from "../../components/Auth/AuthCard";
import AuthWrapper from "../../components/Auth/AuthWrapper";
import LogoContainer from "../../components/Auth/LogoContainer";
import { env } from "../../constants/env";
import {setCookie} from "../../utilities/functions"
import classes from "./AuthPage.module.css";
import { toast } from "react-hot-toast";

const AuthPage = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState()
  const [username, setUsername] = useState()
  const [isSending, setIsSending] = useState()
  
  const userloginHandler = async () => {
    let dataContent = { email: username?.trim(), password: password}
    const sendRequest = async () => {
      setIsSending(true)
      const response = await fetch(`${env.primeBridgeUrl}/api/auth/admin`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataContent)
      });

      if (!response.ok) {
        setIsSending(false)
        const err = await response.json();
        toast.error(err.error)
        throw new Error("sending plays failed.");
      }

      const result = await response.json();
      setIsSending(false)
      

      if(result?.isAdmin){
        navigate("/")
      }else{
        if(result?.accessLevel?.includes('company-management')){
          navigate("/")
        }else if(result?.accessLevel?.includes('financial-management')){
          navigate("/financial-management/funds")
        }else if(result?.accessLevel?.includes('risk-management')){
          navigate("/risk-management")
        }else if(result?.accessLevel?.includes('leads')){
          navigate("/leads")
        }else if(result?.accessLevel?.includes('complaince')){
          navigate("/compliance")
        }else if(result?.accessLevel?.includes('customer-support')){
          navigate("/customer-support")
        }else if(result?.accessLevel?.includes('team')){
          navigate("/team")
        }
        
      }

      setCookie("authToken", result.token)
      setCookie("userId", result.userId)

      console.log("Reply from plays data", result);

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      setIsSending(false)
      console.log(error);
    }
  };
  return (
    <AuthWrapper>
      <h3>Prime Bridge Employee Login</h3>
      <input placeholder="Username" type="text" onChange={(e) => setUsername(e.target.value)}/>
        <input placeholder="Password" type="password" onChange={(e) => setPassword(e.target.value)}/>
        {!isSending && (<button onClick={userloginHandler}>
            Enter
        </button>)}

        {isSending && (
          <div class="spinner-border" role="status">
          </div>
        )}
        <div className={classes.forgotPasswordText} role="button" onClick={() => navigate("/forgot-password")}>Forgot Password?</div>
    </AuthWrapper>
  );
};
export default AuthPage;
