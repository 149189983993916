import React, { useEffect, useState } from "react";
import classes from "../Dashboard/edit-member.module.css";
import { NavLink, Link, useParams, useNavigate } from "react-router-dom";
import { env } from "../../constants/env";
import { useDispatch } from "react-redux";
import { fetchUsers } from "../../Redux/users-actions";

const EditTeamMember = () => {
  const { id } = useParams()

  const navigate = useNavigate();

  const dispatch = useDispatch()

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isSending, setIsSending] = useState()
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [accessLevel, setAccessLevel] = useState([])

  console.log("ACCESS LEVEL", accessLevel)


  useEffect(() => {
    getUser()
  }, [])

  const  getUser = async () => {
    const sendRequest = async () => {
      const response = await fetch(`${env.primeBridgeUrl}/api/auth/user/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("sending plays failed.");
      }

      const result = await response.json();
      
      setFirstName(result?.firstName)
      setLastName(result?.lastName)
      setEmail(result?.email)
      setAccessLevel(result?.accessLevel)


      console.log("Reply from plays data", result);

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };

  const updateUserHandler = async () => {
    
    let dataContent = {firstName: firstName, lastName: lastName, email: email, password: password, accessLevel: accessLevel}
    const sendRequest = async () => {
      setIsSending(true)
      const response = await fetch(`${env.primeBridgeUrl}/api/auth/update-user/${id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataContent)
      });

      if (!response.ok) {
        setIsSending(false)
        console.log("ERROR RESPONSE", response)
        throw new Error("sending plays failed.");
      }

      const result = await response.json();
      setIsSending(false)

      dispatch(fetchUsers())

      navigate("/team")

      console.log("Updated User", result);

      return result;
    };

    try {
      if(password === confirmPassword && password !== "" && confirmPassword !== ""){
        await sendRequest();
      }else if(password === "" && confirmPassword === ""){
        await sendRequest();
      }else{
        console.log("Passwords don't match")
      }
      
    } catch (error) {
      setIsSending(false)
      console.log(error);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <NavLink style={{ textDecoration: "none" }} to={"/team"}>
          <p className={classes.name}>Team</p>
        </NavLink>
        <h3 className={classes.title}>Edit Member</h3>
      </div>
      <div className={classes.form}>
        <p className={classes.title}>Login Information</p>

        <div className={classes.group}>
          <div className={classes.inputContainer}>
            <input
              placeholder="First Name"
              className={classes.input}
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
          <div className={classes.inputContainer}>
            <input
              placeholder="Last Name"
              className={classes.input}
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
        </div>

        <div className={classes.inputContainer}>
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            className={classes.input}
            type="text"
          />
        </div>

        <div className={classes.group}>
          <div className={`${classes.inputPassword}`}>
            <input
              //   placeholder="Last Name"
              className={classes.input}
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <svg
              width="22"
              height="16"
              viewBox="0 0 22 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => setShowPassword((val) => !val)}
              style={{ cursor: "pointer" }}
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11 4.57143C9.17746 4.57143 7.7 6.10645 7.7 8C7.7 9.89355 9.17746 11.4286 11 11.4286C12.8225 11.4286 14.3 9.89355 14.3 8C14.3 6.10645 12.8225 4.57143 11 4.57143ZM9.9 8C9.9 7.36882 10.3925 6.85714 11 6.85714C11.6075 6.85714 12.1 7.36882 12.1 8C12.1 8.63118 11.6075 9.14286 11 9.14286C10.3925 9.14286 9.9 8.63118 9.9 8Z"
                fill={showPassword ? "#0077B5" : "#D0D0D0"}
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M21.813 7.17798C19.2962 2.45927 15.1934 0 11 0C6.80655 0 2.70375 2.45927 0.187034 7.17799C-0.0589973 7.63928 -0.0620236 8.2081 0.176321 8.67153C2.65302 13.4872 6.77909 16 11 16C15.2209 16 19.347 13.4872 21.8237 8.67153C22.062 8.20809 22.059 7.63927 21.813 7.17798ZM11 13.7143C7.76706 13.7143 4.44457 11.8542 2.30091 7.94134C4.47548 4.10988 7.78051 2.28571 11 2.28571C14.2195 2.28571 17.5245 4.10988 19.6991 7.94134C17.5554 11.8542 14.2329 13.7143 11 13.7143Z"
                fill={showPassword ? "#0077B5" : "#D0D0D0"}
              />
            </svg>
          </div>
          <div className={`${classes.inputPassword}`}>
            <input
              //   placeholder="Last Name"
              className={classes.input}
              type={showConfirmPassword ? "text" : "password"}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <svg
              width="22"
              height="16"
              viewBox="0 0 22 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => setShowConfirmPassword((val) => !val)}
              style={{ cursor: "pointer" }}
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11 4.57143C9.17746 4.57143 7.7 6.10645 7.7 8C7.7 9.89355 9.17746 11.4286 11 11.4286C12.8225 11.4286 14.3 9.89355 14.3 8C14.3 6.10645 12.8225 4.57143 11 4.57143ZM9.9 8C9.9 7.36882 10.3925 6.85714 11 6.85714C11.6075 6.85714 12.1 7.36882 12.1 8C12.1 8.63118 11.6075 9.14286 11 9.14286C10.3925 9.14286 9.9 8.63118 9.9 8Z"
                fill={showConfirmPassword ? "#0077B5" : "#D0D0D0"}
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M21.813 7.17798C19.2962 2.45927 15.1934 0 11 0C6.80655 0 2.70375 2.45927 0.187034 7.17799C-0.0589973 7.63928 -0.0620236 8.2081 0.176321 8.67153C2.65302 13.4872 6.77909 16 11 16C15.2209 16 19.347 13.4872 21.8237 8.67153C22.062 8.20809 22.059 7.63927 21.813 7.17798ZM11 13.7143C7.76706 13.7143 4.44457 11.8542 2.30091 7.94134C4.47548 4.10988 7.78051 2.28571 11 2.28571C14.2195 2.28571 17.5245 4.10988 19.6991 7.94134C17.5554 11.8542 14.2329 13.7143 11 13.7143Z"
                fill={showConfirmPassword ? "#0077B5" : "#D0D0D0"}
              />
            </svg>
          </div>
        </div>
        <div>
          <div className={classes.access}>
            <p className={classes.title}>User Access</p>
            <div className={classes.group}>
              <div className={classes.groupItem}>
                <label class={classes.check_cont}>
                  <input  type="checkbox" checked={accessLevel.includes("company-management")} onChange={() => {
                  if(!accessLevel.includes("company-management")){
                    setAccessLevel([...accessLevel, "company-management"])
                  }else{
                    setAccessLevel((current) => current.filter((access) => access !== "company-management"))
                  }}}/>
                  <span className={classes.checkmark}></span>
                  <p>Company Management</p>
                </label>
                <label class={classes.check_cont}>
                  <input type="checkbox" checked={accessLevel.includes("financial-management")} onChange={() => {
                  if(!accessLevel.includes("financial-management")){
                    setAccessLevel([...accessLevel, "financial-management"])
                  }else{
                    setAccessLevel((current) => current.filter((access) => access !== "financial-management"))
                  }}}/>
                  <span className={classes.checkmark}></span>
                  <p>Financial Management</p>
                </label>
                <label class={classes.check_cont}>
                  <input type="checkbox" checked={accessLevel.includes("risk-management")} onChange={() => {
                  if(!accessLevel.includes("risk-management")){
                    setAccessLevel([...accessLevel, "risk-management"])
                  }else{
                    setAccessLevel((current) => current.filter((access) => access !== "risk-management"))
                  }}}/>
                  <span className={classes.checkmark}></span>
                  <p>Risk Management</p>
                </label>
                <label class={classes.check_cont}>
                  <input type="checkbox" checked={accessLevel.includes("leads")} onChange={() => {
                  if(!accessLevel.includes("leads")){
                    setAccessLevel([...accessLevel, "leads"])
                  }else{
                    setAccessLevel((current) => current.filter((access) => access !== "leads"))
                  }}}/>
                  <span className={classes.checkmark}></span>
                  <p>Leads</p>
                </label>
              </div>

              <div className={classes.groupItem}>
                <label class={classes.check_cont}>
                  <input type="checkbox" checked={accessLevel.includes("compliance")} onChange={() => {
                  if(!accessLevel.includes("compliance")){
                    setAccessLevel([...accessLevel, "compliance"])
                  }else{
                    setAccessLevel((current) => current.filter((access) => access !== "compliance"))
                  }}}/>
                  <span className={classes.checkmark}></span>
                  <p>Compliance</p>
                </label>
                <label class={classes.check_cont}>
                  <input type="checkbox" checked={accessLevel.includes("customer-support")} onChange={() => {
                  if(!accessLevel.includes("customer-support")){
                    setAccessLevel([...accessLevel, "customer-support"])
                  }else{
                    setAccessLevel((current) => current.filter((access) => access !== "customer-support"))
                  }}}/>
                  <span className={classes.checkmark}></span>
                  <p>Customer Support</p>
                </label>
                <label class={classes.check_cont}>
                  <input type="checkbox" checked={accessLevel.includes("team")} onChange={() => {
                  if(!accessLevel.includes("team")){
                    setAccessLevel([...accessLevel, "team"])
                  }else{
                    setAccessLevel((current) => current.filter((access) => access !== "team"))
                  }}}/>
                  <span className={classes.checkmark}></span>
                  <p>Team</p>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className={classes.btnContainer}>
        {!isSending && <><Link
            style={{ textDecoration: "none", display: "block" }}
            to={"/team"}
          >
            <button className={classes.cancel}>Cancel</button>
          </Link>


            <button className={classes.confirm} onClick={updateUserHandler}>Save</button></>}

          {isSending && (
          <div className="spinner-border" role="status">
          </div>
        )}
        </div>
      </div>
    </div>
  );
};

export default EditTeamMember;
