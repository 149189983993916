import React, { useEffect, useState } from "react";
import SharedModal from "../shared/Modal";
import classes from "./remove.module.css";
import modalClasses from "./edit-company.module.css";
import { env } from "../../constants/env";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllAccounts, fetchAllMt4Accounts, fetchAllOverviewAccounts, fetchSyncedAccounts } from "../../Redux/accounts-actions";
import DropDownIcon from "../icons/DropDownIcon";


const AddNewAccountModal = ({ isOpen, setIsOpen, isEdit, companyId, accountId, overview, management, lpAccounts=[], addCashAccount, showMt5Sync }) => {

  const dispatch = useDispatch()
  const [accountName, setAccountName] = useState()
  const [accountID, setAccountID] = useState()
  const [accountid, setAccountid] = useState()
  const [masterPassword, setMasterPassword] = useState()
  const [investorPassword, setInvestorPassword] = useState()
  const [apiId, setApiId] = useState()
  const [depositCorrection, setDepositCorrection] = useState()
  const [isSending, setIsSending] = useState(false)
  const [dropDown, setDropDown] = useState(false)
  const [selectedOption, setSelectedOption] = useState()
  const [cashAccountOn, setCashAccountOn] = useState(false)
  const [realizedCorrection, setRealizedCorrection] = useState()
  const [cumulativeCorrection, setCumulativeCorrection] = useState()
  
  

const syncedAccounts = useSelector((state) => state.accounts.syncedAccounts);

const allMt5Accounts = useSelector((state) => state.accounts.allMt5Accounts);

const [items, setItems] = useState([])

useEffect(() => {
  syncedAccounts.map(obj1 => {
    const matchingObj = allMt5Accounts.find(obj2 => obj2.credentials.accountNumber === obj1.credentials.accountNumber);
    if (!matchingObj) {
      deleteAccountHandler(obj1._id)
    }
  });

  const newArr = syncedAccounts.map(v => ({...v, checked: true}))

  setItems(newArr)
  
}, [syncedAccounts])
  

  console.log("EDIT LP LINES", items)
  

  useEffect(() =>{
    if(isEdit){
      getSingleAccount()
    }
   
  }, [accountId])


  const addDisAccountHandler = async () => {
    console.log("CHECKED ITEMS", items)
    let dataContent = {accountsObjects: items}
    const sendRequest = async () => {
      setIsSending(true)
      const response = await fetch(`${env.primeBridgeUrl}/api/account/upate-synced-account/${accountId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataContent)
      });

      if (!response.ok) {
        setIsSending(false)
        const result = await response.json();
        console.log("ERRRR", result)
        throw new Error("sending plays failed.");
      }

      const result = await response.json();
      setIsSending(false)
      setIsOpen(false);

      console.log("ADD SYNC ACCOUNT", result);

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      setIsSending(false)
      console.log(error);
    }
  };

  const addAccountHandler = async () => {
    let dataContent = { accountName: accountName, company: companyId, overview: overview ? overview : false, management: management ? management : false, cashAccount: cashAccountOn, lpAccount: selectedOption?._id,
      credentials: {accountNumber: accountID, server: "TCBridge-Live", apiId, depositCorrection, realizedCorrection, cumulativeCorrection, masterPassword: masterPassword ?  masterPassword.trim() : "", investorPassword: investorPassword ? investorPassword.trim() : "" }}
    const sendRequest = async () => {
      setIsSending(true)
      const response = await fetch(`${env.primeBridgeUrl}/api/account`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataContent)
      });

      if (!response.ok) {
        setIsSending(false)
        const result = await response.json();
        console.log("ERRRR", result)
        throw new Error("sending plays failed.");
      }

      const result = await response.json();
      setIsSending(false)
      setIsOpen(false);

      setAccountName("")
      setAccountID("")
      setMasterPassword("")
      setInvestorPassword("")

      dispatch(fetchAllAccounts(companyId))

      console.log("Reply from plays data", result);

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      setIsSending(false)
      console.log(error);
    }
  };


  const  deleteAccountHandler = async (id) => {
    const sendRequest = async () => {
      setIsSending(true)
      const response = await fetch(`${env.primeBridgeUrl}/api/account/delete-account/${id}`, {
        method: 'DELETE',
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        setIsSending(false)
        throw new Error("sending plays failed.");
      }

      const result = await response.json();
      

      console.log("Reply from plays data", result);

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      setIsSending(false)
      console.log(error);
    }
  };

  const getSingleAccount = async () => {
    const sendRequest = async () => {
      const response = await fetch(`${env.primeBridgeUrl}/api/account/${accountId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Getting account failed.");
      }

      const result = await response.json();

      console.log("ACCOUNT I WANT TO SEE", result._id)

    

      setAccountName(result.accountName)
      setAccountID(result?.credentials?.accountNumber)
      setMasterPassword(result?.credentials?.masterPassword)
      setInvestorPassword(result?.credentials?.investorPassword)
      setApiId(result?.credentials?.apiId)
      setDepositCorrection(result?.credentials?.depositCorrection)
      setRealizedCorrection(result?.credentials?.realizedCorrection)
      setCumulativeCorrection(result?.credentials?.cumulativeCorrection)
      setCashAccountOn(result.cashAccount)

      

      if(isEdit)
        getLPAccount(result?.lpAccount)

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };


  const getLPAccount = async (id) => {
    const sendRequest = async () => {
      const response = await fetch(`${env.primeBridgeUrl}/api/account/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Getting account failed.");
      }

      const result = await response.json();

      console.log("OVERVIEW STATE: ", result)

      setSelectedOption(result)

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };

  const updateAccount = async () => {
    let dataContent = { accountName: accountName, lpAccount: selectedOption?._id, credentials: {accountNumber: accountID, server: "TCBridge-Live", apiId, depositCorrection, realizedCorrection, cumulativeCorrection, masterPassword: masterPassword.trim(), investorPassword: investorPassword.trim()}, cashAccount: cashAccountOn}

    console.log("BEFORE YOU SEND", dataContent)
    const sendRequest = async () => {
      setIsSending(true)
      const response = await fetch(`${env.primeBridgeUrl}/api/account/update-account/${accountId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataContent)
      });

      if (!response.ok) {
        setIsSending(false)
        throw new Error("Getting account failed.");
      }

      const result = await response.json();
      setIsSending(false)
      setIsOpen(false);
      dispatch(fetchAllAccounts(companyId))

      console.log("BEFORE YOU SEND SEE REUSLTS", result)
      
      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      setIsSending(false)
      console.log(error);
    }
  };

  const handleCheckboxChange = (id) => {
    setItems(items.map(item => {
        if(item._id === id){
          item.synced = !item.synced
          return {...item}
        }else{
          return item
        }
      }
    ));
  };
  
  return (
    <SharedModal
      className={modalClasses.modal}
      show={isOpen}
      setShow={setIsOpen}
    >
      <div className={modalClasses.container}>
        <p className={modalClasses.header}>
          {isEdit ? "Edit" : "Add"} {overview ? "LP Lines" :  "MT5 Login"}
        </p>
        <div className={modalClasses.inputContainer}>
          <p className={modalClasses.label}>Account Name</p>
          <input type="text" className={modalClasses.input} value={accountName} onChange={(e) => setAccountName(e.target.value)}/>
        </div>
        <div className={modalClasses.inputContainer}>
          <p className={modalClasses.label}>Account ID</p>
          <input type="text" className={modalClasses.input} value={accountID} onChange={(e) => setAccountID(e.target.value)} />
        </div>
        {overview &&
          <div className={classes.disWrapper}>
            <div className="row justify-content-end mb-3">
              <div className={`col-4 d-flex justify-content-center ${classes.disContainer}`}><h5 className="m-0">Distribution</h5></div>
              <div  onClick={showMt5Sync}
              className={`${classes.actionContainer} col-4 d-flex justify-content-end`}>
            <svg
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.49997 12.1833C8.03052 12.1833 7.64997 11.8028 7.64997 11.3333V9.35H5.66663C5.19719 9.35 4.81663 8.96944 4.81663 8.5C4.81663 8.03056 5.19719 7.65 5.66663 7.65H7.64997V5.66667C7.64997 5.19723 8.03052 4.81667 8.49997 4.81667C8.96941 4.81667 9.34997 5.19723 9.34997 5.66667V7.65H11.3333C11.8027 7.65 12.1833 8.03056 12.1833 8.5C12.1833 8.96944 11.8027 9.35 11.3333 9.35H9.34997V11.3333C9.34997 11.8028 8.96941 12.1833 8.49997 12.1833Z"
                  fill="#0077B5"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0 8.5C0 3.80558 3.80558 0 8.5 0C13.1944 0 17 3.80558 17 8.5C17 13.1944 13.1944 17 8.5 17C3.80558 17 0 13.1944 0 8.5ZM8.5 1.7C4.74446 1.7 1.7 4.74446 1.7 8.5C1.7 12.2555 4.74446 15.3 8.5 15.3C12.2555 15.3 15.3 12.2555 15.3 8.5C15.3 4.74446 12.2555 1.7 8.5 1.7Z"
                  fill="#0077B5"
                />
              </svg>
              <p className={classes.actionText}>Add Account</p>
          </div></div>
          {items.map((item, i) => (<div key={i}>
              <div class={`${classes.row} ${classes.first} d-flex gap-2`}><div className={`${classes.checkboxContainerArounded} `} key={i}>
                <input
                  className={classes.radioInput}
                  type="checkbox"
                  checked={item.synced}
                  onChange={() => handleCheckboxChange(item._id)}
                />
                <div className={`${classes.radioLabel} ms-2`}></div>
              </div>{item?.account?.accountName}</div>
            </div>))}
          
        </div>
        }

        {!overview && <>
        <div className={modalClasses.group}>
          <div className={modalClasses.inputContainer}>
            <p className={modalClasses.label}>Master Password</p>
            <input className={modalClasses.input} type="text" value={masterPassword} onChange={(e) => setMasterPassword(e.target.value)}/>
          </div>
          <div className={modalClasses.inputContainer}>
            <p className={modalClasses.label}>Investor Password</p>
            <input className={modalClasses.input} type="text" value={investorPassword} onChange={(e) => setInvestorPassword(e.target.value)}/>
          </div>
        </div>
        <div className={modalClasses.inputContainer}>
          <p className={modalClasses.label}>Server</p>
          <input
            type="text"
            className={`${modalClasses.input} ${modalClasses.server_input}`}
            value={"TCBridge-Live"}
          />
        </div>

        <div className={modalClasses.inputContainer}>
          <p className={modalClasses.label}>API id</p>
          <input
            type="text"
            className={`${modalClasses.input}`}
            value={apiId}
            onChange={(e) => setApiId(e.target.value)}
          />
        </div>

        {addCashAccount && <>
          <div className={modalClasses.inputContainer}>
          <p className={modalClasses.label}>Deposit</p>
          <input
            type="text"
            className={`${modalClasses.input}`}
            value={depositCorrection}
            onChange={(e) => setDepositCorrection(e.target.value)}
          />
        </div>
        <div className={modalClasses.inputContainer}>
          <p className={modalClasses.label}>Profit (Realized) Correction</p>
          <input
            type="text"
            className={`${modalClasses.input}`}
            value={realizedCorrection}
            onChange={(e) => setRealizedCorrection(e.target.value)}
          />
        </div>
        <div className={modalClasses.inputContainer}>
          <p className={modalClasses.label}>Profit (Cumulative) Correction</p>
          <input
            type="text"
            className={`${modalClasses.input}`}
            value={cumulativeCorrection}
            onChange={(e) => setCumulativeCorrection(e.target.value)}
          />
        </div>
        <label class={`${classes.check_cont}`}>
                <input type="checkbox" checked={cashAccountOn}
        onChange={(e) => setCashAccountOn(!cashAccountOn)}/>
          <span className={classes.checkmark}></span>
          <p>Cash Account</p>
        </label>
        </>}


        {management && <div className={modalClasses.inputContainer}>
          <p className={modalClasses.label}>Assign LP Account</p>
          <div className={`${modalClasses.input}`}>
            <div className="d-flex justify-content-between align-items-center" onClick={() => setDropDown(!dropDown)} role="button">
              <div className="col">
              {selectedOption ? selectedOption.accountName : <span className={classes.placeholder}>Select Account</span>}
              </div>
              <DropDownIcon dropDown={dropDown} />
            </div>
            {dropDown && <div className="mt-2">
              {lpAccounts.map((item, i) => (<div className={`${classes.checkboxContainer} d-flex justify-content-center align-items-center gap-2`} key={i}>
                {selectedOption !== item && <div className={`${classes.radioLabel} `} onClick={() => {setSelectedOption(item); setDropDown(false)}} role="button">{item.accountName}</div>}
              </div>))}
            </div>}
          </div>
        </div>}
        </>}

        <div className={classes.btnContainer}>
        {!isSending && (<button className={modalClasses.confirm} onClick={() => { if(isEdit){ if(overview){addDisAccountHandler()}else{updateAccount()} }else{addAccountHandler()}}}>
            {isEdit ? "Save" : "Sync"}
          </button>)}
          {isSending && (
          <div className="spinner-border" role="status">
          </div>
        )}
        </div>
        
      </div>
    </SharedModal>
  );
};

export default AddNewAccountModal;
