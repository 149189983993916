import React, { useEffect, useState } from "react";
import classes from "./styles/deposit.module.css";
import tableClasses from "../shared/styles/customTable.module.css";
import StatusRequestItem from "./StatusRequestItem";
import { NavLink } from "react-router-dom";
import { env } from "../../constants/env";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllTransferRequests } from "../../Redux/requests-actions";

const TransferPage = () => {

  // const [withdrawalRequests, setWithdrawalRequests] = useState([])

  const dispatch = useDispatch()

  const transferRequests = useSelector(state => state.requests.allTransferRequests)

  useEffect(() => {
    dispatch(fetchAllTransferRequests())
  }, [])


  // useEffect(() => {
  //   getWithdrawalRequests()
  // },[])


  // const getWithdrawalRequests = async () => {
  //   const sendRequest = async () => {
  //     const response = await fetch(`${env.primeBridgeUrl}/api/transaction/withdrawal`, {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     });

  //     if (!response.ok) {
  //       const error = await response.json();
  //       console.log("ERROR RESPONSE", error?.error)
  //       throw new Error("sending plays failed.");
  //     }

  //     const result = await response.json();
  //     setWithdrawalRequests(result)
  //     console.log("UNSOLVED", result);

  //     return result;
  //   };

  //   try {
  //     await sendRequest();
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  return (
    <div className={classes.container}>
      <div className={classes.navContainer}>
        <NavLink
          style={{ textDecoration: "none" }}
          to={"/financial-management/funds"}
        >
          <p className={classes.headerSection}>Funds</p>
        </NavLink>

        <div className={classes.actionsContainer}></div>
      </div>

      <div className={classes.tableContainer}>
        <div className={classes.headerSection}>
          <p className={classes.header}>Transfer Requests</p>
        </div>

        <table style={{ width: "100%" }} class={tableClasses.container}>
          <thead class="bg-gray-50">
            <tr>
              <th
                scope="col"
                class={`${tableClasses.header} ${tableClasses.first}`}
              >
                Status
              </th>
              <th scope="col" class={tableClasses.header}>
                Request Details
              </th>
              <th scope="col" class={tableClasses.header}>
                Company Name
              </th>
              <th scope="col" class={tableClasses.header}>
              Source
              </th>
              <th scope="col" class={tableClasses.header}>
              Destination
              </th>
              <th scope="col" class={tableClasses.header}>
                Total Amount
              </th>
            </tr>
          </thead>
          <tbody class={tableClasses.body}>
          {transferRequests.map((item, i) => (
              <StatusRequestItem isTransfer={true}  key={i} request={item}/>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TransferPage;
