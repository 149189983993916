import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import SharedModal from "../shared/Modal";
import classes from "./styles/withdrawal-modal.module.css";
import modalClasses from "../Dashboard/edit-company.module.css";
import { env } from "../../constants/env";

const EditInvoiceModal = ({ isOpen, setIsOpen, updateList, companyItem, list }) => {
  const [company, setCompany] = useState("");
  const [item, setItem] = useState("");
  const [date, setDate] = useState("");
  const [amount, setAmount] = useState("");
  const [companiesList, setCompaniesList] = useState([]);
  const [fullName, setFullName] = useState("")
  const [email, setEmail] = useState()
  const [isSending, setIsSending] = useState()

 

  
  useEffect(() => {
    // getCompanies()
    getCompanyDirector(companyItem?.user)
    setAmount(list?.amount);
    setItem(list?.item);
    const today = new Date(list?.date);
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    var hours = today.getHours();
    var minutes = today.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    const formattedToday =  dd + '/' + mm + '/' +  yyyy + ', ' + hours + ':' + minutes + ':00 ' + ampm;
    setDate(formattedToday);
  }, [])

  useEffect(() => {
    getCompany(company)
  }, [company])





  const  getCompany = async (companyId) => {
    const sendRequest = async () => {
      const response = await fetch(`${env.primeBridgeUrl}/api/company/${companyId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("sending plays failed.");
      }

      const result = await response.json();
      
      getCompanyDirector(companyItem?.user)

      console.log("Single Comapny data", result);

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };

  const getCompanies = async () => {
    const sendRequest = async () => {
      const response = await fetch(`${env.primeBridgeUrl}/api/company`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("sending plays failed.");
      }

      const result = await response.json();

      setCompaniesList(result)

      console.log("Company data", result);

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };


  const  getCompanyDirector = async (id) => {
    const sendRequest = async () => {
      const response = await fetch(`${env.primeBridgeUrl}/api/auth/user/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("sending plays failed.");
      }

      const result = await response.json();
      
      setFullName(`${result.firstName} ${result.lastName}`)
      setEmail(result.email)
      console.log("Director data", result);

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };

  const  editInvoice = async () => {
    let dataContent = { date, item, amount}

    console.log("CONTENT BEFORE YOU SEND", dataContent)

    const sendRequest = async () => {
      setIsSending(true)
      const response = await fetch(`${env.primeBridgeUrl}/api/service-fee/company/update/${list._id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataContent)
      });

      if (!response.ok) {
        setIsSending(false)
        const err = await response.json();
        toast.error(err.error)
        console.log(err)
        throw new Error("creating invoice failed.");
      }

      const result = await response.json();
      setIsSending(false)
      console.log("Invoice data", result);
      toast.success("Invoice updated successfully")
      updateList()
      // handleClear()
      setIsOpen(false)

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      setIsSending(false)
      console.log(error);
    }
  };

  return (
    <SharedModal className={classes.modal} show={isOpen} setShow={setIsOpen}>
      <div className={classes.body}>
        <div className={classes.headerSection}>
          <p className={classes.header}>Edit Invoice</p>
        </div>

        <div>
        {companyItem && <>
          <div className={`${classes.group} ${classes.selectContainer}`}>
            <div className={classes.content}>
              <div style={{ width: "100%" }} className={classes.reasonSelect}>
                {/* <select
                  onChange={(e) => {console.log("VALUEEEE", e.target.value); setCompany(e.target.value)}}
                  name=""
                  id=""
                >
                  <option value="">Select company</option>
                  {companiesList.map((item, i) => (<option value={item._id} key={i}>{item.companyName}</option>))}
                </select> */}

                {companyItem?.companyName}
              </div>
            </div>
          </div>
            <div className={classes.group}>
              <div className={classes.groupItem}>
                <p className={classes.label}>Director</p>

                <p className={classes.value}>{fullName}</p>
              </div>

              <div className={classes.groupItem}>
                <p className={classes.label}>Email</p>

                <p className={classes.value}>{email}</p>
              </div>
            </div>
            </>}
          <div
            // style={{
            //   borderTop: "solid 1px #C4C4C4",
            //   paddingTop: "1rem",
            //   marginTop: "1rem",
            // }}
            className={modalClasses.group}
          >
            <div className={modalClasses.inputContainer}>
              <p className={modalClasses.label}>Date</p>
              <input
                value={date}
                onChange={(e) => setDate(e.target.value)}
                className={modalClasses.input}
                type="datetime-local"
                id="datePicker"
              />
            </div>
            <div className={modalClasses.inputContainer}>
              <p className={modalClasses.label}>Item</p>
              <input
                value={item}
                onChange={(e) => setItem(e.target.value)}
                className={modalClasses.input}
                type="text"
              />
            </div>
          </div>
          <div className={modalClasses.inputContainer}>
            <p className={modalClasses.label}>Amount</p>
            <input
              type="text"
              className={`${modalClasses.input} ${modalClasses.server_input}`}
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
          </div>
        </div>

        <div>
        {!isSending && (<button onClick={editInvoice} className={classes.actionBtn}>
            Save
          </button>)}
          {isSending && (<div className="d-flex justify-content-center align-items-center">
          <div class="spinner-border" role="status">
          </div>
          </div>
        )}
        </div>
      </div>
    </SharedModal>
  );
};

export default EditInvoiceModal;
