import React, { useEffect, useState } from "react";
import SharedModal from "../shared/Modal";
import classes from "./remove.module.css";
import modalClasses from "./edit-company.module.css";
import { env } from "../../constants/env";
import { useDispatch } from "react-redux";
import { fetchAllAccounts } from "../../Redux/accounts-actions";

const NOPLimitModal = ({ isOpen, setIsOpen, isEdit, companyId, accountId, overview }) => {

  const dispatch = useDispatch()
  const [isSending, setIsSending] = useState(false)
  const [nopLimit, setNopLimit] = useState()

  useEffect(() => {
    fetchSingleCompany(companyId)
  },[companyId])


  const fetchSingleCompany =  async(id) => {
    try {
        const response = await fetch(
          `${env.primeBridgeUrl}/api/company/${id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
  
        if (!response.ok) {
          throw new Error("fetching company failed.");
        }
  
        const result = await response.json();

        console.log("SINGLE COMPANY", result)
        setNopLimit(result.nopLimit)
  
        return result;
  
      } catch (error) {
        console.log(error);
      }
  };

  const updateNOPLimit =  async() => {
    try {
       setIsSending(true)
        const response = await fetch(
          `${env.primeBridgeUrl}/api/company/update-noplimit/${companyId}`,
          {
            method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({nopLimit})
          }
        );
  
        if (!response.ok) {
          setIsSending(false)
          throw new Error("fetching company failed.");
        }
  
        const result = await response.json();

        console.log("NO UPDATE RESULTS", result)

        setIsSending(false)
        return result;
        
  
      } catch (error) {
        setIsSending(false)
        console.log(error);
      }
  };

  
  return (
    <SharedModal
      className={modalClasses.modal}
      show={isOpen}
      setShow={setIsOpen}
    >
      <div className={modalClasses.container}>
        <p className={modalClasses.header}>
        NOP Limit
        </p>
        <div className={modalClasses.inputContainer}>
          <input type="text" className={modalClasses.input} value={nopLimit} onChange={(e) => setNopLimit(e.target.value)}/>
        </div>
        

        <div className={classes.btnContainer}>
        {!isSending && (<button className={modalClasses.confirm} onClick={updateNOPLimit}>
            Save
          </button>)}
          {isSending && (
          <div className="spinner-border" role="status">
          </div>
        )}
        </div>
      </div>
    </SharedModal>
  );
};

export default NOPLimitModal;
