import { createSlice } from '@reduxjs/toolkit';

const leadsSlice = createSlice({
  name: 'leads',
  initialState: {
    pendingLeads: [],
    allLeads: []
  },
  reducers: {

    getLeads (state, action) {
      state.pendingLeads = action.payload.pendingLeads
    },
    getAllLeads (state, action) {
      state.allLeads = action.payload.allLeads
    },
  }
});

export const leadsActions = leadsSlice.actions;

export default leadsSlice;
