import { useEffect, useState } from "react";
import { env } from "../../constants/env";
import ViolationItem from "./ViolationItem";
import ReactPaginate from "react-paginate";

// const violationList = [
//   {
//     accountId: '123456',
//     amount: '-15,910.17',
//     percentage: '12.9%'
//   },
//   {
//     accountId: '123456',
//     amount: '-202.29',
//     percentage: '15%'
//   },
//   {
//     accountId: '123456',
//     amount: '-103.99',
//     percentage: '23.2%'
//   },
//   {
//     accountId: '123456',
//     amount: '-3,119.33',
//     percentage: '10.4%'
//   },
//   {
//     accountId: '123456',
//     amount: '-100.75',
//     percentage: '17.6%'
//   }
// ]

const ViolationSection = ({ classes }) => {
  const [violationList, setViolationList] = useState([]);
  const [perPage, setPerpage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [offset, setOffset] = useState(0);
  const [pageCount, setPageCount] = useState();

  const [totalAccounts, setTotalAccounts] = useState();

  const [reload, setReload] = useState(false)

  useEffect(() => {
    getViolations(currentPage + 1);
  }, [reload]);

  useEffect(() => {
    const numberOfPage = Math.ceil(totalAccounts/perPage)
    setPageCount(numberOfPage)
  },[totalAccounts])

  useEffect(() => {
    getViolations(currentPage + 1);
  }, [currentPage]);

  const getViolations = async (page) => {
    const sendRequest = async () => {
      const response = await fetch(`${env.pbTrackingUrl}/api/violation?page=${page}&results=10`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        const result = await response.json();

        console.log("ACCOUNT VIOLATION ERROR :)", result);

        throw new Error("Getting account failed.");
      }

      const result = await response.json();

      console.log("ACCOUNT VIOLATION :)", result);

      setViolationList(result.selPrice);
      setTotalAccounts(result?.total?.total)

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * perPage;
  
    setCurrentPage(selectedPage)
    setOffset(offset)
  };

  return (
    <div className={`${classes.tableContainer} mt-5`}>
      <div className={classes.headerSection}>
        <p className={classes.title}>Violated Stop Out</p>
      </div>
      <hr />

      <div className="row">
        <div className="col-6">
          <table style={{ width: "100%" }} class={classes.container}>
            <thead class="bg-gray-50">
              <tr>
                <th scope="col" class={`${classes.header} ${classes.first}`}>
                  Account ID
                </th>
                <th scope="col" class={classes.header}>
                  Amount
                </th>
                <th scope="col" class={classes.header}>
                  Percentage
                </th>
                <th scope="col" class={classes.header}></th>
              </tr>
            </thead>
            <tbody class={classes.body}>
              {violationList?.slice(0, 5)?.map((item, index) => (
                <ViolationItem key={index} item={item} setReload={setReload} reload={reload}/>
              ))}
            </tbody>
          </table>
        </div>
        <div className="col-6">
          <table style={{ width: "100%" }} class={classes.container}>
            <thead class="bg-gray-50">
              <tr>
                <th scope="col" class={`${classes.header} ${classes.first}`}>
                  Account ID
                </th>
                <th scope="col" class={classes.header}>
                  Amount
                </th>
                <th scope="col" class={classes.header}>
                  Percentage
                </th>
                <th scope="col" class={classes.header}></th>
              </tr>
            </thead>
            <tbody class={classes.body}>
              {violationList?.slice(5)?.map((item, index) => (
                <ViolationItem key={index} item={item} setReload={setReload} reload={reload}/>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <ReactPaginate
            previousLabel={"prev"}
            nextLabel={"next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={1}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}/>
    </div>
  );
};

export default ViolationSection;
