import React, { useState } from "react";
import SharedModal from "../shared/Modal";
import classes from "./remove.module.css";
import modalClasses from "./edit-company.module.css";
import { env } from "../../constants/env";
import { useDispatch } from "react-redux";
import { fetchCompanies } from "../../Redux/company-actions";

const NewCompanyModal = ({ isOpen, setIsOpen, setDone, loginCredentails }) => {
  const dispatch = useDispatch()
  const [password, setPassword] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [directorName, setDirectorName] = useState("");
  const [email, setEmail] = useState("");
  const [depositeSettings, setDepositeSettings] = useState("");
  const [withdrawalSettings, setWithdrawalSettings] = useState("");
  const [pbShareSettings, setPBShareSettings] = useState("");
  const [isSending, setIsSending] = useState(false)

  function genPassword() {
    var chars =
      "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var passwordLength = 12;
    var password = "";
    for (var i = 0; i <= passwordLength; i++) {
      var randomNumber = Math.floor(Math.random() * chars.length);
      password += chars.substring(randomNumber, randomNumber + 1);
    }
    setPassword(password.trim());
  }

  const userloginHandler = async () => {
    let dataContent = { companyName: companyName, email: email?.trim(), director: directorName,
      depositSettings: depositeSettings, withdrawalSettings: withdrawalSettings, pbShareSettings: pbShareSettings, password: password}
    const sendRequest = async () => {
      setIsSending(true)
      const response = await fetch(`${env.primeBridgeUrl}/api/company`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataContent)
      });

      if (!response.ok) {
        setIsSending(false)
        const error = await response.json()
        console.log("ERROR", error)
        throw new Error("sending plays failed.");
      }

      const result = await response.json();
      loginCredentails(email?.trim(), password, result?.director)
      setIsSending(false)
      setDone(true);
      setIsOpen(false);


      dispatch(fetchCompanies())
      console.log("NEW COMPANYYYY", result);

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      setIsSending(false)
      console.log(error);
    }
  };
  return (
    <SharedModal
      className={modalClasses.modal}
      show={isOpen}
      setShow={setIsOpen}
    >
      <div className={modalClasses.container}>
        <p className={modalClasses.header}>Add New Company</p>

        <div className={modalClasses.inputContainer}>
            <p className={modalClasses.label}>Company Name</p>
            <input className={modalClasses.input} type="text" onChange={(e) => setCompanyName(e.target.value)}/>
          </div>

        <div className={modalClasses.group}>
          <div className={modalClasses.inputContainer}>
            <p className={modalClasses.label}>Director</p>
            <input className={modalClasses.input} type="text" onChange={(e) => setDirectorName(e.target.value)}/>
          </div>
          <div className={modalClasses.inputContainer}>
            <p className={modalClasses.label}>Email</p>
            <input className={modalClasses.input} type="text" onChange={(e) => setEmail(e.target.value)}/>
          </div>
        </div>

        <div className={modalClasses.group}>
          <div className={modalClasses.inputContainer}>
            <p className={modalClasses.label}>Deposit Settings</p>
            <div className={modalClasses.selectContainer}>
              <select required onChange={(e) => setDepositeSettings(e.target.value)}>
                <option value="" disabled selected hidden>
                  Select%
                </option>
                <option value="0">0%</option>
                <option value="0.05">5%</option>
                <option value="0.10">10%</option>
                <option value="0.15">15%</option>
              </select>
              <svg
                width="13"
                height="8"
                viewBox="0 0 13 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.7168 2.10938L6.68885 6.03617C6.49112 6.22893 6.17457 6.2249 5.9818 6.02717L2.05501 1.99923"
                  stroke="black"
                  stroke-width="3"
                  stroke-linecap="round"
                />
              </svg>
            </div>
          </div>
          <div className={modalClasses.inputContainer}>
            <p className={modalClasses.label}>Withdrawal Fee Settings</p>
            <div className={modalClasses.selectContainer}>
              <select required onChange={(e) => setWithdrawalSettings(e.target.value)}>
                <option value="" disabled selected hidden>
                  Select%
                </option>
                <option value="0">0%</option>
                <option value="0.01">1%</option>
                <option value="0.02">2%</option>
              </select>
              <svg
                width="13"
                height="8"
                viewBox="0 0 13 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.7168 2.10938L6.68885 6.03617C6.49112 6.22893 6.17457 6.2249 5.9818 6.02717L2.05501 1.99923"
                  stroke="black"
                  stroke-width="3"
                  stroke-linecap="round"
                />
              </svg>
            </div>
          </div>
        </div>
        <div className={modalClasses.inputContainer}>
          <p className={modalClasses.label}>Password</p>
          <div className={modalClasses.passwordContainer}>
            <input
              value={password}
              onChange={(e) => setPassword(e.target.value.trim())}
              type="text"
              className={modalClasses.input}
            />
            <button onClick={genPassword}>Generate</button>
          </div>
        </div>

        <div className={classes.btnContainer}>
        {!isSending && (<button
            onClick={() => {
              userloginHandler()
            }}
            className={modalClasses.confirm}
          >
            Create
          </button>)}

        {isSending && (
          <div className="spinner-border" role="status">
          </div>
        )}
        </div>
      </div>
    </SharedModal>
  );
};

export default NewCompanyModal;
