import React, { useEffect } from "react";
import MainLayout from "../../components/Layout/MainLayout";
import TransferPage from "../../components/FinancialManagement/TransferPage";

const Transfer = () => {
  useEffect(() => {
    document.title = "Transfer Requests - Prime Bridge Admin";
  }, []);
  return (
    <MainLayout>
      <TransferPage />
    </MainLayout>
  );
};

export default Transfer;
