import LinkIcon from "../icons/LinkIcon";


const DetailItem = ({icon=false, keyItem, value}) => {
  
  return (
      <div className="d-flex align-items-center mb-3">
        <p><strong>{keyItem}</strong> {value} </p>{icon && <LinkIcon onClick={()=> window.open(`https://tracking.primebridge.io?id=${value}`, "_blank")}/>}
      </div>
  );
};

export default DetailItem;
