import { Toaster } from "react-hot-toast";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
// import AuthPage from "./pages/AuthPage";
import CompanyIndex from "./pages/company-management/Index";
import FinancialIndex from "./pages/financial-management/Index";
import CompanyDetail from "./pages/company-management/CompanyDetail";
import EditCompanyMember from "./components/Dashboard/EditCompanyMember";
import Withdrawal from "./pages/financial-management/Withdrawal";
import Deposit from "./pages/financial-management/Deposit";
import ServiceFee from "./pages/financial-management/ServiceFee";
import LeadsPage from "./pages/leads/IndexPage";
import CompliancePage from "./pages/compliance/IndexPage";
import AllLeadsPage from "./pages/leads/AllLeads";
import Teams from "./pages/teams/IndexPage";
import EditTeamPage from "./pages/teams/EditTeamPage";
import AddTeamPage from "./pages/teams/AddTeamPage";
import CustomerPage from "./pages/customer-page/index";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import PasswordReset from "./pages/PasswordReset/PasswordReset";
import AuthPage from "./pages/AuthPage/AuthPage";
import { useEffect } from "react";
import { getCookie } from "./utilities/functions";
import RiskManagement from "./pages/risk-management/RiskManagement";
import Transfer from "./pages/financial-management/Transfer";
import Accounts from "./pages/accounts/Accounts";

function App() {

  const authToken = getCookie('authToken')

  useEffect(() => {
    if(!authToken){
      if(window.location.pathname === "/auth"  ||  window.location.pathname === "/forgot-password"  || window.location.href.indexOf("/password-reset/") > -1){
        return
      }else{
        window.location = '/auth';
      }
    }
  }, [])

  const router = createBrowserRouter([
    {
      path: "/auth",
      element: <AuthPage />,
    },
    { 
      path:"/forgot-password", 
      element:<ForgotPassword />
    },
    { 
      path:"/password-reset/:userId/:token",
      element:<PasswordReset />
    },
    {
      path: "/",
      element: <CompanyIndex />,
    },
    {
      path: "/company/:id",
      element: <CompanyDetail />,
    },
    {
      path: "/company/edit-member/:id/:companyId",
      element: <EditCompanyMember />,
    },
    {
      path: "/financial-management/funds",
      element: <FinancialIndex />,
    },
    {
      path: "/financial-management/funds/withdrawal",
      element: <Withdrawal />,
    },
    {
      path: "/financial-management/funds/transfer",
      element: <Transfer />,
    },
    {
      path: "/financial-management/funds/deposit",
      element: <Deposit />,
    },
    {
      path: "/financial-management/service-fees",
      element: <ServiceFee />,
    },
    {
      path: "/risk-management",
      element: <RiskManagement />,
    },
    {
      path: "/risk-management/accounts",
      element: <Accounts />,
    },
    {
      path: "/leads",
      element: <LeadsPage />,
    },
    {
      path: "/leads/all",
      element: <AllLeadsPage />,
    },
    {
      path: "/compliance",
      element: <CompliancePage />,
    },
    {
      path: "/team",
      element: <Teams />,
    },
    {
      path: "/team/edit/:id",
      element: <EditTeamPage />,
    },
    {
      path: "/team/add",
      element: <AddTeamPage />,
    },
    {
      path: "/customer-support",
      element: <CustomerPage />,
    },
    // {
    //   path: "/profile",
    //   element: <Index />,
    // },
  ]);
  return (
    <>
      <Toaster />
      <RouterProvider router={router} />
    </>
  );
}

export default App;
