import React, { useState } from "react";
import tableClasses from "../shared/styles/customTable.module.css";
import LeadDetailsModal from "./LeadDetailModal";
const LeadItem = ({lead}) => {
  const [isOpen, setIsOpen] = useState(false);
  
  const dateToYMD = date => {
    const formatedDate = date?.split('T')[0]
    console.log("DATE", formatedDate)
    var newDate = new Date(formatedDate)
    var today = new Date()
    var strArray = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ]
    var days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    var d = newDate.getDate()
    var dayName = days[newDate.getDay()]
    var m = strArray[newDate.getMonth()]
    var y = newDate.getFullYear()

    
    return '' + m + ' ' + (d <= 9 ? '0' + d : d) + ', ' + y
  }
  return (
    <tr>
      <td class={`${tableClasses.row} ${tableClasses.first}`}>{dateToYMD(lead?.createdAt)}</td>
      <td class={tableClasses.row}>{lead?.companyName}</td>
      <td class={tableClasses.row}>{lead ? `${lead?.firstName} ${lead?.lastName}` : ""}</td>
      <td class={tableClasses.row}>{lead?.email}</td>
      <td class={tableClasses.row}>
        <div style={{ gap: "3rem" }} className={tableClasses.flexItem}>
          <a href="#" target={"_blank"}>
            {lead?.website}
          </a>
          <div>
            <svg
              width="20"
              height="20"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => setIsOpen(true)}
            >
              <path
                d="M16 12.5H12.5M12.5 12.5H9M12.5 12.5V9M12.5 12.5V16M18.3333 23H6.66667C4.08934 23 2 20.9107 2 18.3333V6.66667C2 4.08934 4.08934 2 6.66667 2H18.3333C20.9107 2 23 4.08934 23 6.66667V18.3333C23 20.9107 20.9107 23 18.3333 23Z"
                stroke="#0077B5"
                stroke-width="3"
                stroke-linecap="round"
              />
            </svg>
          </div>
        </div>
      </td>
      <LeadDetailsModal isNewRequest isOpen={isOpen} setIsOpen={setIsOpen} lead={lead}/>
    </tr>
  );
};

export default LeadItem;
