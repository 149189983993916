import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { env } from "../../constants/env";
import AccountItem from "./AccountItem";
import AddNewAccountModal from "./AddNewAccount";
import CompliantItem from "./CompliantItem";
import DepositSettings from "./DepositSettings";
import classes from "./detailpage.module.css";
import EditCompanyModal from "./EditCompanyModal";
import TeamItem from "./TeamItem";
import WithdrawalFeeSetting from "./WithdrawalFeeSetting";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchAllAccounts,
  fetchAllLPGroups,
  fetchAllManagementAccounts,
  fetchAllOverviewAccounts,
} from "../../Redux/accounts-actions";

import { fetchCompanyUsers } from "../../Redux/users-actions";
import { fetchDocuments } from "../../Redux/documents-actions";
import UploadDocumentModal from "./UploadDocument";
import PBShareSettings from "./PBShareSettings";
import CustodyProvider from "./CustodyProvider";
import NOPLimitModal from "./NOPLimitModal";
import AddNewGroupModal from "./AddNewGroup";
import AddMt4AccountModal from "./AddMt4Account ";
import ManagementAddAccountModal from "./ManagementAddAccount";

const DetailPage = ({ companyDetails, companyId, getCompany }) => {
  const dispatch = useDispatch();
  const [isCompanyEditOpen, setIsCompanyOpen] = useState(false);
  const [isAddNewAccOpen, setIsAddNewAccOpen] = useState(false);
  const [mediaFile, setMediaFile] = useState();
  const [fileName, setFileName] = useState();
  const [custodyProvider, setCustodyProvider] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [showMt5Account, setShowMt5Account] = useState(false);
  const [showOverviewAccount, setShowOverviewAccount] = useState(false);
  const [showManagementAccount, setShowManagementAccount] = useState(false);

  const [showGroupModal, setShowGroupModal] = useState(false);

  const [isRemoveOpen, setIsRemoveOpen] = useState(false);

  const accountsDetails = useSelector((state) => state.accounts.allAccounts);

  const overviewAccountsDetails = useSelector(
    (state) => state.accounts.allOverviewAccounts
  );

  const managementAccountsDetails = useSelector(
    (state) => state.accounts.allManagementAccounts
  );

  const teamMembers = useSelector((state) => state.users.companyUsers);

  const lpGroups = useSelector((state) => state.accounts.lpGroups);

  const documents = useSelector((state) => state.documents.companyFiles);

  // console.log('FILES MEDIA: ', lpGroups)

  useEffect(() => {
    dispatch(fetchCompanyUsers(companyId));
    dispatch(fetchAllAccounts(companyId));
    dispatch(fetchAllOverviewAccounts(companyId));
    dispatch(fetchAllManagementAccounts(companyId));

    dispatch(fetchAllLPGroups(companyId));

    dispatch(fetchDocuments(companyId));
  }, []);

  const handleChange = (e) => {
    console.log("FILES: ", e.target.value);

    if (e.target.files) {
      setIsRemoveOpen(true);
      var reader = new FileReader();

      reader.readAsDataURL(e.target.files[0]);

      setMediaFile(e.target.files[0]);
    }
  };

  useEffect(() => {
    fetchCustodyProvider();
  }, []);

  const fetchCustodyProvider = async () => {
    try {
      const response = await fetch(
        `${env.primeBridgeUrl}/api/custody-provider/company/${companyId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("fetching company failed.");
      }

      const result = await response.json();

      console.log("CUSTODY PROVIDER", result);
      setCustodyProvider(result);

      return result;
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className={classes.container}>
      <div className={classes.navContainer}>
        <p className={classes.headerSection}>{companyDetails?.companyName}</p>

        <div className={classes.actionsContainer}>
          <NavLink style={{ color: "#BD3437" }} to={"/"}>
            <p>Back</p>
          </NavLink>
        </div>
      </div>

      <div>
        <div>
          <EditCompanyModal
            setIsOpen={setIsCompanyOpen}
            isOpen={isCompanyEditOpen}
            companyDetails={companyDetails}
            companyId={companyId}
          />
          <div className={classes.headerContainer}>
            <p className={classes.headerText}>Company Information</p>

            <div
              onClick={() => setIsCompanyOpen(true)}
              className={classes.actionContainer}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9.35082 1.01441C10.4634 -0.0981162 12.2671 -0.098116 13.3796 1.01441L14.1854 1.82018C15.2979 2.93271 15.2979 4.73648 14.1854 5.84901L4.91911 15.1153L1.22404 15.1153C0.594698 15.1153 0.0845136 14.6051 0.0845156 13.9758L0.0845146 10.2807L9.35082 1.01441ZM11.7681 2.62595L12.5739 3.43171C12.7964 3.65422 12.7964 4.01497 12.5739 4.23748L11.3652 5.44613L9.7537 3.83459L10.9624 2.62595C11.1849 2.40344 11.5456 2.40344 11.7681 2.62595ZM9.7537 7.05766L3.9751 12.8363L2.36356 12.8363L2.36356 11.2247L8.14217 5.44613L9.7537 7.05766Z"
                  fill="#0077B5"
                />
              </svg>
              <p className={classes.actionText}>Edit</p>
            </div>
          </div>
          <div className={classes.compInfo}>
            <div className={`${classes.compInfoItem} ${classes.first}`}>
              <p>
                Company Name: <span>{companyDetails?.companyName}</span>
              </p>
              <p>
                Address: <span>{companyDetails?.address}</span>
              </p>
              <p>
                Country: <span>{companyDetails?.country}</span>
              </p>
            </div>
            <div className={classes.compInfoItem}>
              <p>
                Email: <span>{companyDetails?.email}</span>
              </p>
              <p>
                City: <span>{companyDetails?.city}</span>
              </p>
              <p>
                Postal Code: <span>{companyDetails?.postalCode}</span>
              </p>
            </div>
            <div className={classes.compInfoItem}>
              <p>
                Phone number: <span>{companyDetails?.phone}</span>
              </p>
              <p>
                Province: <span>{companyDetails?.province}</span>
              </p>
              <p>
                Reference Number: <span>{companyDetails?.refNumber}</span>
              </p>
            </div>
          </div>
        </div>

        <div>
          <div className={classes.headerContainer}>
            <p className={classes.headerText}>MT5 Accounts</p>
            {showMt5Account && (
              <AddNewAccountModal
                isOpen={true}
                setIsOpen={() => setShowMt5Account(false)}
                companyId={companyId}
                addCashAccount={true}
              />
            )}
            <div
              onClick={() => {
                setShowMt5Account(true);
                setShowOverviewAccount(false);
                setShowManagementAccount(false);
                setShowGroupModal(false);
              }}
              className={classes.actionContainer}
            >
              <svg
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.49997 12.1833C8.03052 12.1833 7.64997 11.8028 7.64997 11.3333V9.35H5.66663C5.19719 9.35 4.81663 8.96944 4.81663 8.5C4.81663 8.03056 5.19719 7.65 5.66663 7.65H7.64997V5.66667C7.64997 5.19723 8.03052 4.81667 8.49997 4.81667C8.96941 4.81667 9.34997 5.19723 9.34997 5.66667V7.65H11.3333C11.8027 7.65 12.1833 8.03056 12.1833 8.5C12.1833 8.96944 11.8027 9.35 11.3333 9.35H9.34997V11.3333C9.34997 11.8028 8.96941 12.1833 8.49997 12.1833Z"
                  fill="#0077B5"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0 8.5C0 3.80558 3.80558 0 8.5 0C13.1944 0 17 3.80558 17 8.5C17 13.1944 13.1944 17 8.5 17C3.80558 17 0 13.1944 0 8.5ZM8.5 1.7C4.74446 1.7 1.7 4.74446 1.7 8.5C1.7 12.2555 4.74446 15.3 8.5 15.3C12.2555 15.3 15.3 12.2555 15.3 8.5C15.3 4.74446 12.2555 1.7 8.5 1.7Z"
                  fill="#0077B5"
                />
              </svg>
              <p className={classes.actionText}>Add Account</p>
            </div>
          </div>

          <div className={classes.accountSection}>
            {accountsDetails.map((item, i) => (
              <AccountItem account={item} key={i} addCashAccount={true} />
            ))}
            {/* <AccountItem /> */}
          </div>
        </div>

        <div>
          <div className={classes.headerContainer}>
            <p className={classes.headerText}>Overview Accounts</p>
            {showOverviewAccount && (
              <AddMt4AccountModal
                isOpen={true}
                setIsOpen={() => setShowOverviewAccount(false)}
                companyId={companyId}
                overview={true}
                overviewData={overviewAccountsDetails}
              />
            )}

            <NOPLimitModal
              isOpen={isEdit}
              setIsOpen={() => setIsEdit(false)}
              companyId={companyId}
            />
            <div className="d-flex align-items-center gap-4">
              <div
                onClick={() => setIsEdit(true)}
                className={classes.actionContainer}
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => setIsEdit(true)}
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.35082 1.01441C10.4634 -0.0981163 12.2671 -0.098116 13.3796 1.01441L14.1854 1.82018C15.2979 2.93271 15.2979 4.73648 14.1854 5.84901L4.91911 15.1153L1.22404 15.1153C0.594699 15.1153 0.0845151 14.6051 0.0845156 13.9758L0.0845146 10.2807L9.35082 1.01441ZM11.7681 2.62595L12.5739 3.43171C12.7964 3.65422 12.7964 4.01497 12.5739 4.23748L11.3652 5.44613L9.7537 3.83459L10.9624 2.62595C11.1849 2.40344 11.5456 2.40344 11.7681 2.62595ZM9.7537 7.05766L3.9751 12.8363L2.36356 12.8363L2.36356 11.2247L8.14217 5.44613L9.7537 7.05766Z"
                    fill="#0077B5"
                  />
                </svg>
                <p className={classes.actionText}>NOP limit</p>
              </div>
              <div
                onClick={() => {
                  setShowMt5Account(false);
                  setShowOverviewAccount(true);
                  setShowManagementAccount(false);
                  setShowGroupModal(false);
                }}
                className={classes.actionContainer}
              >
                <svg
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.49997 12.1833C8.03052 12.1833 7.64997 11.8028 7.64997 11.3333V9.35H5.66663C5.19719 9.35 4.81663 8.96944 4.81663 8.5C4.81663 8.03056 5.19719 7.65 5.66663 7.65H7.64997V5.66667C7.64997 5.19723 8.03052 4.81667 8.49997 4.81667C8.96941 4.81667 9.34997 5.19723 9.34997 5.66667V7.65H11.3333C11.8027 7.65 12.1833 8.03056 12.1833 8.5C12.1833 8.96944 11.8027 9.35 11.3333 9.35H9.34997V11.3333C9.34997 11.8028 8.96941 12.1833 8.49997 12.1833Z"
                    fill="#0077B5"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0 8.5C0 3.80558 3.80558 0 8.5 0C13.1944 0 17 3.80558 17 8.5C17 13.1944 13.1944 17 8.5 17C3.80558 17 0 13.1944 0 8.5ZM8.5 1.7C4.74446 1.7 1.7 4.74446 1.7 8.5C1.7 12.2555 4.74446 15.3 8.5 15.3C12.2555 15.3 15.3 12.2555 15.3 8.5C15.3 4.74446 12.2555 1.7 8.5 1.7Z"
                    fill="#0077B5"
                  />
                </svg>
                <p className={classes.actionText}>Add Account</p>
              </div>
            </div>
          </div>

          <div className={classes.accountSection}>
            {overviewAccountsDetails.map((item, i) => (
              <AccountItem account={item} key={i} overview={true} />
            ))}
            {/* <AccountItem /> */}
          </div>
        </div>
        <div className="row">
        <div className="col-6">
          <div className={classes.headerContainer}>
            <p className={classes.headerText}>Assets Under Management</p>
            {showManagementAccount && (
              // <AddNewAccountModal
              //   isOpen={true}
              //   companyId={companyId}
              //   setIsOpen={() => setShowManagementAccount(false)}
              //   management={true}
              //   lpAccounts={overviewAccountsDetails}
              // />
              <ManagementAddAccountModal 
                  isOpen={true}
                  companyId={companyId}
                  setIsOpen={() => setShowManagementAccount(false)}
              />
            )}

            <div className="d-flex align-items-center gap-4">
              <div
                onClick={() => {
                  setShowMt5Account(false);
                  setShowOverviewAccount(false);
                  setShowManagementAccount(true);
                  setShowGroupModal(false);
                }}
                className={classes.actionContainer}
              >
                <svg
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.49997 12.1833C8.03052 12.1833 7.64997 11.8028 7.64997 11.3333V9.35H5.66663C5.19719 9.35 4.81663 8.96944 4.81663 8.5C4.81663 8.03056 5.19719 7.65 5.66663 7.65H7.64997V5.66667C7.64997 5.19723 8.03052 4.81667 8.49997 4.81667C8.96941 4.81667 9.34997 5.19723 9.34997 5.66667V7.65H11.3333C11.8027 7.65 12.1833 8.03056 12.1833 8.5C12.1833 8.96944 11.8027 9.35 11.3333 9.35H9.34997V11.3333C9.34997 11.8028 8.96941 12.1833 8.49997 12.1833Z"
                    fill="#0077B5"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0 8.5C0 3.80558 3.80558 0 8.5 0C13.1944 0 17 3.80558 17 8.5C17 13.1944 13.1944 17 8.5 17C3.80558 17 0 13.1944 0 8.5ZM8.5 1.7C4.74446 1.7 1.7 4.74446 1.7 8.5C1.7 12.2555 4.74446 15.3 8.5 15.3C12.2555 15.3 15.3 12.2555 15.3 8.5C15.3 4.74446 12.2555 1.7 8.5 1.7Z"
                    fill="#0077B5"
                  />
                </svg>
                <p className={classes.actionText}>Add Account</p>
              </div>
            </div>
          </div>

          <div className={classes.accountSection}>
            {managementAccountsDetails?.map((item, i) => (
              <AccountItem
                account={item}
                key={i}
                management={true}
              />
            ))}
            {/* <AccountItem /> */}
          </div>
        </div>

        <div className="col-6">
          <div className={classes.headerContainer}>
            <p className={classes.headerText}>LP Groups</p>
            {showGroupModal && (
              <AddNewGroupModal
                isOpen={true}
                companyId={companyId}
                setIsOpen={() => setShowGroupModal(false)}
                management={true}
                lpAccounts={overviewAccountsDetails}
              />
            )}

            <div className="d-flex align-items-center gap-4">
              <div
                onClick={() => {
                  setShowMt5Account(false);
                  setShowOverviewAccount(false);
                  setShowManagementAccount(false);
                  setShowGroupModal(true);
                }}
                className={classes.actionContainer}
              >
                <svg
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.49997 12.1833C8.03052 12.1833 7.64997 11.8028 7.64997 11.3333V9.35H5.66663C5.19719 9.35 4.81663 8.96944 4.81663 8.5C4.81663 8.03056 5.19719 7.65 5.66663 7.65H7.64997V5.66667C7.64997 5.19723 8.03052 4.81667 8.49997 4.81667C8.96941 4.81667 9.34997 5.19723 9.34997 5.66667V7.65H11.3333C11.8027 7.65 12.1833 8.03056 12.1833 8.5C12.1833 8.96944 11.8027 9.35 11.3333 9.35H9.34997V11.3333C9.34997 11.8028 8.96941 12.1833 8.49997 12.1833Z"
                    fill="#0077B5"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0 8.5C0 3.80558 3.80558 0 8.5 0C13.1944 0 17 3.80558 17 8.5C17 13.1944 13.1944 17 8.5 17C3.80558 17 0 13.1944 0 8.5ZM8.5 1.7C4.74446 1.7 1.7 4.74446 1.7 8.5C1.7 12.2555 4.74446 15.3 8.5 15.3C12.2555 15.3 15.3 12.2555 15.3 8.5C15.3 4.74446 12.2555 1.7 8.5 1.7Z"
                    fill="#0077B5"
                  />
                </svg>
                <p className={classes.actionText}>Add Group</p>
              </div>
            </div>
          </div>

          <div className={classes.accountSection}>
            {lpGroups.map((item, i) => (
              <AccountItem
                account={item}
                key={i}
                group={true}
                lpAccounts={overviewAccountsDetails}
              />
            ))}

            {/* <AccountItem /> */}
          </div>
        </div>

        </div>

        <div>
          <table className={classes.teamsTable}>
            <thead>
              <th className={classes.teamsHeader}>Team Member</th>
              <th className={classes.teamsHeader}>Access</th>
              <th className={classes.teamsHeader}>Email</th>
              <th className={classes.teamsHeader}></th>
            </thead>
            <tbody className={classes.body}>
              {teamMembers.map((member, i) => (
                <TeamItem
                  userDetails={member}
                  isTeam={false}
                  companyId={companyId}
                  key={i}
                />
              ))}
            </tbody>
          </table>
        </div>

        <div>
          <div className={classes.headerContainer}>
            <p className={classes.headerText}>Transaction</p>
          </div>
          <div className={classes.transaction}>
            <DepositSettings
              depositSettings={companyDetails?.depositSettings}
              companyId={companyId}
              getCompany={getCompany}
            />
            <WithdrawalFeeSetting
              withdrawalSettings={companyDetails?.withdrawalSettings}
              companyId={companyId}
              getCompany={getCompany}
            />
            {custodyProvider && (
              <CustodyProvider
                custodyProvider={custodyProvider}
                getProvider={fetchCustodyProvider}
              />
            )}
          </div>
        </div>

        <div>
          <div className={classes.headerContainer}>
            <p className={classes.headerText}>Compliance Documents</p>

            {documents.length > 0 && (
              <div
                style={{ position: "relative", cursor: "pointer" }}
                className={classes.actionContainer}
              >
                <svg
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.49997 12.1833C8.03052 12.1833 7.64997 11.8028 7.64997 11.3333V9.35H5.66663C5.19719 9.35 4.81663 8.96944 4.81663 8.5C4.81663 8.03056 5.19719 7.65 5.66663 7.65H7.64997V5.66667C7.64997 5.19723 8.03052 4.81667 8.49997 4.81667C8.96941 4.81667 9.34997 5.19723 9.34997 5.66667V7.65H11.3333C11.8027 7.65 12.1833 8.03056 12.1833 8.5C12.1833 8.96944 11.8027 9.35 11.3333 9.35H9.34997V11.3333C9.34997 11.8028 8.96941 12.1833 8.49997 12.1833Z"
                    fill="#0077B5"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0 8.5C0 3.80558 3.80558 0 8.5 0C13.1944 0 17 3.80558 17 8.5C17 13.1944 13.1944 17 8.5 17C3.80558 17 0 13.1944 0 8.5ZM8.5 1.7C4.74446 1.7 1.7 4.74446 1.7 8.5C1.7 12.2555 4.74446 15.3 8.5 15.3C12.2555 15.3 15.3 12.2555 15.3 8.5C15.3 4.74446 12.2555 1.7 8.5 1.7Z"
                    fill="#0077B5"
                  />
                </svg>
                <input
                  style={{
                    width: "100%",
                    height: "100%",
                    opacity: 0,
                    position: "absolute",
                    cursor: "pointer",
                  }}
                  type={"file"}
                  onChange={handleChange}
                />
                <p className={classes.actionText}>Add Document</p>
              </div>
            )}
          </div>

          <div className={classes.compliantContainer}>
            {documents.map((item, i) => {
              if (item.fileType && item.received) {
                return <CompliantItem file={item} companyId={companyId} />;
              }
            })}
          </div>
        </div>
      </div>
      <UploadDocumentModal
        isOpen={isRemoveOpen}
        setIsOpen={setIsRemoveOpen}
        fileName={mediaFile?.name}
        companyId={companyId}
        file={mediaFile}
      />
    </div>
  );
};

export default DetailPage;
