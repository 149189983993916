import React, { useEffect } from "react";
import styles from "./Statistics.module.css";
import StatsList from "./statsList/StatsList";
import CardContainer from "./cardContainer/CardContainer";
import TradesCard from "./tradesCard/TradesCard";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { env } from "../../../constants/env";
import { calcDuration, checkNum } from "../../../utilities/functions";


const percentage = 40;



// STATISTICS CARD
export const StatisticsCard = ({
  children,
  height,
  width,
  bottomColor,
  padding,
}) => {
  const cardStyle = {
    height: height,
    width: width,
    padding: padding,
  };
  return (
    <div style={cardStyle} className={styles.cardContainer}>
      {children}
      {bottomColor && (
        <div
          className={styles.cardBottom}
          style={{ backgroundColor: bottomColor }}
        ></div>
      )}
    </div>
  );
};

// COLORED CARD COMPONENT
const ColoredCard = ({ children, height, width, bottomColor }) => {
  const cardStyle = {
    height: height,
    width: width,
  };
  return (
    <div style={cardStyle} className={styles.gainCard}>
      <div className={styles.gainCardBody}>{children}</div>
      <div
        style={{ backgroundColor: bottomColor, height: "14px" }}
        className={styles.gainCardBottom}
      ></div>
    </div>
  );
};



// STATISTICS SECTION
const StatisticSection = ({statistics}) => {
  const dispatch = useDispatch()
  const mobile = window.innerWidth > 768 ? false : true;
  const settingsNoModules = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    dotsClass: "button__bar",
    // centerMode: true,
    arrows: false,
  };

  const slideOneData = [
    {
      title: "Profit Factor",
      num: "0.87",
    },
    {
      title: "Sharpe Ratio",
      num: "-0.13",
    },
    {
      title: "One Day Return",
      num: "0.17%",
    },
    {
      title: "Annualized Return",
      num: "-99.53%",
    },
    {
      title: "Annualized Volatility",
      num: "758%",
    },
    {
      title: "Sortino Ratio",
      num: "0.14",
    },
    {
      title: "Omega Ratio",
      num: "1.7",
    },
  ];

  // const statistics = 
  //   {
  //     gain: "Chance of Profit",
  //     absGain: 5,
  //     daily: 7,
  //     monthlyGain: 1.2,
  //     profitFactor: 3,
  //     annualizedReturn: 0.89,
  //     accountAge: -99.53,
  //     lotsTraded: 21.86,
  //     commission: 21000.80,
  //     swaps: -534.56,
  //     deposits: 20.33,
  //     withdrawals: 22.40
  //   }

  // const [statistics, setStatistics] = useState({});

  // const statistics = useSelector(state => state.trades.statistics)

  useEffect(() => {
    // fetchStatisticsData(accountNumber)
    // dispatch(fetchStatistics(accountNumber))
  },[])

  return (
    <div className="d-flex justify-content-center align-items-center">
      <div className={`${styles.statSection} `}>
        <h5 className={styles.statTitle}>Trading Account Performance</h5>
          <CardContainer>
            {statistics && <>
             <div className={`d-flex justify-content-between`}>
              <div>
                <StatsList
                  title={"Gain"}
                  num={statistics?.data?.gain ? `${Math.round(statistics?.data?.gain * 100) / 100}%` : 0}
                  firstColumn={true}
                  // underline={true}
                  // descText={<><p>Time-Weighted Return (TWR) that measures the performance of a dollar invested in the system since inception.</p>
                  
                  // <p>TWR measurement is required by the Global Investment Performance Standards published by the CFA Institute.</p> 
                  
                  // <p>Its distinguishing characteristic is that cash inflows, cash outflows and amounts invested over different time periods have no impact on the return.</p></>}
                  firstRow={true}
                />
                <StatsList
                  title={"Absolute Gain"}
                  num={statistics?.data?.absGain ? `${Math.round(statistics?.data?.absGain * 100) / 100}%` : 0}
                  // underline={true}
                  // descText={"Returns of the investment as a percentage of the total deposits. By definition, new deposits will affect the absolute gain."}
                  firstColumn={true}
                />
                <StatsList
                  title={"Daily Gain"}
                  num={statistics?.data?.daily ? `${Math.round(statistics?.data?.daily * 100) / 100}%` : 0}
                  // underline={true}
                  // descText={"Daily compound rate of return leading to the total gain."}
                  firstColumn={true}
                />
                <StatsList
                  title={"Monthly Gain"}
                  num={statistics?.data?.monthly ? `${Math.round(statistics?.data?.monthly * 100) / 100}%` : 0}
                  // underline={true}
                  // descText={"Monthly compound rate of return leading to the total gain."}
                  firstColumn={true}
                />
                {/* <StatsList
                  title={"Annualized Volatility"}
                  num={"758%"}
                  firstColumn={true}
                /> */}
              </div>
              <div className={styles.vertical}></div>
              <div>
                <StatsList
                  title={"Profit Factor"}
                  num={statistics?.data?.profitFactor ? Math.round(statistics?.data?.profitFactor * 100) / 100 : 0}
                  firstRow={true}
                />
                <StatsList title={"Annualized Return"} num={statistics?.data?.monthly ? `${Math.round((statistics?.data?.monthly * 12) * 100) / 100}%` : 0} 
                  // underline={true}
                  // descText={"Annualized Return will be calibrated after 30 calendar days"}
                  />
                <StatsList title={"Account Age"} num={statistics?.data?.firstTradeDate ? calcDuration(statistics?.data?.firstTradeDate, statistics?.data?.lastUpdateDate) : 0} />
                <StatsList title={"Lots traded"} num={statistics?.statistics?.lotsTraded ? Math.round(statistics?.statistics?.lotsTraded  * 100) / 100 : 0} />
              </div>
              <div className={styles.vertical}></div>
              <div>
                <StatsList
                  title={"Commissions"}
                  num={statistics?.data?.commission ? checkNum(Math.round(statistics?.data?.commission * 100) / 100) : 0}
                  firstRow={true}
                />
                <StatsList title={"Swaps"} num={statistics?.statistics?.swaps ? checkNum(Math.round(statistics?.statistics?.swaps  * 100) / 100) : 0} />
                <StatsList title={"Total Deposits"} num={statistics?.data?.profit ? checkNum(Math.round(statistics?.data?.deposits * 100) / 100) : 0} />
                <StatsList title={"Total Withdrawals"} num={statistics?.data?.withdrawals ? checkNum(Math.round(statistics?.data?.withdrawals * 100) / 100) : 0} />
              </div>
            </div></>}
          </CardContainer>
      </div>
    </div>
  );
};




// MAIN PAGE TO EXPORT
export default function Statistics({ active, handleChange, openModal, statistics }) {
  
  return (
    <div>
      <div style={{ height: "max-content", background: "" }}>
        <StatisticSection statistics={statistics}/>
      </div>
    </div>
  );
}
