import React, { useState } from "react";
import SharedModal from "../shared/Modal";
import classes from "./remove.module.css";
import styles from "../FinancialManagement/styles/withdrawal-modal.module.css";
import { env } from "../../constants/env";
import { useDispatch } from "react-redux";
import { fetchDocuments } from "../../Redux/documents-actions";

const UploadDocumentModal = ({ isOpen, setIsOpen, fileName, companyId, file }) => {
  const dispatch = useDispatch()
  const [isSending, setIsSending] = useState(false)
  const [selectedOption, setSelectedOption] = useState("")

  const  uploadDocumentHandler = async () => {
    const sendRequest = async () => {
      setIsSending(true)

      const formData = new FormData()
      formData.append('files', file)
      formData.append('fileType', selectedOption)
      const response = await fetch(`${env.primeBridgeUrl}/api/company/upload/${companyId}`, { 
        method: 'POST',
        body: formData
      });

      if (!response.ok) {
        const result = await response.json();
        setIsSending(false)
        console.log(result)
        throw new Error("sending plays failed.");
      }

      const result = await response.json();

      setIsOpen(false)
      setSelectedOption("")
      dispatch(fetchDocuments(companyId))
      setIsSending(false)

      console.log("Reply from plays data", result);

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      setIsSending(false)
      console.log(error);
    }
  };

  function truncateString(str, num) {
    if (str.length > num) {
      return str.slice(0, 12) + "(...)" + str.slice(-12);
    } else {
      return str;
    }
  }

  return (
    <SharedModal show={isOpen} setShow={setIsOpen}>
      <div>
        <p className={classes.header}>
          Select the type of compliance document
        </p>
        <div className="d-flex justify-content-center align-items-center py-3">
        <svg
        width="31"
        height="30"
        viewBox="0 0 31 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M29.5607 11.8243C29.2471 11.8243 28.9463 11.9489 28.7246 12.1706C28.5028 12.3924 28.3783 12.6931 28.3783 13.0067V34.2904C28.3783 34.604 28.2537 34.9048 28.0319 35.1265C27.8102 35.3483 27.5094 35.4728 27.1958 35.4728H3.54728C3.23368 35.4728 2.93293 35.3483 2.71118 35.1265C2.48943 34.9048 2.36486 34.604 2.36486 34.2904V3.54728C2.36486 3.23368 2.48943 2.93293 2.71118 2.71118C2.93293 2.48943 3.23368 2.36486 3.54728 2.36486H21.2837V8.27699C21.2837 8.59059 21.4083 8.89135 21.63 9.1131C21.8518 9.33484 22.1525 9.45942 22.4661 9.45942H29.5607C29.7956 9.46079 30.0255 9.39218 30.2212 9.26235C30.4169 9.13252 30.5695 8.94734 30.6596 8.73043C30.7497 8.51352 30.7731 8.27471 30.7269 8.04443C30.6807 7.81416 30.567 7.60286 30.4002 7.43747L23.3057 0.342904C23.095 0.145821 22.8244 0.0250792 22.5371 0C22.1823 0 21.5793 0 3.54728 0C2.60649 0 1.70422 0.37373 1.03898 1.03898C0.373731 1.70422 0 2.60648 0 3.54728V34.2904C0 35.2312 0.373731 36.1335 1.03898 36.7987C1.70422 37.464 2.60649 37.8377 3.54728 37.8377H27.1958C28.1366 37.8377 29.0389 37.464 29.7041 36.7987C30.3694 36.1335 30.7431 35.2312 30.7431 34.2904V13.0067C30.7431 12.6931 30.6185 12.3924 30.3968 12.1706C30.175 11.9489 29.8743 11.8243 29.5607 11.8243ZM23.6486 7.09457V4.03208L26.711 7.09457H23.6486Z"
          fill="#033C59"
        />
        <path
          d="M23.6485 16.5543H13.0066C12.693 16.5543 12.3923 16.4297 12.1705 16.208C11.9488 15.9862 11.8242 15.6855 11.8242 15.3719C11.8242 15.0583 11.9488 14.7575 12.1705 14.5358C12.3923 14.314 12.693 14.1895 13.0066 14.1895H23.6485C23.9621 14.1895 24.2629 14.314 24.4846 14.5358C24.7063 14.7575 24.8309 15.0583 24.8309 15.3719C24.8309 15.6855 24.7063 15.9862 24.4846 16.208C24.2629 16.4297 23.9621 16.5543 23.6485 16.5543Z"
          fill="#D0D0D0"
        />
        <path
          d="M23.6485 21.2848H7.09454C6.78094 21.2848 6.48018 21.1602 6.25843 20.9385C6.03669 20.7167 5.91211 20.4159 5.91211 20.1023C5.91211 19.7888 6.03669 19.488 6.25843 19.2662C6.48018 19.0445 6.78094 18.9199 7.09454 18.9199H23.6485C23.9621 18.9199 24.2629 19.0445 24.4846 19.2662C24.7064 19.488 24.831 19.7888 24.831 20.1023C24.831 20.4159 24.7064 20.7167 24.4846 20.9385C24.2629 21.1602 23.9621 21.2848 23.6485 21.2848Z"
          fill="#D0D0D0"
        />
        <path
          d="M23.6485 26.0133H7.09454C6.78094 26.0133 6.48018 25.8887 6.25843 25.667C6.03669 25.4452 5.91211 25.1445 5.91211 24.8309C5.91211 24.5173 6.03669 24.2165 6.25843 23.9948C6.48018 23.773 6.78094 23.6484 7.09454 23.6484H23.6485C23.9621 23.6484 24.2629 23.773 24.4846 23.9948C24.7064 24.2165 24.831 24.5173 24.831 24.8309C24.831 25.1445 24.7064 25.4452 24.4846 25.667C24.2629 25.8887 23.9621 26.0133 23.6485 26.0133Z"
          fill="#D0D0D0"
        />
        <path
          d="M17.7364 30.7438H7.09454C6.78094 30.7438 6.48018 30.6192 6.25843 30.3974C6.03669 30.1757 5.91211 29.8749 5.91211 29.5613C5.91211 29.2477 6.03669 28.947 6.25843 28.7252C6.48018 28.5035 6.78094 28.3789 7.09454 28.3789H17.7364C18.05 28.3789 18.3507 28.5035 18.5725 28.7252C18.7942 28.947 18.9188 29.2477 18.9188 29.5613C18.9188 29.8749 18.7942 30.1757 18.5725 30.3974C18.3507 30.6192 18.05 30.7438 17.7364 30.7438Z"
          fill="#D0D0D0"
        />
      </svg>
        <p className={`ms-2 ${classes.compliantItemText}`}>
          {fileName && truncateString(fileName, 30)}
        </p>
        </div>

        {/* <p className={classes.info}>{fileName}</p> */}
        <div className="d-flex justify-content-center align-items-center p-3">
        <div className={`${styles.selectWrapper} col`}>
            <select name="" id="" onChange={(e) => setSelectedOption(e.target.value)}>
              <option>Choose document type</option>
              <option value="board-resolution-to-open-account">Board Resolution to Open Account</option>
              <option value="certificate-of-incorporation">Certificate of Incorporation</option>
              <option value="corporate-address-verification">Corporate Address Verification</option>
              <option value="memorandum-&-articles-of-association">Memorandum & Articles of Association</option>
              <option value="director-identification">Director Identification</option>
              <option value="director-address-verification">Director Address Verification</option>
            </select>
            <svg
              width="13"
              height="8"
              viewBox="0 0 13 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.7168 2.10938L6.68885 6.03617C6.49112 6.22893 6.17457 6.2249 5.9818 6.02717L2.05501 1.99923"
                stroke="black"
                stroke-width="3"
                stroke-linecap="round"
              />
            </svg>
          </div>
          </div>
        <div className={classes.btnContainer}>
       
        
        {!isSending && (<><button onClick={() => setIsOpen(false)} className={classes.cancel}>
            Cancel
          </button>

          <button className={classes.confirm} onClick={uploadDocumentHandler} style={{background: selectedOption ? "#0077B5" : "#D0D0D0"}} disabled={!selectedOption}>Upload</button></>
          )}

          {isSending && (
          <div className="spinner-border" role="status">
          </div>
          )}
        </div>
      </div>
    </SharedModal>
  );
};

export default UploadDocumentModal;
