import React, { useEffect } from "react";
import AdminDashboard from "../../components/Dashboard/AdminDashboard";
import MainLayout from "../../components/Layout/MainLayout";

const Index = () => {
  useEffect(() => {
    document.title = "Company Management - Prime Bridge Admin";
  }, []);
  return (
    <MainLayout>
      <AdminDashboard />
    </MainLayout>
  );
};

export default Index;
