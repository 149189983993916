import React, { useEffect, useState } from "react";
import InvoiceDetailModal from "./InvoiceDetailModal";
import tableClasses from "../shared/styles/customTable.module.css";
import classes from "../../components/Dashboard/AdminDashboard.module.css";
import Docxtemplater from 'docxtemplater';
import PizZip from 'pizzip';
import PizZipUtils from 'pizzip/utils/index.js';
import FileDownload from "js-file-download";
import axios from "axios";

import { env } from "../../constants/env";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import RemoveInvoiceModal from "./RemoveInvoiceModal";
import EditInvoiceModal from "./EditInvoice";
import { numberWithCommas } from "../../utilities/functions";

const ServiceFeeItem = ({ fetchAllInvoices, list }) => {
  const [isRemoveOpen, setIsRemoveOpen] = useState(false);
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [fileKey, setFileKey] = useState()
  const [fileLink, setFileLink] = useState()

  

  
  const formatDate = (date) =>{
    var today = new Date(date);
    var newDate = today.toLocaleString('en-US', {
       timeZone: "America/Los_Angeles"
    });

    console.log("PST TIME ZONE :(", newDate)

    return dateToYMD(newDate)
  }

  function loadFile(url, callback) {
    PizZipUtils.getBinaryContent(url, callback);
  } 

  const [isDetailOpen, setIsDetailOpen] = useState(false);

  const [company, setCompany] = useState();

  const hidePopover = () => {
    document.body.click();
  };

  const dateToYMD = (date) => {
    const formatedDate = date.split("T")[0];
    console.log("DATE", formatedDate);
    var newDate = new Date(formatedDate);
    var today = new Date();
    var strArray = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    var d = newDate.getDate();
    var dayName = days[newDate.getDay()];
    var m = strArray[newDate.getMonth()];
    var y = newDate.getFullYear();

    return "" + m + " " + (d <= 9 ? "0" + d : d) + ", " + y;
  };

  useEffect(() => {
    getUsers(list?.company);
  }, [list]);

  const getUsers = async (companyId) => {
    const sendRequest = async () => {
      const response = await fetch(
        `${env.primeBridgeUrl}/api/company/${companyId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("sending plays failed.");
      }

      const result = await response.json();

      setCompany(result);

      console.log("Single Comapny data", result);
      

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };



  const handleDownload = (url, filename) => {
    axios.get(url, {
      responseType: 'blob',
    })
    .then((res) => {
      FileDownload(res.data, filename)
    }).then(() => {
      // deleteFile(fileKey)
    })
  }





  const  uploadDocumentHandler = async (file, view) => {
    const sendRequest = async () => {
      console.log("REACHED HERE")
      const formData = new FormData()
      formData.append('files', file)
  
      const response = await fetch(`${env.primeBridgeUrl}/api/docxtopdf`, { 
        method: 'POST',
        body: formData
      });

      if (!response.ok) {
        const result = await response.json();
        setIsSending(false)
        console.log(result.error)
        throw new Error("sending plays failed.");
      }

      const result = await response.json();

      setIsSending(false)

      console.log("File", result.file);
      if(view){
        setFileLink(result.file)
      }else{
        handleDownload(result.file, 'prime-bridge_invoice.pdf');
      }
      
      return result;
    };

    try {
      const data = await sendRequest();
      // deleteFile(data.key)
      setFileKey(data.key)
    } catch (error) {
      // setIsSending(false)
      console.log(error);
    }
  };

  

  

  const generateDocument = (view) => {
    setIsSending(true)
    loadFile(
      'https://s3.amazonaws.com/cdn.traders-central/prime-bridge-portal/invoice_template.docx',
      function (error, content) {
        if (error) {
          throw error;
        }
        var zip = new PizZip(content);
        var doc = new Docxtemplater(zip, {
          paragraphLoop: true,
          linebreaks: true,
        });
        doc.setData({
          director_name: company?.companyName,
          company_name: company?.companyName,
          invoice_date: list?.date?.split('T')[0],
          invoice_number: '100-13',
          payment_terms: 'Monthly',
          customer_number: '12345',
          account_number: '12345',
          description: list?.item,
          total_amount: list.amount
        });
        try {
          // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
          doc.render();
        } catch (error) {
          // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
          function replaceErrors(key, value) {
            if (value instanceof Error) {
              return Object.getOwnPropertyNames(value).reduce(function (
                error,
                key
              ) {
                error[key] = value[key];
                return error;
              },
              {});
            }
            return value;
          }
          console.log(JSON.stringify({ error: error }, replaceErrors));

          if (error.properties && error.properties.errors instanceof Array) {
            const errorMessages = error.properties.errors
              .map(function (error) {
                return error.properties.explanation;
              })
              .join('\n');
            console.log('errorMessages', errorMessages);
            // errorMessages is a humanly readable message looking like this :
            // 'The tag beginning with "foobar" is unopened'
          }
          throw error;
        }
        var out = doc.getZip().generate({
          type: 'blob',
          mimeType:
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        }); //Output the document using Data-URI

        uploadDocumentHandler(out, view)
        // saveAs(out, 'prime-bridge_invoice.docx');
      }
    );
  };

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        <div style={{ width: "120px" }}>
          <div  onClick={() => {
              generateDocument(true)
              setIsDetailOpen(true)
              hidePopover();
            }} className={classes.popover_item}>
            <svg
              width="20"
              height="14"
              viewBox="0 0 20 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10 4C8.34315 4 7 5.34315 7 7C7 8.65685 8.34315 10 10 10C11.6569 10 13 8.65685 13 7C13 5.34315 11.6569 4 10 4ZM9 7C9 6.44772 9.44771 6 10 6C10.5523 6 11 6.44772 11 7C11 7.55228 10.5523 8 10 8C9.44771 8 9 7.55228 9 7Z"
                fill="black"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M19.83 6.28074C17.542 2.15186 13.8122 0 10 0C6.18777 0 2.45796 2.15186 0.170031 6.28074C-0.0536339 6.68437 -0.0563851 7.18209 0.160292 7.58759C2.41183 11.8013 6.1628 14 10 14C13.8372 14 17.5882 11.8013 19.8397 7.58759C20.0564 7.18208 20.0536 6.68437 19.83 6.28074ZM10 12C7.06097 12 4.04052 10.3724 2.09173 6.94867C4.06862 3.59614 7.07319 2 10 2C12.9268 2 15.9314 3.59614 17.9083 6.94867C15.9595 10.3724 12.939 12 10 12Z"
                fill="black"
              />
            </svg>
            <p>View</p>
          </div>

          <div
            onClick={() => {
              generateDocument()
              hidePopover();
            }}
            className={classes.popover_item}
          >
            <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.29 11.29C5.19627 11.383 5.12188 11.4936 5.07111 11.6154C5.02034 11.7373 4.9942 11.868 4.9942 12C4.9942 12.132 5.02034 12.2627 5.07111 12.3846C5.12188 12.5064 5.19627 12.617 5.29 12.71L8.29 15.71C8.38296 15.8037 8.49356 15.8781 8.61542 15.9289C8.73728 15.9797 8.86799 16.0058 9 16.0058C9.13201 16.0058 9.26272 15.9797 9.38458 15.9289C9.50644 15.8781 9.61704 15.8037 9.71 15.71L12.71 12.71C12.8983 12.5217 13.0041 12.2663 13.0041 12C13.0041 11.7337 12.8983 11.4783 12.71 11.29C12.5217 11.1017 12.2663 10.9959 12 10.9959C11.7337 10.9959 11.4783 11.1017 11.29 11.29L10 12.59V1C10 0.734784 9.89464 0.48043 9.70711 0.292893C9.51957 0.105357 9.26522 0 9 0C8.73478 0 8.48043 0.105357 8.29289 0.292893C8.10536 0.48043 8 0.734784 8 1V12.59L6.71 11.29C6.61704 11.1963 6.50644 11.1219 6.38458 11.0711C6.26272 11.0203 6.13201 10.9942 6 10.9942C5.86799 10.9942 5.73728 11.0203 5.61542 11.0711C5.49356 11.1219 5.38296 11.1963 5.29 11.29ZM15 7H13C12.7348 7 12.4804 7.10536 12.2929 7.29289C12.1054 7.48043 12 7.73478 12 8C12 8.26522 12.1054 8.51957 12.2929 8.70711C12.4804 8.89464 12.7348 9 13 9H15C15.2652 9 15.5196 9.10536 15.7071 9.29289C15.8946 9.48043 16 9.73478 16 10V17C16 17.2652 15.8946 17.5196 15.7071 17.7071C15.5196 17.8946 15.2652 18 15 18H3C2.73478 18 2.48043 17.8946 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17V10C2 9.73478 2.10536 9.48043 2.29289 9.29289C2.48043 9.10536 2.73478 9 3 9H5C5.26522 9 5.51957 8.89464 5.70711 8.70711C5.89464 8.51957 6 8.26522 6 8C6 7.73478 5.89464 7.48043 5.70711 7.29289C5.51957 7.10536 5.26522 7 5 7H3C2.20435 7 1.44129 7.31607 0.87868 7.87868C0.316071 8.44129 0 9.20435 0 10V17C0 17.7956 0.316071 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20H15C15.7956 20 16.5587 19.6839 17.1213 19.1213C17.6839 18.5587 18 17.7956 18 17V10C18 9.20435 17.6839 8.44129 17.1213 7.87868C16.5587 7.31607 15.7956 7 15 7Z" fill="black"/>
</svg>

            <p>Download</p>
          </div>
          <div
            onClick={() => {
              setIsCreateOpen(true)
              hidePopover();
            }}
            className={classes.popover_item}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.35082 1.01441C10.4634 -0.0981162 12.2671 -0.098116 13.3796 1.01441L14.1854 1.82018C15.2979 2.93271 15.2979 4.73648 14.1854 5.84901L4.91911 15.1153L1.22404 15.1153C0.594698 15.1153 0.0845136 14.6051 0.0845156 13.9758L0.0845146 10.2807L9.35082 1.01441ZM11.7681 2.62595L12.5739 3.43171C12.7964 3.65422 12.7964 4.01497 12.5739 4.23748L11.3652 5.44613L9.7537 3.83459L10.9624 2.62595C11.1849 2.40344 11.5456 2.40344 11.7681 2.62595ZM9.7537 7.05766L3.9751 12.8363L2.36356 12.8363L2.36356 11.2247L8.14217 5.44613L9.7537 7.05766Z"
                fill="black"
              />
            </svg>

            <p>Edit</p>
          </div>
          <div
            onClick={() => {
              setIsRemoveOpen(true);
              hidePopover();
            }}
            className={`${classes.popover_item} ${classes.isLast}`}
          >
            <svg
              width="17"
              height="21"
              viewBox="0 0 17 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.66565 9.1534C1.57629 7.99181 2.49473 7 3.65975 7H13.3402C14.5053 7 15.4237 7.9918 15.3344 9.15339L14.642 18.1534C14.5619 19.1954 13.693 20 12.6479 20H4.35206C3.30699 20 2.43811 19.1954 2.35795 18.1534L1.66565 9.1534Z"
                stroke="#BD3437"
                stroke-width="2"
              />
              <path
                d="M16 4H1"
                stroke="#BD3437"
                stroke-width="2"
                stroke-linecap="round"
              />
              <path
                d="M6.5 2C6.5 1.44772 6.94772 1 7.5 1H9.5C10.0523 1 10.5 1.44772 10.5 2V4H6.5V2Z"
                stroke="#BD3437"
                stroke-width="2"
              />
              <path
                d="M10.5 11V16"
                stroke="#BD3437"
                stroke-width="2"
                stroke-linecap="round"
              />
              <path
                d="M6.5 11V16"
                stroke="#BD3437"
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>

            <p style={{ color: "#BD3437" }}>Remove</p>
          </div>
        </div>
      </Popover.Body>
    </Popover>
  );

  console.log("WHAT'S THE DATE", list?.date)

  return (
    <tr>
      <td class={`${tableClasses.row} ${tableClasses.first}`}>{company?.companyName}</td>
      <td class={tableClasses.row}>{formatDate(list?.date)}</td>
      <td class={tableClasses.row}>{list.item}</td>
      <td class={tableClasses.row}>${numberWithCommas(list.amount)} USD</td>
      <td class={tableClasses.row}>
        <div className={tableClasses.flexItem}>
          <p></p>
          <div>
            {/* <svg
                width="20"
                height="20"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => setIsDetailOpen(true)}
              >
                <path
                  d="M16 12.5H12.5M12.5 12.5H9M12.5 12.5V9M12.5 12.5V16M18.3333 23H6.66667C4.08934 23 2 20.9107 2 18.3333V6.66667C2 4.08934 4.08934 2 6.66667 2H18.3333C20.9107 2 23 4.08934 23 6.66667V18.3333C23 20.9107 20.9107 23 18.3333 23Z"
                  stroke="#0077B5"
                  stroke-width="3"
                  stroke-linecap="round"
                />
              </svg> */}
            <OverlayTrigger
              trigger="click"
              placement="bottom"
              overlay={popover}
              rootClose
            >
              <svg
                width="15"
                height="4"
                viewBox="0 0 15 4"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 1.575C0 1.35833 0.0374999 1.15417 0.1125 0.9625C0.195833 0.770833 0.304167 0.604167 0.4375 0.4625C0.579167 0.320834 0.745833 0.208333 0.9375 0.125C1.12917 0.0416666 1.33333 0 1.55 0C1.76667 0 1.97083 0.0416666 2.1625 0.125C2.35417 0.208333 2.52083 0.320834 2.6625 0.4625C2.80417 0.604167 2.91667 0.770833 3 0.9625C3.08333 1.15417 3.125 1.35833 3.125 1.575C3.125 1.8 3.08333 2.00833 3 2.2C2.91667 2.38333 2.80417 2.54583 2.6625 2.6875C2.52083 2.82917 2.35417 2.9375 2.1625 3.0125C1.97083 3.09583 1.76667 3.1375 1.55 3.1375C1.33333 3.1375 1.12917 3.09583 0.9375 3.0125C0.745833 2.9375 0.579167 2.82917 0.4375 2.6875C0.304167 2.54583 0.195833 2.38333 0.1125 2.2C0.0374999 2.00833 0 1.8 0 1.575Z"
                  fill="#0077B5"
                />
                <path
                  d="M5.9082 1.575C5.9082 1.35833 5.9457 1.15417 6.0207 0.9625C6.10404 0.770833 6.21237 0.604167 6.3457 0.4625C6.48737 0.320834 6.65404 0.208333 6.8457 0.125C7.03737 0.0416666 7.24154 0 7.4582 0C7.67487 0 7.87904 0.0416666 8.0707 0.125C8.26237 0.208333 8.42904 0.320834 8.5707 0.4625C8.71237 0.604167 8.82487 0.770833 8.9082 0.9625C8.99154 1.15417 9.0332 1.35833 9.0332 1.575C9.0332 1.8 8.99154 2.00833 8.9082 2.2C8.82487 2.38333 8.71237 2.54583 8.5707 2.6875C8.42904 2.82917 8.26237 2.9375 8.0707 3.0125C7.87904 3.09583 7.67487 3.1375 7.4582 3.1375C7.24154 3.1375 7.03737 3.09583 6.8457 3.0125C6.65404 2.9375 6.48737 2.82917 6.3457 2.6875C6.21237 2.54583 6.10404 2.38333 6.0207 2.2C5.9457 2.00833 5.9082 1.8 5.9082 1.575Z"
                  fill="#0077B5"
                />
                <path
                  d="M11.8164 1.575C11.8164 1.35833 11.8539 1.15417 11.9289 0.9625C12.0122 0.770833 12.1206 0.604167 12.2539 0.4625C12.3956 0.320834 12.5622 0.208333 12.7539 0.125C12.9456 0.0416666 13.1497 0 13.3664 0C13.5831 0 13.7872 0.0416666 13.9789 0.125C14.1706 0.208333 14.3372 0.320834 14.4789 0.4625C14.6206 0.604167 14.7331 0.770833 14.8164 0.9625C14.8997 1.15417 14.9414 1.35833 14.9414 1.575C14.9414 1.8 14.8997 2.00833 14.8164 2.2C14.7331 2.38333 14.6206 2.54583 14.4789 2.6875C14.3372 2.82917 14.1706 2.9375 13.9789 3.0125C13.7872 3.09583 13.5831 3.1375 13.3664 3.1375C13.1497 3.1375 12.9456 3.09583 12.7539 3.0125C12.5622 2.9375 12.3956 2.82917 12.2539 2.6875C12.1206 2.54583 12.0122 2.38333 11.9289 2.2C11.8539 2.00833 11.8164 1.8 11.8164 1.575Z"
                  fill="#0077B5"
                />
              </svg>
            </OverlayTrigger>
          </div>
          <InvoiceDetailModal
            isOpen={isDetailOpen}
            setIsOpen={setIsDetailOpen}
            fileLink={fileLink}
            isSending={isSending}
          />
        </div>
      </td>
      <RemoveInvoiceModal isOpen={isRemoveOpen} setIsOpen={setIsRemoveOpen} invoiceId={list._id} fetchAllInvoices={fetchAllInvoices}/>
      <EditInvoiceModal
            isOpen={isCreateOpen}
            setIsOpen={setIsCreateOpen}
            updateList={fetchAllInvoices} 
            companyItem={company}
            list={list}
            />
    </tr>
  );
};

export default ServiceFeeItem;
