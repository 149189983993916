import React, { useEffect } from "react";
import MainLayout from "../../components/Layout/MainLayout";
import AddTeamMember from "../../components/Teams/AddTeamMember";

const AddTeamPage = () => {
  useEffect(() => {
    document.title = "Add Team member - Prime Bridge Admin";
  }, []);
  return (
    <MainLayout>
      <AddTeamMember />
    </MainLayout>
  );
};

export default AddTeamPage;
