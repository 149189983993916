import React, { useEffect, useState } from "react";
import classes from "./RiskManagementMain.module.css";
import DetailItem from "./DetailsItem";
import Statistics from "./statistics/Statistics";
import AccountItem from "../Dashboard/AccountItem";
import AddNewMT4AccountModal from "../Dashboard/AddNewMT4Account";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import AnalyticsItem from "./AnalyticsItem";
import AccountRow from "./AccountRow";
import { env } from "../../constants/env";
import AccountMt4Item from "../Dashboard/AccountMt4Item";




const AccountsMain = () => {

  const dispatch = useDispatch();

  const [accountNumber, setAccountNumber] = useState("")
  const [fetchedAccounts, setFetchedAccount] = useState([])
  const [statistics, setStatistics] = useState()
  const [accountsDropDown, setAccountsDropDown] = useState(false)
  const [companyName, setCompanyName] = useState("")
  const [showAccountDetails, setShowAccountDetails] = useState("")
  const [showMt5Account, setShowMt5Account] = useState(false)

  const [perPage, setPerpage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [offset, setOffset] = useState(0);
  const [pageCount, setPageCount] = useState();
  const [accounts, setAccounts] = useState([]);

  const [totalAccounts, setTotalAccounts] = useState();

  const [text, setText] = useState("");

  const accountsDetails = useSelector((state) => state.accounts.allMt4Accounts);


  useEffect(() => {
    getAccounts()
  },[])

  useEffect(() => {
    const numberOfPage = Math.ceil(totalAccounts/perPage)
    setPageCount(numberOfPage)
  },[totalAccounts])

  useEffect(() => {
    getAccounts(currentPage)
  },[currentPage])

  const getAccounts = async (page) => {
    const sendRequest = async () => {
      const response = await fetch(`${env.primeBridgeUrl}/api/account/mt-5?page=${page}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
  
      if (!response.ok) {
        throw new Error("Getting account failed.");
      }
  
      const result = await response.json();
  
      console.log("ALL ACCOUNTS :)", result)
  
      setTotalAccounts(result?.totalItems)
      setAccounts(result?.accounts)
  
      return result;
    };
  
    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * perPage;
  
    setCurrentPage(selectedPage)
    setOffset(offset)
  };

  return (
    <div>
      <div className={classes.container}>
        <div className={classes.tableContainer}>
          <div className={classes.headerSection}>
            <p className={classes.title}>MT4 Accounts</p>
            {showMt5Account && (
              <AddNewMT4AccountModal
                isOpen={true}
                setIsOpen={() => setShowMt5Account(false)}
                addCashAccount={true}
              />
            )}
            <div  onClick={() => {
                setShowMt5Account(true);
              }}
              className={classes.actionContainer}>
            <svg
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.49997 12.1833C8.03052 12.1833 7.64997 11.8028 7.64997 11.3333V9.35H5.66663C5.19719 9.35 4.81663 8.96944 4.81663 8.5C4.81663 8.03056 5.19719 7.65 5.66663 7.65H7.64997V5.66667C7.64997 5.19723 8.03052 4.81667 8.49997 4.81667C8.96941 4.81667 9.34997 5.19723 9.34997 5.66667V7.65H11.3333C11.8027 7.65 12.1833 8.03056 12.1833 8.5C12.1833 8.96944 11.8027 9.35 11.3333 9.35H9.34997V11.3333C9.34997 11.8028 8.96941 12.1833 8.49997 12.1833Z"
                  fill="#0077B5"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0 8.5C0 3.80558 3.80558 0 8.5 0C13.1944 0 17 3.80558 17 8.5C17 13.1944 13.1944 17 8.5 17C3.80558 17 0 13.1944 0 8.5ZM8.5 1.7C4.74446 1.7 1.7 4.74446 1.7 8.5C1.7 12.2555 4.74446 15.3 8.5 15.3C12.2555 15.3 15.3 12.2555 15.3 8.5C15.3 4.74446 12.2555 1.7 8.5 1.7Z"
                  fill="#0077B5"
                />
              </svg>
              <p className={classes.actionText}>Add Account</p>
          </div>
          </div>
          <hr/>
          <div className={classes.accountSection}>
            {accountsDetails.map((item, i) => (
              <AccountMt4Item account={item} key={i} />
            ))}
            {/* <AccountItem /> */}
          </div>
        </div>  
      <div className={`${classes.tableContainer} mt-5`} >
          <div className={classes.headerSection}>
            <p className={classes.title}>MT5 Accounts</p>
          </div>
          <hr/>
          <table style={{ width: "100%" }} class={classes.container}>
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class={`${classes.header} ${classes.first}`}
                >
                  Account ID
                </th>
                <th scope="col" class={classes.header}>
                  Account Name
                </th>
                <th scope="col" class={classes.header}>
                  Deposit
                </th>
                <th scope="col" class={classes.header}>
                  LP Group
                </th>
                <th scope="col" class={classes.header}>
                  API ID
                </th>
              </tr>
            </thead>
            <tbody class={classes.body}>
              {accounts?.map((item, index) => (
                <AccountRow key={index} item={item}/>
              ))}
            </tbody>
          </table>

          <ReactPaginate
            previousLabel={"prev"}
            nextLabel={"next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={1}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}/>
        </div>
      </div>
    </div>
  );
};

export default AccountsMain;
