import React, { useEffect, useState } from "react";
import tableClasses from "../shared/styles/customTable.module.css";
import WithdrawalRequestModal from "./WithdrawalRequestModal";
import DepositRequestModal from "./DepositRequestModal";
import { env } from "../../constants/env";
import { numberWithCommas } from "../../utilities/functions";
import TransferRequestModal from "./TransferRequestModal";

const StatusRequestItem = ({
  isWithdrawal,
  isTransfer,
  status = "pending",
  isNewRequest = false,
  isCrypto,
  request
}) => {
  const [isDepositOpen, setIsDepositOpen] = useState(false);
  const [isWithdrawalOpen, setIsWithdrawalOpen] = useState(false);
  const [isTransferOpen, setIsTransferOpen] = useState(false);

  const [companyName, setCompanyName] = useState("")
  const [accountName, setAccountName] = useState("")
  const [userName, setUserName] = useState("")

  useEffect(() => {
    fetchSingleCompany(request.company)
    fetchSingleAccount(request.account)
    fetchUser(request.user)
  },[request])

  const dateToYMD = date => {
    const formatedDate = date?.split('T')[0]
    console.log("DATE", formatedDate)
    var newDate = new Date(formatedDate)
    var today = new Date()
    var strArray = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ]
    var days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    var d = newDate.getDate()
    var dayName = days[newDate.getDay()]
    var m = strArray[newDate.getMonth()]
    var y = newDate.getFullYear()

    
    return '' + m + ' ' + (d <= 9 ? '0' + d : d) + ', ' + y
  }


  const fetchSingleCompany =  async(companyId) => {
    const sendRequest = async () => {
      const response = await fetch(
        `${env.primeBridgeUrl}/api/company/${companyId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("fetching company failed.");
      }

      const result = await response.json();

      console.log("Results get company redux", result);
      setCompanyName(result.companyName)

      return result;
    };

    try {
      const data = await sendRequest();
    } catch (error) {
      console.log(error);
    }
};

const fetchSingleAccount =  async(accountId) => {
  const sendRequest = async () => {
    const response = await fetch(
      `${env.primeBridgeUrl}/api/account/${accountId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error("fetching company failed.");
    }

    const result = await response.json();

    console.log("Results get Account", result);
    setAccountName(result.accountName)

    return result;
  };

  try {
    const data = await sendRequest();
  } catch (error) {
    console.log(error);
  }
};


const fetchUser =  async(userId) => {
  const sendRequest = async () => {
    const response = await fetch(
      `${env.primeBridgeUrl}/api/auth/user/${userId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error("fetching company failed.");
    }

    const result = await response.json();

    console.log("Results user Account", result);
    setUserName(`${result.firstName} ${result.lastName}`)

    return result;
  };

  try {
    const data = await sendRequest();
  } catch (error) {
    console.log(error);
  }
};

  const statusIcon = () => {
    if (request.status === "received" || request.status === "paid" || request.status === "transferred") {
      return (
        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="8.5" cy="8.5" r="8.5" fill="#5DBE94" />
        </svg>
      );
    } else if (request.status === "not-received" || request.status === "not-paid" || request.status === "not-transferred") {
      return (
        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="8.5" cy="8.5" r="8.5" fill="#BD3437" />
        </svg>
      );
    } else {
      return (
        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="8.5" cy="8.5" r="8.5" fill="#D0D0D0" />
        </svg>
      );
    }
  };

  const generateStatusText = () => {
    if (isWithdrawal) {
      if (status == "received") {
        return "Paid";
      } else if ((status = "not received")) {
        return "Not Paid";
      } else {
        return "Pending";
      }
    } else {
      if (status == "received") {
        return "Received";
      } else if (status == "not received") {
        return "Not Received";
      } else {
        return "Pending";
      }
    }
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
  return (
    <tr>
      <td class={`${tableClasses.row} ${tableClasses.first}`}>
        <div
          style={{ justifyContent: "flex-start", gap: "1rem" }}
          className={tableClasses.flexItem}
        >
          <div>{statusIcon()}</div>
          <p>{capitalizeFirstLetter(request?.status)}</p>
        </div>
      </td>
      <td class={tableClasses.row}>{dateToYMD(request?.createdAt)}</td>
      <td class={tableClasses.row}>{companyName}</td>
      <td class={tableClasses.row}>{request?.paymentMethod === "internal-transfer" ? request?.sourceAccount?.accountName : accountName}</td>
      {request?.paymentMethod === "internal-transfer"  && <td class={tableClasses.row}>{request?.destinationAccount?.accountName }</td>}
      <td class={tableClasses.row}>
        <div style={{ gap: "3rem" }} className={tableClasses.flexItem}>
        <p>{`${request?.currency === "usd" || request?.paymentMethod === "crypto" ? "$" : request?.currency === "euro"  && "€"} ${numberWithCommas(request?.totalWithdrawal)} ${request?.currency === "usd" || request?.paymentMethod === "crypto" ? "USD" : request?.currency === "euro"  && "EUR"}`}</p>
          <div>
            <svg
              width="20"
              height="20"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() =>
                isTransfer ? setIsTransferOpen(true) : isWithdrawal
                  ? setIsWithdrawalOpen(true)
                  : setIsDepositOpen(true)
              }
            >
              <path
                d="M16 12.5H12.5M12.5 12.5H9M12.5 12.5V9M12.5 12.5V16M18.3333 23H6.66667C4.08934 23 2 20.9107 2 18.3333V6.66667C2 4.08934 4.08934 2 6.66667 2H18.3333C20.9107 2 23 4.08934 23 6.66667V18.3333C23 20.9107 20.9107 23 18.3333 23Z"
                stroke="#0077B5"
                stroke-width="3"
                stroke-linecap="round"
              />
            </svg>
          </div>
        </div>
      </td>

      <DepositRequestModal
        isOpen={isDepositOpen}
        setIsOpen={setIsDepositOpen}
        isNewRequest={isNewRequest}
        request={request}
        companyName={companyName}
        accountName={accountName}
        userName={userName}
      />
      <WithdrawalRequestModal
        isOpen={isWithdrawalOpen}
        setIsOpen={setIsWithdrawalOpen}
        isNewRequest={isNewRequest}
        request={request}
        companyName={companyName}
        accountName={accountName}
        userName={userName}
      />

      <TransferRequestModal
        isOpen={isTransferOpen}
        setIsOpen={setIsTransferOpen}
        isNewRequest={isNewRequest}
        request={request}
        companyName={companyName}
        accountName={accountName}
        userName={userName}
      />
    </tr>
  );
};

export default StatusRequestItem;
