import { env } from "../constants/env";
import { requestsActions } from "./requests-slice";



export const fetchPendingDepositRequests =  () => {
  return async (dispatch) => {
    const sendRequest = async () => {
      const response = await fetch(`${env.primeBridgeUrl}/api/transaction/pending-deposit`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Getting accounts failed.");
      }

      const result = await response.json();

      return result;
    };

    try {
      const data = await sendRequest();
      dispatch(requestsActions.getPendingDepositRequests({ pendingDepositRequests: data }));
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchAllDepositRequests =  () => {
  return async (dispatch) => {
    const sendRequest = async () => {
      const response = await fetch(`${env.primeBridgeUrl}/api/transaction/deposit`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Getting Deposit Requets failed.");
      }

      const result = await response.json();


      result.forEach(function (element) {
        if(element?.status === "pending"){
          element.order = 1;
        }else if(element?.status === "received"){
          element.order = 2;
        }else if(element?.status === "not-received"){
          element.order = 3;
        }
       
      });

      result.sort(function (a, b) {
        return a.order - b.order;
      });

      return result;
    };

    try {
      const data = await sendRequest();
      dispatch(requestsActions.getAllDepositRequests({allDepositRequests: data}));
    } catch (error) {
      console.log(error);
    }
  };
};


export const fetchPendingWithdrawalRequests =  () => {
  return async (dispatch) => {
    const sendRequest = async () => {
      const response = await fetch(`${env.primeBridgeUrl}/api/transaction/pending-withdrawal`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Getting Withdrawal failed.");
      }

      const result = await response.json();

      return result;
    };

    try {
      const data = await sendRequest();
      dispatch(requestsActions.getPendingWithdrawalRequests({ pendingWithdrawalRequests: data }));
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchAllWithdrawalRequests =  () => {
  return async (dispatch) => {
    const sendRequest = async () => {
      const response = await fetch(`${env.primeBridgeUrl}/api/transaction/withdrawal`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Getting Withdrawal Requets failed.");
      }

      const result = await response.json();

      result.forEach(function (element) {
        if(element?.status === "pending"){
          element.order = 1;
        }else if(element?.status === "paid"){
          element.order = 2;
        }else if(element?.status === "not-paid"){
          element.order = 3;
        }
       
      });

      result.sort(function (a, b) {
        return a.order - b.order;
      });

      return result;
    };

    try {
      const data = await sendRequest();
      dispatch(requestsActions.getAllWithdrawalRequests({allWithdrawalRequests: data}));
    } catch (error) {
      console.log(error);
    }
  };
};


export const fetchPendingTransferRequests =  () => {
  return async (dispatch) => {
    const sendRequest = async () => {
      const response = await fetch(`${env.primeBridgeUrl}/api/transaction/pending-transfer`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Getting Withdrawal failed.");
      }

      const result = await response.json();

      console.log("TRANSFER PENDING redux", result);

      return result;
    };

    try {
      const data = await sendRequest();
      dispatch(requestsActions.getPendingTransferRequests({ pendingTransferRequests: data }));
    } catch (error) {
      console.log(error);
    }
  };
};


export const fetchAllTransferRequests =  () => {
  return async (dispatch) => {
    const sendRequest = async () => {
      const response = await fetch(`${env.primeBridgeUrl}/api/transaction/transfer`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Getting Withdrawal Requets failed.");
      }

      const result = await response.json();

      result.forEach(function (element) {
        if(element?.status === "pending"){
          element.order = 1;
        }else if(element?.status === "completed"){
          element.order = 2;
        }else if(element?.status === "failed"){
          element.order = 3;
        }
       
      });

      result.sort(function (a, b) {
        return a.order - b.order;
      });

      return result;
    };

    try {
      const data = await sendRequest();
      dispatch(requestsActions.getAllTransferRequests({allTransferRequests: data}));
    } catch (error) {
      console.log(error);
    }
  };
};
