import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DetailPage from "../../components/Dashboard/DetailPage";
import MainLayout from "../../components/Layout/MainLayout";
import { env } from "../../constants/env";

const CompanyDetail = () => {
  const { id } = useParams()

  const [companyDetails, setCompanyDetails] = useState()
  const [accountsDetails, setAccountsDetails] = useState([])

  console.log(id)

  useEffect(() => {
    document.title = "Company Detail - Prime Bridge Admin";
    getCompany()
    getAccounts()
  }, []);

  const getCompany = async () => {
    const sendRequest = async () => {
      const response = await fetch(`${env.primeBridgeUrl}/api/company/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        const error = await response.json()
        console.log("ERROR", error)
        throw new Error("sending plays failed.");
      }

      const result = await response.json();

      setCompanyDetails(result)

      console.log("Reply from plays data", result);

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };

  const getAccounts = async () => {
    const sendRequest = async () => {
      const response = await fetch(`${env.primeBridgeUrl}/api/account/company/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("sending plays failed.");
      }

      const result = await response.json();
      setAccountsDetails(result)
      console.log("Reply from plays data", result);

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <MainLayout>
      <DetailPage companyDetails={companyDetails} accountsDetails={accountsDetails} companyId={id} getCompany={getCompany}/>
    </MainLayout>
  );
};

export default CompanyDetail;
