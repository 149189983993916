import React, { useEffect, useState } from "react";
import classes from "./RiskManagementMain.module.css";
import { env } from "../../constants/env";
import AnalyticsItem from "./AnalyticsItem";
import Statistics from "./statistics/Statistics";
import DetailItem from "./DetailsItem";
import { numberWithCommas } from "../../utilities/functions";
import { getCurrentTime, getTodaysDate } from "../../utilities/GetTimeDate";
import ReactPaginate from "react-paginate";
import ViolationSection from "./ViolationSection";




const RiskManagementMain = () => {

  const [accountNumber, setAccountNumber] = useState("")
  const [fetchedAccounts, setFetchedAccount] = useState([])
  const [statistics, setStatistics] = useState()
  const [accountsDropDown, setAccountsDropDown] = useState(false)
  const [companyName, setCompanyName] = useState("")
  const [showAccountDetails, setShowAccountDetails] = useState("")
  const [accountDetails, setAccountDetails] = useState()

  const [perPage, setPerpage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [offset, setOffset] = useState(0);
  const [pageCount, setPageCount] = useState();
  const [accounts, setAccounts] = useState();

  const [totalAccounts, setTotalAccounts] = useState();

  const [text, setText] = useState("");

  useEffect(() => {
    getAccounts(accountNumber)
  },[accountNumber])

  useEffect(() => {
    const numberOfPage = Math.ceil(totalAccounts/perPage)
    setPageCount(numberOfPage)
  },[totalAccounts])

  useEffect(() => {
      
      if(text.length <= 0){
        getAllAccounts(currentPage)
      }else{
        searchAccounts()
      }
      
  },[currentPage])

  useEffect(() => {
    setCurrentPage(0)
    searchAccounts()
  },[text])




  
  const getAccounts = async (account) => {
    const sendRequest = async () => {
      const response = await fetch(`${env.primeBridgeUrl}/api/account/account-number/${account}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Getting account failed.");
      }

      const result = await response.json();

      console.log("ACCOUNT DETAILS :)", result)

      setFetchedAccount(result)


      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };

  const getStatistics = async(id, companyId) => {
      const sendRequest = async () => {
        fetchSingleCompany(companyId)
        setAccountNumber(id)
        getAccountDetails(id)
        setAccountsDropDown(false)
        setShowAccountDetails(true)
        const response = await fetch(
          `${env.pbTrackingUrl}/api/trades/statistic/${id}`,
          {
            method: "GET",
            headers: {
              "x-api-key": "hv3zmZLmRLM6ZPIQKPhRXWRNMf71MSZE",
            },
          }
        );
  
        if (!response.ok) {
          const result = await response.json();
          console.log("ERRRORR", result);
          setStatistics([])
          throw new Error("Fetching Bagley failed.");
        }
  
        const result = await response.json();
        console.log("STATISTICS DATA FROM REDUX", result);
  
        return result;
      };
  
      try {
        const data = await sendRequest();
        setStatistics(data);
      } catch (error) {
        setStatistics([])
        console.log(error);
      }
  };

  const fetchSingleCompany =  async(companyId) => {
    const sendRequest = async () => {
      const response = await fetch(
        `${env.primeBridgeUrl}/api/company/${companyId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        setCompanyName("")
        throw new Error("fetching company failed.");
      }

      const result = await response.json();

      console.log("Results get company redux", result);
      setCompanyName(result.companyName)

      return result;
    };

    try {
      const data = await sendRequest();
    } catch (error) {
      setCompanyName("")
      console.log(error);
    }
};


const getAccountDetails = async(id) => {
  const sendRequest = async () => {
    const response = await fetch(
      `${env.pbTrackingUrl}/api/trades/account-details/${id}`,
      {
        method: "GET",
        headers: {
          "x-api-key": "hv3zmZLmRLM6ZPIQKPhRXWRNMf71MSZE",
        },
      }
    );

    if (!response.ok) {
      const result = await response.json();
      console.log("ERRRORR", result);
      setAccountDetails(null)
      throw new Error("Fetching Bagley failed.");
    }

    const result = await response.json();
    console.log("ACCOUNT Details", result);

    return result;
  };

  try {
    const data = await sendRequest();
    setAccountDetails(data);
  } catch (error) {
    setAccountDetails(null)
    console.log(error);
  }
};


const getAllAccounts = async (page) => {
  const sendRequest = async () => {
    const response = await fetch(`${env.primeBridgeUrl}/api/account/all/${page}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Getting account failed.");
    }

    const result = await response.json();

    console.log("ALL ACCOUNTS :)", result)

    setTotalAccounts(result?.totalItems)
    setAccounts(result?.accounts)

    return result;
  };

  try {
    await sendRequest();
  } catch (error) {
    console.log(error);
  }
};


const searchAccounts = async () => {
  const sendRequest = async () => {
    const response = await fetch(`${env.primeBridgeUrl}/api/account/search/${text}/${currentPage}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Getting account failed.");
    }

    const result = await response.json();

    console.log("SEARCHED ACCOUNTS :)", result)

    setTotalAccounts(result?.totalItems)
    setAccounts(result?.accounts)

    return result;
  };

  try {

    if(text.length <= 0){
      setCurrentPage(0)
      await getAllAccounts(0)
    }else{
      await sendRequest();
    }
  } catch (error) {
    console.log(error);
  }
};





const handlePageClick = (e) => {
  const selectedPage = e.selected;
  const offset = selectedPage * perPage;

  setCurrentPage(selectedPage)
  setOffset(offset)
};
  

  return (
    <div>
      <div className={classes.container}>
        <div className={classes.navContainer}>
          <p className={classes.headerSection}>Risk Management</p>
          <div className={classes.actionsContainer}></div>
        </div>


        <div className={classes.tableContainer}>
          <div className={classes.headerSection}>
            <p className={classes.title}>Account Details</p>
            <div className={classes.searchContainer}>
            <div className="searchIcon">
              <svg
                width="26"
                height="20"
                viewBox="0 0 26 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="10.5"
                  cy="10.5"
                  r="9"
                  stroke="#ABABAB"
                  stroke-width="3"
                />
                <rect
                  x="15"
                  y="16.9434"
                  width="3"
                  height="13.1583"
                  rx="1.5"
                  transform="rotate(-40.3606 15 16.9434)"
                  fill="#ABABAB"
                />
              </svg>
            </div>
            <div className={classes.searchContainer}>
              <input
                className={classes.searchInput}
                placeholder="Search"
                type="text"
                name=""
                id=""
                value={accountNumber}
                onChange={(e) => {setAccountNumber(e.target.value); setAccountsDropDown(true)}}
              />
              {accountsDropDown && <div className={`${classes.dropdownContainer} d-flex flex-column`}>
                  {fetchedAccounts.map((item, i) => (<div key={i} onClick={() => getStatistics(item.credentials.accountNumber, item.company)} role="button">
                    {item.credentials.accountNumber}
                  </div>))}
              </div>}
            </div>
            
          </div>
          </div>
          <hr/>
          {showAccountDetails && <div>
            <div className="row">
              
              <div className="col-4">
                <DetailItem icon={true} keyItem={'Account ID:'} value={accountNumber}/>
                <DetailItem keyItem={'Company Name:'} value={companyName}/>
              </div>
              <div className="col-4">
                <DetailItem keyItem={'Monthly Drawdown Time:'} value={accountDetails?.data[0]?.md_date ? getTodaysDate(accountDetails?.data[0]?.md_date) + ' ' + getCurrentTime(accountDetails?.data[0]?.md_date) : ""}/>
                <DetailItem keyItem={'Monthly Drawdown:'} value={accountDetails?.data[0]?.Monthlydrawdown ? numberWithCommas(Math.round(accountDetails?.data[0]?.Monthlydrawdown)) : 0}/>
              </div>
              <div className="col-4">
                <DetailItem keyItem={'Profit:'} value={accountDetails?.data[0]?.MonthlyProfit ? numberWithCommas(Math.round(accountDetails?.data[0]?.MonthlyProfit * 100) / 100) : 0}/>
                <DetailItem keyItem={'Profit Time:'} value={accountDetails?.data[0]?.profit_date ? getTodaysDate(accountDetails?.data[0]?.profit_date) + ' ' + getCurrentTime(accountDetails?.data[0]?.profit_date) : ""}/>
              </div>
            </div>
          <Statistics statistics={statistics}/>

          </div>}
          {!showAccountDetails &&
            <div className={classes.emptyContainer}>
            </div>
          }
        </div>

        <div className={classes.tableContainer}>
          <div className={classes.headerSection}>
            <p className={classes.title}>Analytics Link</p>
            <div className={classes.searchContainer}>
            <div className="searchIcon">
              <svg
                width="26"
                height="20"
                viewBox="0 0 26 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="10.5"
                  cy="10.5"
                  r="9"
                  stroke="#ABABAB"
                  stroke-width="3"
                />
                <rect
                  x="15"
                  y="16.9434"
                  width="3"
                  height="13.1583"
                  rx="1.5"
                  transform="rotate(-40.3606 15 16.9434)"
                  fill="#ABABAB"
                />
              </svg>
            </div>
            <input
              className={classes.searchInput}
              placeholder="Search"
              type="text"
              name=""
              id=""
              onChange={(e) => setText(e.target.value)}
            />
          </div>
          </div>
          <hr/>

          <table style={{ width: "100%" }} class={classes.container}>
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class={`${classes.header} ${classes.first}`}
                >
                  Account ID
                </th>
                <th scope="col" class={classes.header}>
                  Company
                </th>
                <th scope="col" class={classes.header}>
                Account Name
                </th>
                <th scope="col" class={classes.header}>
                Account Link
                </th>
                {/* <th scope="col" class={classes.header}>
                  Website
                </th> */}
              </tr>
            </thead>
            <tbody class={classes.body}>
              {accounts?.map((item, index) => (
                <AnalyticsItem key={index} item={item}/>
              ))}
            </tbody>
          </table>

          <ReactPaginate
            previousLabel={"prev"}
            nextLabel={"next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={1}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}/>
        </div>


        <ViolationSection classes={classes}/>
      </div>
    </div>
  );
};

export default RiskManagementMain;
