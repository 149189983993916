import React, { useEffect, useState } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { NavLink } from "react-router-dom";

import tableClasses from "../shared/styles/customTable.module.css";
import classes from "../../components/Dashboard/AdminDashboard.module.css";
import RemoveCompanyModal from "./RemoveCompanyModal";
import { env } from "../../constants/env";
import RedDocumentIcon from "../icons/RedDocumentIcon";

const CompanyListItem = ({companyId,companyName, directorId, website, refNumber, director, email}) => {
  const [isRemoveOpen, setIsRemoveOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [companyDirector, setCompanyDirector] = useState();
  const [isSending, setIsSending] = useState(false)
  const [documentCount, setDocumentCount] = useState(0)
  
  const hidePopover = () => {
    document.body.click();
  };


  useEffect(() => {
    getCompanyDirector()
  }, [])

  const  getCompanyDirector = async () => {
    const sendRequest = async () => {
      const response = await fetch(`${env.primeBridgeUrl}/api/auth/user/${directorId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("sending plays failed.");
      }

      const result = await response.json();

      setCompanyDirector(result)
      if(result.status === "disabled"){
        setIsDisabled(true)
      }else{
        setIsDisabled(false)
      }
      

      console.log("Reply from plays data", result);

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };

  const disableLogin = async (status) => {
    let dataContent = {status}
    const sendRequest = async () => {
      setIsSending(true)
      const response = await fetch(`${env.primeBridgeUrl}/api/auth/disable-login/${directorId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataContent)
      });

      if (!response.ok) {
        setIsSending(false)
        throw new Error("sending plays failed.");
      }

      const result = await response.json();

      console.log("Reply from plays data", result);

      if(result.status === "disabled"){
        setIsDisabled(true)
      }else{
        setIsDisabled(false)
      }
      hidePopover();
      setIsSending(false)

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      setIsSending(false)
      console.log(error);
    }
  };

  useEffect(() => {
    getDocumentCount()
  }, [])

  const  getDocumentCount = async () => {
    const sendRequest = async () => {
      const response = await fetch(`${env.primeBridgeUrl}/api/company/count-verified/${companyId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        const result = await response.json();
        console.log("COUNT ERRROR", result)
        throw new Error("sending plays failed.");
      }

      const result = await response.json();
      setDocumentCount(result)

      console.log("COUNT", result);

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        <NavLink
          className={classes.linkItem}
          style={{ textDecoration: "none", color: "black" }}
          to={`/company/${companyId}`}
        >
          <div className={classes.popover_item}>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.35082 1.01441C10.4634 -0.0981162 12.2671 -0.098116 13.3796 1.01441L14.1854 1.82018C15.2979 2.93271 15.2979 4.73648 14.1854 5.84901L4.91911 15.1153L1.22404 15.1153C0.594698 15.1153 0.0845136 14.6051 0.0845156 13.9758L0.0845146 10.2807L9.35082 1.01441ZM11.7681 2.62595L12.5739 3.43171C12.7964 3.65422 12.7964 4.01497 12.5739 4.23748L11.3652 5.44613L9.7537 3.83459L10.9624 2.62595C11.1849 2.40344 11.5456 2.40344 11.7681 2.62595ZM9.7537 7.05766L3.9751 12.8363L2.36356 12.8363L2.36356 11.2247L8.14217 5.44613L9.7537 7.05766Z"
                fill="black"
              />
            </svg>
            <p>Edit Company</p>
          </div>
        </NavLink>
        {!isDisabled ? (
          <div
            onClick={() => {
              disableLogin('disabled')
            }}
            className={classes.popover_item}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.6 7.2C5.15817 7.2 4.8 7.55817 4.8 8C4.8 8.44183 5.15817 8.8 5.6 8.8H10.4C10.8418 8.8 11.2 8.44183 11.2 8C11.2 7.55817 10.8418 7.2 10.4 7.2H5.6Z"
                fill="black"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8ZM8 1.6C4.46538 1.6 1.6 4.46538 1.6 8C1.6 11.5346 4.46538 14.4 8 14.4C11.5346 14.4 14.4 11.5346 14.4 8C14.4 4.46538 11.5346 1.6 8 1.6Z"
                fill="black"
              />
            </svg>

            <p>Disable Login</p>
          </div>
        ) : (
          <div
            onClick={() => {
              disableLogin('active')
            }}
            className={classes.popover_item}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8ZM8 1.6C4.46538 1.6 1.6 4.46538 1.6 8C1.6 11.5346 4.46538 14.4 8 14.4C11.5346 14.4 14.4 11.5346 14.4 8C14.4 4.46538 11.5346 1.6 8 1.6ZM8.29741 8.8975L8.15571 8.97015L7.56126 9.27496C7.30945 9.40407 7 9.23602 7 8.97015V8.18033V8.10181V7.88618V7.87705V7.76162V7.75249V7.53609V7.45834V6.66852C7 6.40265 7.30945 6.2346 7.56126 6.36372L8.15571 6.66852L8.29741 6.74117L8.3 6.7425L8.48799 6.83889L8.5 6.84505L8.75635 6.97649L8.8241 7.01123L8.97552 7.08887L9.01168 7.10741L9.38162 7.2971L9.80568 7.51453C10.0648 7.64738 10.0648 7.99129 9.80568 8.12414L9.38162 8.34158L9.01168 8.53126L8.97552 8.5498L8.8241 8.62744L8.75635 8.66218L8.5 8.79362L8.48799 8.79978L8.3 8.89617L8.29741 8.8975ZM5.5 6.66852V8.97015C5.5 10.5502 7.1724 11.16 8.24566 10.6097L10.4901 9.4589C11.8366 8.76846 11.8366 6.87021 10.4901 6.17977L8.24566 5.02895C7.17239 4.47864 5.5 5.08843 5.5 6.66852Z"
                fill="black"
              />
            </svg>

            <p>Enable Login</p>
          </div>
        )}
        <div
          onClick={() => {
            setIsRemoveOpen(true);
            hidePopover();
          }}
          className={`${classes.popover_item} ${classes.isLast}`}
        >
          <svg
            width="17"
            height="21"
            viewBox="0 0 17 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.66565 9.1534C1.57629 7.99181 2.49473 7 3.65975 7H13.3402C14.5053 7 15.4237 7.9918 15.3344 9.15339L14.642 18.1534C14.5619 19.1954 13.693 20 12.6479 20H4.35206C3.30699 20 2.43811 19.1954 2.35795 18.1534L1.66565 9.1534Z"
              stroke="#BD3437"
              stroke-width="2"
            />
            <path
              d="M16 4H1"
              stroke="#BD3437"
              stroke-width="2"
              stroke-linecap="round"
            />
            <path
              d="M6.5 2C6.5 1.44772 6.94772 1 7.5 1H9.5C10.0523 1 10.5 1.44772 10.5 2V4H6.5V2Z"
              stroke="#BD3437"
              stroke-width="2"
            />
            <path
              d="M10.5 11V16"
              stroke="#BD3437"
              stroke-width="2"
              stroke-linecap="round"
            />
            <path
              d="M6.5 11V16"
              stroke="#BD3437"
              stroke-width="2"
              stroke-linecap="round"
            />
          </svg>

          <p style={{ color: "#BD3437" }}>Remove Company</p>
        </div>
      </Popover.Body>
    </Popover>
  );
  return (
    <tr>
      <td class={`${tableClasses.row} ${tableClasses.first}`}>
      <div className="d-flex  align-items-end">
        {isDisabled == true && (
          <svg
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={tableClasses.svg}
          >
            <path
              d="M6.65 8.55C6.12533 8.55 5.7 8.97533 5.7 9.5C5.7 10.0247 6.12533 10.45 6.65 10.45H12.35C12.8747 10.45 13.3 10.0247 13.3 9.5C13.3 8.97533 12.8747 8.55 12.35 8.55H6.65Z"
              fill="#BD3437"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0 9.5C0 4.25329 4.25329 0 9.5 0C14.7467 0 19 4.25329 19 9.5C19 14.7467 14.7467 19 9.5 19C4.25329 19 0 14.7467 0 9.5ZM9.5 1.9C5.30264 1.9 1.9 5.30264 1.9 9.5C1.9 13.6974 5.30264 17.1 9.5 17.1C13.6974 17.1 17.1 13.6974 17.1 9.5C17.1 5.30264 13.6974 1.9 9.5 1.9Z"
              fill="#BD3437"
            />
          </svg>
        )}
        
        <div className={tableClasses.docIconWrapper}>{documentCount < 6 && <RedDocumentIcon />}</div>
        <NavLink
          className={classes.linkItem}
          style={{ textDecoration: "none", color: "black" }}
          to={`/company/${companyId}`}
        >
          {companyName}
        </NavLink>
        </div>
      </td>
      <td class={tableClasses.row}>{director}</td>
      <td class={tableClasses.row}>{email}</td>
      <td class={tableClasses.row}>
        <a target={"_blank"} href={website}>
          {website}
        </a>
      </td>
      <td class={tableClasses.row}>
        <div className={tableClasses.flexItem}>
          <p>{refNumber}</p>
          <div>
            <OverlayTrigger
              trigger="click"
              placement="bottom"
              overlay={popover}
              rootClose
            >
              <svg
                width="15"
                height="4"
                viewBox="0 0 15 4"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 1.575C0 1.35833 0.0374999 1.15417 0.1125 0.9625C0.195833 0.770833 0.304167 0.604167 0.4375 0.4625C0.579167 0.320834 0.745833 0.208333 0.9375 0.125C1.12917 0.0416666 1.33333 0 1.55 0C1.76667 0 1.97083 0.0416666 2.1625 0.125C2.35417 0.208333 2.52083 0.320834 2.6625 0.4625C2.80417 0.604167 2.91667 0.770833 3 0.9625C3.08333 1.15417 3.125 1.35833 3.125 1.575C3.125 1.8 3.08333 2.00833 3 2.2C2.91667 2.38333 2.80417 2.54583 2.6625 2.6875C2.52083 2.82917 2.35417 2.9375 2.1625 3.0125C1.97083 3.09583 1.76667 3.1375 1.55 3.1375C1.33333 3.1375 1.12917 3.09583 0.9375 3.0125C0.745833 2.9375 0.579167 2.82917 0.4375 2.6875C0.304167 2.54583 0.195833 2.38333 0.1125 2.2C0.0374999 2.00833 0 1.8 0 1.575Z"
                  fill="#0077B5"
                />
                <path
                  d="M5.9082 1.575C5.9082 1.35833 5.9457 1.15417 6.0207 0.9625C6.10404 0.770833 6.21237 0.604167 6.3457 0.4625C6.48737 0.320834 6.65404 0.208333 6.8457 0.125C7.03737 0.0416666 7.24154 0 7.4582 0C7.67487 0 7.87904 0.0416666 8.0707 0.125C8.26237 0.208333 8.42904 0.320834 8.5707 0.4625C8.71237 0.604167 8.82487 0.770833 8.9082 0.9625C8.99154 1.15417 9.0332 1.35833 9.0332 1.575C9.0332 1.8 8.99154 2.00833 8.9082 2.2C8.82487 2.38333 8.71237 2.54583 8.5707 2.6875C8.42904 2.82917 8.26237 2.9375 8.0707 3.0125C7.87904 3.09583 7.67487 3.1375 7.4582 3.1375C7.24154 3.1375 7.03737 3.09583 6.8457 3.0125C6.65404 2.9375 6.48737 2.82917 6.3457 2.6875C6.21237 2.54583 6.10404 2.38333 6.0207 2.2C5.9457 2.00833 5.9082 1.8 5.9082 1.575Z"
                  fill="#0077B5"
                />
                <path
                  d="M11.8164 1.575C11.8164 1.35833 11.8539 1.15417 11.9289 0.9625C12.0122 0.770833 12.1206 0.604167 12.2539 0.4625C12.3956 0.320834 12.5622 0.208333 12.7539 0.125C12.9456 0.0416666 13.1497 0 13.3664 0C13.5831 0 13.7872 0.0416666 13.9789 0.125C14.1706 0.208333 14.3372 0.320834 14.4789 0.4625C14.6206 0.604167 14.7331 0.770833 14.8164 0.9625C14.8997 1.15417 14.9414 1.35833 14.9414 1.575C14.9414 1.8 14.8997 2.00833 14.8164 2.2C14.7331 2.38333 14.6206 2.54583 14.4789 2.6875C14.3372 2.82917 14.1706 2.9375 13.9789 3.0125C13.7872 3.09583 13.5831 3.1375 13.3664 3.1375C13.1497 3.1375 12.9456 3.09583 12.7539 3.0125C12.5622 2.9375 12.3956 2.82917 12.2539 2.6875C12.1206 2.54583 12.0122 2.38333 11.9289 2.2C11.8539 2.00833 11.8164 1.8 11.8164 1.575Z"
                  fill="#0077B5"
                />
              </svg>
            </OverlayTrigger>
          </div>
        </div>
      </td>

      <RemoveCompanyModal isOpen={isRemoveOpen} setIsOpen={setIsRemoveOpen} companyId={companyId} companyName={companyName}/>
    </tr>
  );
};

export default CompanyListItem;
