import { createSlice } from '@reduxjs/toolkit';

const accountsSlice = createSlice({
  name: 'accounts',
  initialState: {
    allAccounts: [],
    allMt4Accounts: [],
    allMt5Accounts: [],
    syncedAccounts: [],
    allOverviewAccounts: [],
    allManagementAccounts: [],
    lpGroups: []

  },
  reducers: {

    getAccounts (state, action) {
      state.allAccounts = action.payload.allAccounts
    },

    getMt4Accounts (state, action) {
      state.allMt4Accounts = action.payload.allMt4Accounts
    },

    getMt5Accounts (state, action) {
      state.allMt5Accounts = action.payload.allMt5Accounts
    },

    getSyncedAccounts (state, action) {
      state.syncedAccounts = action.payload.syncedAccounts
    },

    getOverviewAccounts (state, action) {
      state.allOverviewAccounts = action.payload.allOverviewAccounts
    },

    getManagementAccounts (state, action) {
      state.allManagementAccounts = action.payload.allManagementAccounts
    },

    getLPGroups (state, action) {
      state.lpGroups = action.payload.lpGroups
    },

  }
});

export const accountsActions = accountsSlice.actions;

export default accountsSlice;
