import React, { useEffect, useState } from "react";
import classes from "./styles/index.module.css";
import tableClasses from "../shared/styles/customTable.module.css";
import DepositRequestItem from "./RequestItem";
import { NavLink } from "react-router-dom";
import { env } from "../../constants/env";
import { useDispatch, useSelector } from "react-redux";
import { fetchPendingDepositRequests, fetchPendingTransferRequests, fetchPendingWithdrawalRequests } from "../../Redux/requests-actions";

const IndexPage = () => {

  // const [depositeRequests, setDepositeRequests] = useState([])
  // const [withdrawalRequests, setWithdrawalRequests] = useState([])

  const dispatch = useDispatch()

  const depositeRequests = useSelector(state => state.requests.pendingDepositRequests)
  const withdrawalRequests = useSelector(state => state.requests.pendingWithdrawalRequests)
  const transferRequests = useSelector(state => state.requests.pendingTransferRequests)

  useEffect(() => {
    dispatch(fetchPendingDepositRequests())
    dispatch(fetchPendingWithdrawalRequests())
    dispatch(fetchPendingTransferRequests())
  }, [])


  // useEffect(() => {
  //   getDepositRequests()
  //   getWithdrawalRequests()
  // },[])

  // const getDepositRequests = async () => {
  //   const sendRequest = async () => {
  //     const response = await fetch(`${env.primeBridgeUrl}/api/transaction/pending-deposit`, {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     });

  //     if (!response.ok) {
  //       const error = await response.json();
  //       console.log("ERROR RESPONSE", error?.error)
  //       throw new Error("sending plays failed.");
  //     }

  //     const result = await response.json();
  //     setDepositeRequests(result)
  //     console.log("UNSOLVED", result);

  //     return result;
  //   };

  //   try {
  //     await sendRequest();
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };


  // const getWithdrawalRequests = async () => {
  //   const sendRequest = async () => {
  //     const response = await fetch(`${env.primeBridgeUrl}/api/transaction/pending-withdrawal`, {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     });

  //     if (!response.ok) {
  //       const error = await response.json();
  //       console.log("ERROR RESPONSE", error?.error)
  //       throw new Error("sending plays failed.");
  //     }

  //     const result = await response.json();
  //     setWithdrawalRequests(result)
  //     console.log("UNSOLVED", result);

  //     return result;
  //   };

  //   try {
  //     await sendRequest();
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  return (
    <div className={classes.container}>
      <div className={classes.navContainer}>
        <p className={classes.headerSection}>Funds</p>

        <div className={classes.actionsContainer}></div>
      </div>

      <div className={classes.tableContainer}>
        <div className={classes.headerSection}>
          <p className={classes.header}>New Deposit Requests</p>
          <NavLink to={"/financial-management/funds/deposit"}>
            <button className={classes.actionBtn}>See all requests</button>
          </NavLink>
        </div>

        <table style={{ width: "100%" }} class={tableClasses.container}>
          <thead class="bg-gray-50">
            <tr>
              <th
                scope="col"
                class={`${tableClasses.header} ${tableClasses.first}`}
              >
                Request Details
              </th>
              <th scope="col" class={tableClasses.header}>
                Company Name
              </th>
              <th scope="col" class={tableClasses.header}>
                Account
              </th>
              <th scope="col" class={tableClasses.header}>
                Method
              </th>
              <th scope="col" class={tableClasses.header}>
                Total Deposit
              </th>
            </tr>
          </thead>
          <tbody class={tableClasses.body}>
            {depositeRequests.map((item, i) => (
              <DepositRequestItem isNewRequest isWithdrawal={false} request={item} key={i}/>
            ))}
          </tbody>
        </table>
      </div>

      <div className={`${classes.tableContainer} ${classes.spacer}`}>
        <div className={classes.headerSection}>
          <p className={classes.header}>New Withdrawal Requests</p>
          <NavLink to={"/financial-management/funds/withdrawal"}>
            <button className={classes.actionBtn}>See all requests</button>
          </NavLink>
        </div>

        <table style={{ width: "100%" }} class={tableClasses.container}>
          <thead class="bg-gray-50">
            <tr>
              <th
                scope="col"
                class={`${tableClasses.header} ${tableClasses.first}`}
              >
                Request Details
              </th>
              <th scope="col" class={tableClasses.header}>
                Company Name
              </th>
              <th scope="col" class={tableClasses.header}>
                Account
              </th>
              <th scope="col" class={tableClasses.header}>
                Method
              </th>
              <th scope="col" class={tableClasses.header}>
                Total Withdrawal
              </th>
            </tr>
          </thead>
          <tbody class={tableClasses.body}>
            {withdrawalRequests.map((item, i) => (
              <DepositRequestItem isNewRequest isWithdrawal={true} key={i} request={item}/>
            ))}
          </tbody>
        </table>
      </div>

      <div className={`${classes.tableContainer} ${classes.spacer}`}>
        <div className={classes.headerSection}>
          <p className={classes.header}>New Transfer Requests</p>
          <NavLink to={"/financial-management/funds/transfer"}>
            <button className={classes.actionBtn}>See all requests</button>
          </NavLink>
        </div>

        <table style={{ width: "100%" }} class={tableClasses.container}>
          <thead class="bg-gray-50">
            <tr>
              <th
                scope="col"
                class={`${tableClasses.header} ${tableClasses.first}`}
              >
                Request Details
              </th>
              <th scope="col" class={tableClasses.header}>
                Company Name
              </th>
              <th scope="col" class={tableClasses.header}>
                Source
              </th>
              <th scope="col" class={tableClasses.header}>
                Destination
              </th>
              <th scope="col" class={tableClasses.header}>
                Total Amount
              </th>
            </tr>
          </thead>
          <tbody class={tableClasses.body}>
            {transferRequests.map((item, i) => (
              <DepositRequestItem isNewRequest isTransfer={true} key={i} request={item}/>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default IndexPage;
