import React from "react";
import SharedModal from "../shared/Modal";
import classes from "./styles/withdrawal-modal.module.css";

const InvoiceDetailModal = ({ isOpen, setIsOpen, fileLink, isSending }) => {
  console.log("FILE LINK", fileLink)
  return (
    <SharedModal className={classes.modal} show={isOpen} setShow={setIsOpen}>
      <div className="modalContent">
      {isSending && (
          <div class="spinner-border" role="status">
          </div>
        )}
        {!isSending && <iframe
          src={fileLink}
          style={{ width: "500px", height: "500px" }}
          frameborder="0"
        ></iframe>}
      </div>
    </SharedModal>
  );
};

export default InvoiceDetailModal;
