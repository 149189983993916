import { env } from "../constants/env";
import { documentsActions } from "./documents-slice";


export const fetchDocuments =  (companyId) => {
  return async (dispatch) => {
    const sendRequest = async () => {
      const response = await fetch(`${env.primeBridgeUrl}/api/company/documents/${companyId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        const result = await response.json();

      console.log("GET DOCUMENTS ERROR", result);
        throw new Error("Getting accounts failed.");
      }

      const result = await response.json();

      return result;
    };

    try {
      const data = await sendRequest();
      dispatch(documentsActions.getDocuments({ companyFiles: data }));
    } catch (error) {
      console.log(error);
    }
  };
};
