import React, { useState } from "react";
import SharedModal from "../shared/Modal";
import classes from "./remove.module.css";
import { env } from "../../constants/env";
import { useDispatch } from "react-redux";
import { fetchAllAccounts, fetchAllMt4Accounts } from "../../Redux/accounts-actions";

const RemoveAccountModal = ({ isOpen, setIsOpen, account, isMt4Account }) => {
  const dispatch = useDispatch()
  const [isSending, setIsSending] = useState(false)

  const  deleteAccountHandler = async () => {
    const sendRequest = async () => {
      setIsSending(true)
      const response = await fetch(`${env.primeBridgeUrl}/api/account/delete-account/${account?._id}`, {
        method: 'DELETE',
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        setIsSending(false)
        throw new Error("sending plays failed.");
      }

      const result = await response.json();

      setIsOpen(false)

      setIsSending(false)

      if(isMt4Account){
        dispatch(fetchAllMt4Accounts(account.company))
      }else{
        dispatch(fetchAllAccounts(account.company))
      }
      

      console.log("Reply from plays data", result);

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      setIsSending(false)
      console.log(error);
    }
  };
  return (
    <SharedModal show={isOpen} setShow={setIsOpen}>
      <div>
        <p className={classes.header}>
          Are you sure you want to remove trading account nº {account?.credentials?.accountNumber}?
        </p>

        <p className={classes.info}>This action cannot be undone.</p>
        <div className={classes.btnContainer}>
        {!isSending && (<>
          <button onClick={() => setIsOpen(false)} className={classes.cancel}>
            Cancel
          </button>

          <button className={classes.confirm} onClick={deleteAccountHandler}>Remove</button>
          </>
        )}

          {isSending && (
          <div className="spinner-border" role="status">
          </div>
          )}
        </div>
      </div>
    </SharedModal>
  );
};

export default RemoveAccountModal;
