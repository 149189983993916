import React, { useEffect, useState } from "react";
import SharedModal from "../shared/Modal";
import classes from "./AddMt4Account.module.css";
import modalClasses from "./edit-company.module.css";
import { env } from "../../constants/env";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllAccounts, fetchAllOverviewAccounts } from "../../Redux/accounts-actions";
import DropDownIcon from "../icons/DropDownIcon";
import { replaceObjects } from "../../utilities/functions";


const AddMt4AccountModal = ({ isOpen, setIsOpen, isEdit, companyId, accountId, overview, management, lpAccounts=[], overviewData }) => {

  const dispatch = useDispatch()
  const [accountName, setAccountName] = useState()
  const [accountID, setAccountID] = useState()
  const [masterPassword, setMasterPassword] = useState()
  const [investorPassword, setInvestorPassword] = useState()
  const [apiId, setApiId] = useState()
  const [depositCorrection, setDepositCorrection] = useState()
  const [isSending, setIsSending] = useState(false)
  const [dropDown, setDropDown] = useState(false)
  const [selectedOption, setSelectedOption] = useState()
  const [cashAccountOn, setCashAccountOn] = useState(false)
  const [checkedAccounts, setCheckedAccounts] = useState([]);

  const [isChecked, setIsChecked] = useState(false);

  const accountsDetails = useSelector((state) => state.accounts.allMt4Accounts);

  const newAccounts = replaceObjects(accountsDetails, overviewData)


  


  const [items, setItems] = useState(newAccounts);



  const handleCheckboxChange = (id) => {
    setItems(items.map(item =>
      item._id === id ? { ...item, checked: !item.checked, company: companyId } : item
    ));
  };
  

  useEffect(() =>{
    if(isEdit){
      getSingleAccount()
    }
   
  }, [accountId])

  const addAccountHandler = async () => {
    console.log("CHECKED ITEMS", items)
    let dataContent = {overviewObjects: items}
    const sendRequest = async () => {
      setIsSending(true)
      const response = await fetch(`${env.primeBridgeUrl}/api/account/overview`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataContent)
      });

      if (!response.ok) {
        setIsSending(false)
        const result = await response.json();
        console.log("ERRRR", result)
        throw new Error("sending plays failed.");
      }

      const result = await response.json();
      setIsSending(false)
      setIsOpen(false);

      dispatch(fetchAllOverviewAccounts(companyId))

      console.log("Reply from plays data", result);

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      setIsSending(false)
      console.log(error);
    }
  };

  const getSingleAccount = async () => {
    const sendRequest = async () => {
      const response = await fetch(`${env.primeBridgeUrl}/api/account/${accountId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Getting account failed.");
      }

      const result = await response.json();

      console.log("ACCOUNT I WANT TO SEE", result)

      setAccountName(result.accountName)
      setAccountID(result?.credentials?.accountNumber)
      setMasterPassword(result?.credentials?.masterPassword)
      setInvestorPassword(result?.credentials?.investorPassword)
      setApiId(result?.credentials?.apiId)
      setDepositCorrection(result?.credentials?.depositCorrection)
      setCashAccountOn(result.cashAccount)

      if(isEdit)
        getLPAccount(result?.lpAccount)

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };


  const getLPAccount = async (id) => {
    const sendRequest = async () => {
      const response = await fetch(`${env.primeBridgeUrl}/api/account/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Getting account failed.");
      }

      const result = await response.json();

      setSelectedOption(result)

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };

  const updateAccount = async () => {
    let dataContent = { accountName: accountName, lpAccount: selectedOption?._id, credentials: {accountNumber: accountID, server: "TCBridge-Live", apiId, depositCorrection, masterPassword: masterPassword.trim(), investorPassword: investorPassword.trim()}, cashAccount: cashAccountOn}
    const sendRequest = async () => {
      setIsSending(true)
      const response = await fetch(`${env.primeBridgeUrl}/api/account/update-account/${accountId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataContent)
      });

      if (!response.ok) {
        setIsSending(false)
        throw new Error("Getting account failed.");
      }

      const result = await response.json();
      setIsSending(false)
      setIsOpen(false);
      dispatch(fetchAllAccounts(companyId))
      
      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      setIsSending(false)
      console.log(error);
    }
  };

  
  return (
    <SharedModal
      className={modalClasses.modal}
      show={isOpen}
      setShow={setIsOpen}
    >
      <div className={modalClasses.container}>
        <p className={modalClasses.header}>
          {isEdit ? "Edit" : "Add"} Sync LP Accounts
        </p>
        <div className="row">
        {items.map((item, i) => (<div className={`${classes.checkboxContainer} d-flex align-items-center col-6`} key={i}>
        <input
          className={classes.radioInput}
          type="checkbox"
          checked={item.checked}
          onChange={() => handleCheckboxChange(item._id)}
        />
        <div className={`${classes.radioLabel} ms-2`}>{item.accountName}</div>
      </div>))}
      </div>

        <div className={classes.btnContainer}>
        {!isSending && (<button className={modalClasses.confirm} onClick={() => { if(isEdit){ updateAccount() }else{addAccountHandler()}}}>
            {isEdit ? "Save" : "Sync"}
          </button>)}
          {isSending && (
          <div className="spinner-border" role="status">
          </div>
        )}
        </div>
      </div>
    </SharedModal>
  );
};

export default AddMt4AccountModal;
