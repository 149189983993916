import React, { useState } from "react";
import tableClasses from "../shared/styles/customTable.module.css";
import LeadDetailsModal from "./LeadDetailModal";

const LeadItemStatus = ({lead}) => {
  const statusIcon = () => {
    if (lead?.status == "contacted") {
      return (
        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="8.5" cy="8.5" r="8.5" fill="#5DBE94" />
        </svg>
      );
    } else if (lead?.status == "discarded") {
      return (
        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="8.5" cy="8.5" r="8.5" fill="#BD3437" />
        </svg>
      );
    } else {
      return (
        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="8.5" cy="8.5" r="8.5" fill="#D0D0D0" />
        </svg>
      );
    }
  };

  const generateStatusText = () => {
    if (lead?.status == "contacted") {
      return "Contacted";
    } else if (lead?.status == "discarded") {
      return "Declined";
    } else {
      return "Pending";
    }
  };


  const dateToYMD = date => {
    const formatedDate = date?.split('T')[0]
    console.log("DATE", formatedDate)
    var newDate = new Date(formatedDate)
    var today = new Date()
    var strArray = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ]
    var days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    var d = newDate.getDate()
    var dayName = days[newDate.getDay()]
    var m = strArray[newDate.getMonth()]
    var y = newDate.getFullYear()

    
    return '' + m + ' ' + (d <= 9 ? '0' + d : d) + ', ' + y
  }

  const [isOpen, setIsOpen] = useState(false);
  return (
    <tr>
      <td class={`${tableClasses.row} ${tableClasses.first}`}>
        <div
          style={{ justifyContent: "flex-start", gap: "1rem" }}
          className={tableClasses.flexItem}
        >
          <div>{statusIcon()}</div>
          <p>{generateStatusText()}</p>
        </div>
      </td>
      <td class={tableClasses.row}>{dateToYMD(lead.createdAt)}</td>
      <td class={tableClasses.row}>{lead?.companyName}</td>
      <td class={tableClasses.row}>{lead ? `${lead?.firstName} ${lead?.lastName}` : ""}</td>
      <td class={tableClasses.row}>
        <div style={{ gap: "3rem" }} className={tableClasses.flexItem}>
          <a>{lead?.website}</a>
          <div>
            <svg
              width="20"
              height="20"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => setIsOpen(true)}
            >
              <path
                d="M16 12.5H12.5M12.5 12.5H9M12.5 12.5V9M12.5 12.5V16M18.3333 23H6.66667C4.08934 23 2 20.9107 2 18.3333V6.66667C2 4.08934 4.08934 2 6.66667 2H18.3333C20.9107 2 23 4.08934 23 6.66667V18.3333C23 20.9107 20.9107 23 18.3333 23Z"
                stroke="#0077B5"
                stroke-width="3"
                stroke-linecap="round"
              />
            </svg>
          </div>
        </div>
      </td>

      <LeadDetailsModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        status={lead?.status}
        lead={lead}
      />
    </tr>
  );
};

export default LeadItemStatus;
