import { configureStore } from '@reduxjs/toolkit'
import accountsSlice from './Redux/accounts-slice'
import companySlice from './Redux/company-slice'
import usersSlice from './Redux/users-slice'
import leadsSlice from './Redux/leads-slice'
import documentsSlice from './Redux/documents-slice'
import requestsSlice from './Redux/requests-slice'

export const store = configureStore({
  reducer: {
    users: usersSlice.reducer,
    company: companySlice.reducer,
    accounts: accountsSlice.reducer,
    leads: leadsSlice.reducer,
    documents: documentsSlice.reducer,
    requests: requestsSlice.reducer
  },
})