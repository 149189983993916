import { createSlice } from '@reduxjs/toolkit';

const companySlice = createSlice({
  name: 'company',
  initialState: {
    companyName: "",
    companyDirector: "",
    companies: []
  },
  reducers: {

    getCompany (state, action) {
      state.companyName = action.payload.companyName
    },
    getDirector (state, action) {
      state.companyDirector = action.payload.companyDirector
    },

    getCompanies (state, action) {
      state.companies = action.payload.companies
    },

    
  }
});

export const companyActions = companySlice.actions;

export default companySlice;
