import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import SharedModal from "../shared/Modal";
import classes from "./styles/withdrawal-modal.module.css";
import { env } from "../../constants/env";
import { useDispatch } from "react-redux";
import { fetchAllTransferRequests, fetchPendingTransferRequests} from "../../Redux/requests-actions";
import { numberWithCommas } from "../../utilities/functions";

const TransferRequestModal = ({
  isOpen,
  setIsOpen,
  isCrypto,
  isNewRequest,
  status,
  request,
  companyName,
  accountName,
  userName
}) => {
  const dispatch = useDispatch()
  const [selectedStatus, setSelectedStatus] = useState("");
  const [reason, setReason] = useState("Not enough funds")
  const [reasonText, setReasonText] = useState("")
  const [withdrawalFeeIsOpen, setWithdrawalFeeIsOpen] = useState(false);
  const [withdrawalAmountIsOpen, setWithdrawalAmountIsOpen] = useState(false);
  const [totalWithdrawalIsOpen, setTotalWithdrawalIsOpen] = useState(false);

  const [withdrawalFee, setWithdrawalFee] = useState("");
  const [withdrawalAmount, setWithdrawalAmount] = useState("");
  const [totalWithdrawal, setTotalWithdrawal] = useState("");


  useEffect(() => {
    setWithdrawalFee(request?.fee)
    setWithdrawalAmount(request?.amount)
    setTotalWithdrawal(request?.totalWithdrawal)
  },[])

  const [isSending, setIsSending] = useState(false)

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    toast.success("Copied to clipboard");
  };

  const dateToYMD = date => {
    const formatedDate = date.split('T')[0]
    console.log("DATE", formatedDate)
    var newDate = new Date(date)
    var fullDate = new Date(date)
    var strArray = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ]
    var days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    var d = newDate.getDate()
    var dayName = days[newDate.getDay()]
    var m = strArray[newDate.getMonth()]
    var y = newDate.getFullYear()
    var hr = newDate.getHours()
    var min = newDate.getMinutes()

    if (min < 10) {
      min = "0" + min;
    }
    var ampm = "AM";
    if( hr > 12 ) {
        hr -= 12;
        ampm = "PM";
    }

    return {
      full: '' + m + ' ' + (d <= 9 ? '0' + d : d) + ', ' + (hr <= 9 ? '0' + hr : hr) + ':' + min + ' ' + ampm,
      date: d,
      month: m
    }
  }

  const requestStatusUpdate = async () => {
    let dataContent = {status: selectedStatus, reason: reason === "Other" ? reasonText : reason}
    const sendRequest = async () => {
      setIsSending(true)
      const response = await fetch(`${env.primeBridgeUrl}/api/transaction/update-status/${request._id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataContent)
      });

      if (!response.ok) {
        setIsSending(false)
        const error = await response.json();

        console.log("ERROR", error)

        throw new Error("sending plays failed.");
      }

      const result = await response.json();

      console.log("Reply from REQUEST UPDATE data", result);
      if(isNewRequest){
        dispatch(fetchPendingTransferRequests())
      }else{
        dispatch(fetchAllTransferRequests())
      }
      

      setIsSending(false)

      setIsOpen(false)

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      setIsSending(false)
      console.log(error);
    }
  };

  const requestFeeUpdate = async () => {
    let dataContent = {fee: withdrawalFee}
    const sendRequest = async () => {

      const response = await fetch(`${env.primeBridgeUrl}/api/transaction/update-fee/${request._id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataContent)
      });

      if (!response.ok) {
        const error = await response.json();

        console.log("ERROR", error)

        throw new Error("sending plays failed.");
      }

      const result = await response.json();

      console.log("Reply from REQUEST UPDATE data", result);
      if(isNewRequest){
        dispatch(fetchPendingTransferRequests())
      }else{
        dispatch(fetchAllTransferRequests())
      }
      setWithdrawalFeeIsOpen(false)

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };


  const requestAmountUpdate = async () => {
    let dataContent = {amount: withdrawalAmount}
    const sendRequest = async () => {

      const response = await fetch(`${env.primeBridgeUrl}/api/transaction/update-amount/${request._id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataContent)
      });

      if (!response.ok) {
        const error = await response.json();

        console.log("ERROR", error)

        throw new Error("sending plays failed.");
      }

      const result = await response.json();

      console.log("Reply from REQUEST UPDATE data", result);
      if(isNewRequest){
        dispatch(fetchPendingTransferRequests())
      }else{
        dispatch(fetchAllTransferRequests())
      }
      setWithdrawalAmountIsOpen(false)

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };

  const requestTotalUpdate = async () => {
    let dataContent = {totalWithdrawal}
    const sendRequest = async () => {

      const response = await fetch(`${env.primeBridgeUrl}/api/transaction/update-total/${request._id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataContent)
      });

      if (!response.ok) {
        const error = await response.json();

        console.log("ERROR", error)

        throw new Error("sending plays failed.");
      }

      const result = await response.json();

      console.log("Reply from REQUEST UPDATE data", result);
      if(isNewRequest){
        dispatch(fetchPendingTransferRequests())
      }else{
        dispatch(fetchAllTransferRequests())
      }
      setTotalWithdrawalIsOpen(false)

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <SharedModal className={classes.modal} show={isOpen} setShow={setIsOpen}>
      <div className={classes.body}>
        <div className={classes.headerSection}>
          <p className={classes.header}>
            Transfer Request
          </p>
        </div>

        <div>
          <div className={classes.group}>
            <div className={classes.groupItem}>
              <p className={classes.label}>Request details</p>

              <p className={classes.value}>{dateToYMD(request?.createdAt).full}</p>
            </div>

            <div className={classes.groupItem}>
              <p className={classes.label}>Company</p>

              <p className={classes.value}>{companyName}</p>
            </div>
          </div>
          <div className={classes.group}>
            <div className={classes.groupItem}>
              <p className={classes.label}>Team Member</p>

              <p className={classes.value}>{userName}</p>
            </div>

            <div className={classes.groupItem}>
              <p className={classes.label}>Source Account</p>

              <p className={classes.value}>{request?.sourceAccount?.accountName}</p>
            </div>
          </div>
          <div className={classes.group}>
            <div className={classes.groupItem}>
              <p className={classes.label}>Destination Account</p>

              <p className={classes.value}>{request?.destinationAccount?.accountName}</p>
            </div>

  

            

            <div className={classes.groupItem}>
              <p className={classes.label} >Total Amount</p>

              {!totalWithdrawalIsOpen ? (
                <p className={`${classes.value}`}>
                  {`${request?.currency === "usd" || request?.paymentMethod === "crypto" ? "$" : request?.currency === "euro"  && "€"} ${numberWithCommas(request?.totalWithdrawal)} ${request?.currency === "usd" || request?.paymentMethod === "crypto" ? "USD" : request?.currency === "euro"  && "EUR"}`}
                  {isNewRequest && (
                    <svg
                      width="16"
                      height="15"
                      viewBox="0 0 16 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => setTotalWithdrawalIsOpen(true)}
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M9.85792 0.927237C10.9223 -0.137147 12.648 -0.137147 13.7124 0.927237L14.4833 1.69813C15.5477 2.76251 15.5477 4.48822 14.4833 5.55261L5.618 14.4179L2.08284 14.4179C1.48073 14.4179 0.992628 13.9298 0.992628 13.3277L0.992628 9.79253L9.85792 0.927237ZM12.1706 2.46903L12.9415 3.23992C13.1544 3.4528 13.1544 3.79794 12.9415 4.01082L11.7852 5.16716L10.2434 3.62537L11.3997 2.46903C11.6126 2.25615 11.9577 2.25615 12.1706 2.46903ZM10.2434 6.70895L4.71484 12.2375L3.17305 12.2375L3.17305 10.6957L8.70158 5.16716L10.2434 6.70895Z"
                        fill="black"
                      />
                    </svg>
                  )}
                </p>
              ) : (
                <div className={classes.form_group}>
                  <input
                    value={totalWithdrawal}
                    onChange={(e) => setTotalWithdrawal(e.target.value)}
                  />
                  <button onClick={requestTotalUpdate}>
                    Save
                  </button>
                </div>
              )}
            </div>
          </div>
           
        </div>
        {request?.status === "pending" ? (
          <>
            <div className={classes.actionBox}>
              <div className={classes.heading}>
                <p className={classes.label}>Status</p>
              </div>

              <div className={classes.content}>
                <div
                  className={classes.statuses}
                >
                  <label class={classes.container}>
                  Transferred
                    <input type="radio" value={"transferred"} name="status" onChange={(e) => setSelectedStatus(e.target.value)}/>
                    <span class={classes.checkmark}></span>
                  </label>

                  <label class={classes.container}>
                  Not Transferred
                    <input
                      value={"not-transferred"}
                      type="radio"
                      name="status"
                      onChange={(e) => setSelectedStatus(e.target.value)}
                    />
                    <span class={classes.checkmark}></span>
                  </label>
                </div>
              </div>
            </div>
           
          </>
        ) : (
          <div className={classes.decision}>
            <div className={classes.heading}>
              <p
                className={`${classes.status} ${
                  request?.status == "transferred" ? classes.success : classes.danger
                }`}
              >
                {request?.status == "transferred"
                  ? "Funds were transferred to another sub account."
                  : "Funds were not transferred to another sub account."}
              </p>
            </div>

            <div className={classes.message}>
              <p>Admin review done {dateToYMD(request?.updatedAt).full}.</p>
            </div>
          </div>
        )}

{request?.status === "pending" && (
          <div>
            {!isSending && (<button className={classes.actionBtn} onClick={requestStatusUpdate}>Process</button>)}
            {isSending && (
              <div className="d-flex justify-content-center align-items-center">
                <div className="spinner-border" role="status">
              </div>
            </div>
        )}
          </div>
        )}
        
      </div>
    </SharedModal>
  );
};

export default TransferRequestModal;
