import React, { useEffect } from "react";
import IndexPage from "../../components/FinancialManagement/IndexPage";
import MainLayout from "../../components/Layout/MainLayout";

const Index = () => {
  useEffect(() => {
    document.title = "Financial Management - Prime Bridge Admin";
  }, []);
  return (
    <MainLayout>
      <IndexPage />
    </MainLayout>
  );
};

export default Index;
