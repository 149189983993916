import React, { useEffect, useState } from "react";
import SharedModal from "../shared/Modal";
import classes from "./remove.module.css";
import modalClasses from "./edit-company.module.css";
import { env } from "../../constants/env";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllAccounts } from "../../Redux/accounts-actions";
import DropDownIcon from "../icons/DropDownIcon";


const LPLineDetailsModal = ({ isOpen, setIsOpen, accountName}) => {


  const syncedAccounts = useSelector((state) => state.accounts.syncedAccounts);

  
  return (
    <SharedModal
      className={modalClasses.modal}
      show={isOpen}
      setShow={setIsOpen}
    >
      <div className={modalClasses.container}>
        <p className={modalClasses.header}>
          LP Line Details
        </p>
        <h5 className={classes.subHeader}>MT4 Account: {accountName}</h5>
        <table class={classes.container}>
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class={`${classes.head} ${classes.first}`}
                >
                  Account ID
                </th>
                <th scope="col" class={classes.head}>
                  Account Name
                </th>
              </tr>
            </thead>
            <tbody class={classes.body}>
            {syncedAccounts.map((item, i) => (<tr>
              <td class={`${classes.row} ${classes.first} d-flex gap-2`}>{item?.credentials?.accountNumber}</td>
              <td class={classes.row}>{item?.account?.accountName}</td>
            </tr>))}
            </tbody>
          </table>
      </div>
    </SharedModal>
  );
};

export default LPLineDetailsModal;
