import React, { useEffect, useState } from "react";
import classes from "../FinancialManagement/styles/index.module.css";
import tableClasses from "../shared/styles/customTable.module.css";
import { NavLink } from "react-router-dom";
import LeadItem from "./LeadItem";
import { env } from "../../constants/env";
import { useDispatch, useSelector } from "react-redux";
import { fetchPendingLeads } from "../../Redux/leads-actions";

const MainPage = () => {

  const dispatch = useDispatch()

  const leadsList = useSelector(state => state.leads.pendingLeads)

  useEffect(() => {
    dispatch(fetchPendingLeads())
  }, [])

  

  return (
    <div>
      <div className={classes.container}>
        <div className={classes.navContainer}>
          <p className={classes.headerSection}>Leads</p>

          <div className={classes.actionsContainer}></div>
        </div>

        <div className={classes.tableContainer}>
          <div className={classes.headerSection}>
            <p className={classes.header}>New Demo Requests</p>
            <NavLink to={"/leads/all"}>
              <button className={classes.actionBtn}>See all</button>
            </NavLink>
          </div>

          <table style={{ width: "100%" }} class={tableClasses.container}>
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class={`${tableClasses.header} ${tableClasses.first}`}
                >
                  Request Date
                </th>
                <th scope="col" class={tableClasses.header}>
                  Company Name
                </th>
                <th scope="col" class={tableClasses.header}>
                  Director
                </th>
                <th scope="col" class={tableClasses.header}>
                  Email
                </th>
                <th scope="col" class={tableClasses.header}>
                  Website
                </th>
              </tr>
            </thead>
            <tbody class={tableClasses.body}>
              {leadsList?.map((lead, index) => (
                <LeadItem key={index} lead={lead}/>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default MainPage;
