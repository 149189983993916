import React, { useEffect, useState } from "react";
import classes from "./RiskManagementMain.module.css";
import LeadDetailsModal from "./LeadDetailModal";
import DeleteViolationModal from "./DeleteViolationAccountModal";
import { env } from "../../constants/env";
const ViolationItem = ({item, setReload, reload}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [deposit, setDeposit] = useState()

  useEffect(() => {
    getDeposit(item.Acc_Id)
  },[item])



  const getDeposit = async (id) => {
    const sendRequest = async () => {
      const response = await fetch(`${env.pbTrackingUrl}/api/violation/deposit/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        const result = await response.json();

        console.log("DEPOSIT ERROR :)", result);

        throw new Error("Getting account failed.");
      }

      const result = await response.json();


      setDeposit(result?.deposit)

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };
  
  return (
    <tr>
      <td class={`${classes.row} ${classes.first}`}>{item?.Acc_Id}</td>
      <td class={classes.row}>{item?.Monthly_Loss ? Math.round(item?.Monthly_Loss * 100) / 100 : 0}</td>
      <td class={classes.row}>{item?.Monthly_Loss ? Math?.round(((item?.Monthly_Loss/deposit) * 100) * 100) / 100 : 0}%</td>
      <td class={classes.row}>
       <button className={classes.removeButton} onClick={() => setIsOpen(true)}>Remove</button>
      </td>
      <DeleteViolationModal isNewRequest isOpen={isOpen} setIsOpen={setIsOpen} account={item?.Acc_Id} setReload={setReload} reload={reload}/>
    </tr>
  );
};

export default ViolationItem;
