import React, { useEffect } from "react";
import MainLayout from "../../components/Layout/MainLayout";
import EditTeamMember from "../../components/Teams/EditTeamMember";

const EditTeamPage = () => {
  useEffect(() => {
    document.title = "Edit Team member - Prime Bridge Admin";
  }, []);
  return (
    <MainLayout>
      <EditTeamMember />
    </MainLayout>
  );
};

export default EditTeamPage;
