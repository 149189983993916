import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";

const SharedModal = ({ show, setShow, size = "md", children, className }) => {
  const handleClose = () => setShow(false);
  return (
    <Modal
      backdrop="static"
      backdropClassName=""
      centered
      show={show}
      onHide={handleClose}
      size={size}
      dialogClassName={className}
    >
      <Modal.Header
        closeButton
        style={{
          border: "none",
          position: "absolute",
          top: "0.5rem",
          right: "1rem",
          zIndex: 100,
        }}
      />
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};

export default SharedModal;
