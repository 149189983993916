import { env } from "../constants/env";
import { usersActions } from "./users-slice";

export const  fetchSingleUser = (userId) => {
  return async (dispatch) => {
  const sendRequest = async () => {
    const response = await fetch(`${env.primeBridgeUrl}/api/auth/user/${userId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Getting user failed.");
    }

    const result = await response.json();

    return result;
  };

  try {
    const data = await sendRequest();
    dispatch(usersActions.getUser({ user: data }));
  } catch (error) {
    console.log(error);
  }
}
};


export const  fetchUsers = () => {
  return async (dispatch) => {
  const sendRequest = async () => {
    const response = await fetch(`${env.primeBridgeUrl}/api/auth/admin-users`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Getting user failed.");
    }

    const result = await response.json();

    return result;
  };

  try {
    const data = await sendRequest();
    dispatch(usersActions.getUsers({ users: data }));
  } catch (error) {
    console.log(error);
  }
}
};

export const  fetchCompanyUsers = (companyId) => {
  return async (dispatch) => {
  const sendRequest = async () => {
    const response = await fetch(`${env.primeBridgeUrl}/api/auth/portal-users/${companyId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("sending plays failed.");
    }

    const result = await response.json();
  

    return result;
  };

  try {
    const data = await sendRequest();
    dispatch(usersActions.getCompanyUsers({ companyUsers: data }));
  } catch (error) {
    console.log(error);
  }
}
};