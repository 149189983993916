import React, { useEffect, useState } from "react";
import SharedModal from "../shared/Modal";
import classes from "./remove.module.css";
import modalClasses from "./edit-company.module.css";
import { env } from "../../constants/env";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllAccounts } from "../../Redux/accounts-actions";
import DropDownIcon from "../icons/DropDownIcon";


const Mt5SyncAccountModal = ({ isOpen, setIsOpen, accountId, companyId}) => {

  const dispatch = useDispatch()
 
  
  const [isSending, setIsSending] = useState(false)
  const [accounts, setAccounts] = useState([])

  const syncedAccounts = useSelector((state) => state.accounts.syncedAccounts);

  const allMt5Accounts = useSelector((state) => state.accounts.allMt5Accounts);

  const [items, setItems] = useState([]);

  useEffect(() => {
    const newAccounts = replaceObjects(allMt5Accounts, syncedAccounts)
    setItems(newAccounts)
  },[syncedAccounts, accounts])


 

  const replaceObjects = (array1, array2) => {
    return array1.map(obj1 => {
        const matchingObj = array2.find(obj2 => obj2.credentials.accountNumber === obj1.credentials.accountNumber);
        
        if (matchingObj) {
          return { _id: matchingObj._id, accountName: matchingObj?.account?.accountName, credentials: matchingObj.credentials, checked: true };
        }
        return obj1;
    });
  }
  


  console.log("OVERVIEW NEW ARRAY: ", items)

  const handleCheckboxChange = (id) => {
    setItems(items.map(item =>
      item._id === id ? { ...item, checked: !item.checked, company: companyId } : item
    ));
  };


  const addAccountHandler = async () => {
    console.log("CHECKED ITEMS", items)
    let dataContent = {accountsObjects: items}
    const sendRequest = async () => {
      setIsSending(true)
      const response = await fetch(`${env.primeBridgeUrl}/api/account/sync-account/${accountId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataContent)
      });

      if (!response.ok) {
        setIsSending(false)
        const result = await response.json();
        console.log("ERRRR", result)
        throw new Error("sending plays failed.");
      }

      const result = await response.json();
      setIsSending(false)
      setIsOpen(false);

      console.log("ADD SYNC ACCOUNT", result);

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      setIsSending(false)
      console.log(error);
    }
  };
  

  
  return (
    <SharedModal
      className={modalClasses.modal}
      show={isOpen}
      setShow={setIsOpen}
    >
      <div className={modalClasses.container}>
        <p className={modalClasses.header}>
          Sync MT5 Accounts
        </p>
        <table class={classes.container}>
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class={`${classes.head} ${classes.first}`}
                >
                  Account ID
                </th>
                <th scope="col" class={classes.head}>
                  Account Name
                </th>
              </tr>
            </thead>
            <tbody class={classes.body}>
            {items.map((item, i) => (<tr>
              <td class={`${classes.row} ${classes.first} d-flex gap-2`}><div className={`${classes.checkboxContainerArounded} `} key={i}>
                <input
                  className={classes.radioInput}
                  type="checkbox"
                  checked={item.checked}
                  onChange={() => handleCheckboxChange(item._id)}
                />
                <div className={`${classes.radioLabel} ms-2`}></div>
              </div>{item?.credentials?.accountNumber}</td>
              <td class={classes.row}>{item?.accountName}</td>
            </tr>))}
            </tbody>
          </table>

          <div className={classes.btnContainer}>
        {!isSending && (<button className={modalClasses.confirm} onClick={addAccountHandler}>
            Save 
          </button>)}
          {isSending && (
          <div className="spinner-border" role="status">
          </div>
        )}
        </div>
      </div>
    </SharedModal>
  );
};

export default Mt5SyncAccountModal;
