import React, { useEffect, useState } from "react";
import SharedModal from "../shared/Modal";
import classes from "./remove.module.css";
import modalClasses from "./edit-company.module.css";
import { env } from "../../constants/env";
import { useDispatch } from "react-redux";
import { fetchAllAccounts } from "../../Redux/accounts-actions";
import DropDownIcon from "../icons/DropDownIcon";

const AddNewGroupModal = ({ isOpen, setIsOpen, isEdit, companyId, groupId, lpAccounts }) => {

  const dispatch = useDispatch()
  const [groupName, setGroupName] = useState()
  const [nopLimit, setNOPLimit] = useState()
  const [isSending, setIsSending] = useState(false)
  const [dropDown, setDropDown] = useState(false)
  const [lpGroupAccounts, setLPGroupAccount] = useState([])

  // console.log("LP GROUP ACCOUNTS", lpGroupAccounts)
  // console.log("LP ACCOUNTS", lpAccounts)


  

  useEffect(() =>{
    if(isEdit){
      getSingleGroup(groupId)
    }

  }, [groupId])

  const addAccountHandler = async () => {
    // let dataContent = { accountName: accountName, company: companyId, overview: overview ? overview : false, management: management ? management : false,
    //   credentials: {accountNumber: accountID, server: "TCBridge-Live", apiId, depositCorrection, masterPassword: masterPassword ?  masterPassword.trim() : "", investorPassword: investorPassword ? investorPassword.trim() : "" }}
    const sendRequest = async () => {
      setIsSending(true)
      const response = await fetch(`${env.primeBridgeUrl}/api/account/lp-group`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({groupName, nopLimit,lpAccounts: lpGroupAccounts, company: companyId})
      });

      if (!response.ok) {
        setIsSending(false)
        const result = await response.json();
        console.log("ERRRR", result)
        throw new Error("sending plays failed.");
      }

      const result = await response.json();
      setIsSending(false)
      setIsOpen(false);

      setGroupName("")
      setNOPLimit("")


      dispatch(fetchAllAccounts(companyId))

      console.log("Reply from plays data", result);

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      setIsSending(false)
      console.log(error);
    }
  };

  const getSingleGroup = async (id) => {
    const sendRequest = async () => {
      const response = await fetch(`${env.primeBridgeUrl}/api/account/lp-group/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Getting account failed.");
      }

      const result = await response.json();

      console.log("SINGLE GROUP RESULT", result)

      setGroupName(result.groupName)
      setNOPLimit(result.nopLimit)
      setLPGroupAccount(result.lpAccounts)

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };

  const updateGroup = async (id) => {
    let dataContent = { groupName, nopLimit, lpAccounts: lpGroupAccounts}
    const sendRequest = async () => {
      setIsSending(true)
      const response = await fetch(`${env.primeBridgeUrl}/api/account/update-lp-group/${id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataContent)
      });

      if (!response.ok) {
        setIsSending(false)
        throw new Error("Getting account failed.");
      }

      const result = await response.json();
      setIsSending(false)
      setIsOpen(false);
      dispatch(fetchAllAccounts(companyId))
      
      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      setIsSending(false)
      console.log(error);
    }
  };

  
  return (
    <SharedModal
      className={modalClasses.modal}
      show={isOpen}
      setShow={setIsOpen}
    >
      <div className={modalClasses.container}>
        <p className={modalClasses.header}>
          {isEdit ? "Edit" : "Add"} Group
        </p>
        <div className={modalClasses.inputContainer}>
          <p className={modalClasses.label}>Group name</p>
          <input type="text" className={modalClasses.input} value={groupName} onChange={(e) => setGroupName(e.target.value)}/>
        </div>
        <div className={modalClasses.inputContainer}>
          <p className={modalClasses.label}>NOP Limit</p>
          <input type="text" className={modalClasses.input} value={nopLimit} onChange={(e) => setNOPLimit(e.target.value)} />
        </div>
       
        

        <div className={modalClasses.inputContainer}>
          <p className={modalClasses.label}>Assign LP Account(s)</p>
          <div className={`${modalClasses.input}`}>
            <div className="d-flex justify-content-between align-items-center" onClick={() => setDropDown(!dropDown)} role="button">
              <div className="col">
              Account(s)
              </div>
              <DropDownIcon dropDown={dropDown} />
            </div>
            {dropDown && <div className="mt-2">
              {lpAccounts?.map((item, i) => (<div className={`${classes.checkboxContainer} d-flex justify-content-center align-items-center gap-2`} key={i}>
                <input
                  className={classes.checkInput}
                  type="checkbox"
                  name="lp-account"
                  value={item.accountName}
                  checked={lpGroupAccounts.some(el => el._id === item._id)}
                  onChange={(e) => {
                    if(!lpGroupAccounts.some(el => el._id === item._id)){
                      setLPGroupAccount([...lpGroupAccounts, item])
                    }else{
                      setLPGroupAccount((current) => current.filter((account) => account._id !== item._id))
                    }
                  }}
                />
                <div className={`${classes.radioLabel} `}>{item.accountName}</div>
              </div>))}
            </div>}
          </div>
        </div>


        

        <div className={classes.btnContainer}>
        {!isSending && (<button className={modalClasses.confirm} onClick={() => {if(isEdit){ updateGroup(groupId) }else{addAccountHandler()}}}>
            Save
          </button>)}
          {isSending && (
          <div className="spinner-border" role="status">
          </div>
        )}
        </div>
      </div>
    </SharedModal>
  );
};

export default AddNewGroupModal;
